import { Autocomplete, TextField } from "@mui/material";
import React from "react";
export const SelectInput = ({select,onChange, selectSx, placeholder, options }) => {

  return (
    
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={options}
        onChange={onChange}
        value={select}
        onInputChange={onChange}
        sx={{
          width: "157px",
          height: "36px",
          "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
            fontSize: "14px",
            borderRadius: "8px",
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            
            size={"small"}
            sx={{
              width: "157px",
              height: "36px",
              opacity: 1,
              "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                fontSize: "14px",
              },
            }}
          />
        )}
      />
  );
};
