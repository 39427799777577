import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../../config";
import { LocalStorageKeys } from "../../../../utils";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: config.api_url + "git/",
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = localStorage.getItem(LocalStorageKeys.authToken);
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 });

export const gitHubAPI = createApi({
  reducerPath: "gitHubAPI",
  baseQuery: baseQueryWithRetry,
  tagTypes: ["gitHub"],
  endpoints: (builder) => ({
    // Get All User API
    getAllgitHub: builder.query({
      query: (payload) => ({ url: `hub/authorize`, method: "GET" }),
      transformResponse: (response, meta, arg) => {
        if (response.type === "success") {
          return { gitHub: response.URL };
        }
        return { gitHub: [] };
      },
    }),

    // Create User API
    creategitHub: builder.mutation({
      query: () => ({
        url: `hub/authorize`,
        method: "GET",
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const { useCreategitHubMutation, useGetAllgitHubQuery } = gitHubAPI;
