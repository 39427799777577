export const featureListing_Style = {
  rootSx: {},
  featuresBoxSx: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid ",
    borderColor: "rgb(226, 232, 240)",
    borderRadius: "8px",
    // boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    backgroundColor: "#fff",
    p: 1,
    mx: 1,
    mb: 1.75,
    cursor: "pointer",
    width: "100%",
    maxWidth: "340px",
    '@media (max-width: 900px) ': {
      maxWidth:'inherit'
       
     },
  },
  featuresNameSx: {
    fontSize: "14px",
    fontWeight: "500",
 
  },
  iconSx: {
    color: "#FF8088",
    fontSize: "18px",
    "&:hover": {
      color: "#F44F5A",
    },
  },
  iconEditSx: {
    fontSize: "18px",
    color: "#bfbfbf",
    "&:hover": {
      color: "#636363",
    },
  },

  inputSx: {
    // p: 1,
    mx: 1,
    mb: 1.75,
  },
  textBox: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      width: "100%",
      maxWidth: "340px",
      '@media (max-width: 900px) ': {
        maxWidth:'inherit'
         
       },
      "& input::placeholder": {
        fontFamily: "crayond_regular",
      },
      "& .MuiOutlinedInput-input": {
        p: 1.25,
        fontWeight: 500,
        minWidth: '254px',
        fontSize: "14px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        border: "1px solid",
        borderColor: "rgb(226, 232, 240)",
      },
    },
  },
  tickSx: {
    color: "#3fac3fd6",
    fontSize:'24px',
  },
  closeInput: {
    color: "#F44F5A",
    fontSize:'24px',
  },
};
