import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
// import { getNullableType } from "graphql";
import { useNavigate } from "react-router-dom";
import { BreadcrumbsComponent } from "../../breadcrumbs";

export const TopLeftNav = ({
    showBackBtn = true,
    title="",
    subTitle="",
    breadcrumbsLink = [],
    prevURL = null,
    bddUrl=false,
    onbackScreenBtn=()=>false
}) => {

    const navigate = useNavigate();

    const onBackBtnClicked = () => {
        if(bddUrl){
            onbackScreenBtn()
        }else{
            if (prevURL) {
                navigate(prevURL);
            } else {
                navigate(-1);
            }
        }
        
       
    }
    return <Box display={"flex"} >
        {showBackBtn && <IconButton sx={{marginLeft: -2}} disableRipple onClick={onBackBtnClicked}>
            <KeyboardBackspaceRoundedIcon />
        </IconButton>}
        <div>
            <Box sx={{display:"flex", flexDirection:"row"}}>
            <Typography sx={{ fontWeight: 700 }} variant="body2">{title}</Typography>
            <Typography  sx={{ fontSize: 12 }}ml={.5} variant="subtitle2" color={"textSecondary"}>{`(${subTitle})`}</Typography>
            </Box>
            <BreadcrumbsComponent breadcrumbsLink={breadcrumbsLink ?? []}/>
        </div>
    </Box>
}