import React from "react";
import { dashboard_styles } from "./styles";
import { Box, Grid, MenuItem, Select, Stack, Typography } from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const SocSecurity = () => {
  const selectOption = [
    {
      label: "Project",
      value: "value",
    },
    {
      label: "App",
      value: "App",
    },
  ];

  const options = {
    plugins: {
      legend: {
        display: false, // show or hide the legend
        position: "right", // the position of the legend: top, bottom, left, right
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          usePointStyle: true,
          fontSize: "12px",
          color: "#101010",
          margin: 30,
        },
      },
    },
    elements: {
        arc: {
            borderWidth: 1,
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
  };

  const options2 = {
    cutout: "50%",
    rotation: -90,
    circumference: 180,
    cutoutPercentage: 50,
    plugins: {
      // cutout: "50%",
      legend: {
        display: false, // show or hide the legend
      },
    },
    elements: {
        arc: {
            borderWidth: 1,
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
  };
  const data = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        // backgroundColor: [
        //   'rgba(255, 99, 132, 0.2)',
        //   'rgba(54, 162, 235, 0.2)',
        //   'rgba(255, 206, 86, 0.2)',
        //   'rgba(75, 192, 192, 0.2)',
        //   'rgba(153, 102, 255, 0.2)',
        //   'rgba(255, 159, 64, 0.2)',
        // ],
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        // borderWidth: 1,
      },
    ],
  };
  const data2 = {
    labels: ["Red"],
    datasets: [
      {
        label: "# of Votes",
        data: [23],
        backgroundColor: ["#EB8F7C"],
      },
    ],
  };
  

  return (
    <Box sx={dashboard_styles.detailsContainer}>
      <Grid
        container
        spacing={{ xs: 2, md: 2, lg: 4 }}
        columns={{ xs: 1, sm: 8, md: 12 }}
        paddingTop={{xs:'54px',md: "36px",lg: "0px"}}
      >
        <Grid item xs={12} md={7}>
          <BorderCard>
            <Typography sx={dashboard_styles.subTitle2}>
            5478 Total Entities
            </Typography>
            <div style={{ width: "180px", height: "180px" }}>
              <Doughnut data={data} options={options} />
            </div>
          </BorderCard>
        </Grid>
        <Grid item xs={12} md={5}>
          <Grid
            container
            spacing={{ xs: 2, md: 2, lg: 4 }}
            columns={{ xs: 1, sm: 8, md: 12 }}
          >
            <Grid item xs={6}>
              <BorderCard>
                <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
                  <Typography
                    sx={{
                      ...dashboard_styles.progressCount2,
                      color: "#18283E",
                    }}
                  >
                    77
                  </Typography>
                  <Typography sx={dashboard_styles.progressPercentage}>
                    {"(77%)"}
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    ...dashboard_styles.progressLabel,
                    backgroundColor: "#FAE9D1",
                    color: "#E3911C",
                  }}
                >
                  {"Critical"}
                </Typography>
              </BorderCard>
            </Grid>
            <Grid item xs={6}>
              <BorderCard>
                <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
                  <Typography
                    sx={{
                      ...dashboard_styles.progressCount2,
                      color: "#18283E",
                    }}
                  >
                    547
                  </Typography>
                  <Typography sx={dashboard_styles.progressPercentage}>
                    {"(23%)"}
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    ...dashboard_styles.progressLabel,
                    backgroundColor: "#CCE0FA",
                    color: "#0063E7",
                  }}
                >
                  {"In Due"}
                </Typography>
              </BorderCard>
            </Grid>
            <Grid item xs={6}>
              <BorderCard>
                <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
                  <Typography
                    sx={{
                      ...dashboard_styles.progressCount2,
                      color: "#18283E",
                    }}
                  >
                    121
                  </Typography>
                  <Typography sx={dashboard_styles.progressPercentage}>
                    {"(77%)"}
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    ...dashboard_styles.progressLabel,
                    backgroundColor: "#FADDD5",
                    color: "#E6542F",
                  }}
                >
                  {"Failing"}
                </Typography>
              </BorderCard>
            </Grid>
            <Grid item xs={6}>
              <BorderCard>
                <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
                  <Typography
                    sx={{
                      ...dashboard_styles.progressCount2,
                      color: "#18283E",
                    }}
                  >
                    77
                  </Typography>
                  <Typography sx={dashboard_styles.progressPercentage}>
                    {"(77%)"}
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    ...dashboard_styles.progressLabel,
                    backgroundColor: "#D1F3D0",
                    color: "#1DC417",
                  }}
                >
                  {"Passing"}
                </Typography>
              </BorderCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box height={"24px"} />
      <Grid
        container
        spacing={{ xs: 2, md: 2, lg: 4 }}
        columns={{ xs: 1, sm: 8, md: 12 }}
      >
        <Grid item xs={3}>
          <BorderCard>
            <Typography textAlign={"center"} sx={dashboard_styles.subTitle2}>
              Impact Value
            </Typography>
            <div style={{ width: "150px", height: "150px" ,display:"flex", justifyContent:"center"}}>
            <Doughnut data={data2} options={options2} />
            </div>
          </BorderCard>
        </Grid>
        <Grid item xs={3}>
          <BorderCard>
            <Typography textAlign={"center"} sx={dashboard_styles.subTitle2}>
              Post Likelihood
            </Typography>
             <div style={{ width: "150px", height: "150px" ,display:"flex", justifyContent:"center"}}>
              <Doughnut data={data2} options={options2} />
            </div>
          </BorderCard>
        </Grid>
        <Grid item xs={3}>
          <BorderCard>
            <Typography sx={dashboard_styles.subTitle2}>SOC2 risk assessment</Typography>
            <Box>
              <Typography sx={dashboard_styles.inputLabel}>Risk</Typography>
              <Select
                sx={dashboard_styles.dropRootSx}
                id="demo-simple-select"
                // value={value}
                // onChange={onChange}
                placeholder={"placeholder"}
                size="small"
                fullWidth
                variant="outlined"
                // inputProps={{ 'aria-label': 'Without label' }}
                MenuProps={{
                  sx: { ...dashboard_styles.optionListSx },
                }}
                IconComponent={ExpandMoreRoundedIcon}
              >
                {selectOption?.map((option) => (
                  <MenuItem key={option?.value} value={option?.value}>
                    {option?.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box height={"12px"} />
            <Box>
              <Typography sx={dashboard_styles.inputLabel}>Risk Category</Typography>
              <Select
                sx={dashboard_styles.dropRootSx}
                id="demo-simple-select"
                // value={value}
                // onChange={onChange}
                placeholder={"placeholder"}
                size="small"
                fullWidth
                variant="outlined"
                // inputProps={{ 'aria-label': 'Without label' }}
                MenuProps={{
                  sx: { ...dashboard_styles.optionListSx },
                }}
                IconComponent={ExpandMoreRoundedIcon}
              >
                {selectOption?.map((option) => (
                  <MenuItem key={option?.value} value={option?.value}>
                    {option?.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </BorderCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SocSecurity;
const BorderCard = ({ height, width, children }) => {
  return (
    <Box sx={{ ...dashboard_styles.borderCard, height: height, width: width }}>
      {children}
    </Box>
  );
};
