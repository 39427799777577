// testSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  component: <></>,
  ViewPage: true,
  disabledPages: [], // Add a list to store disabled pages
};

const testSlice = createSlice({
  name: 'test',
  initialState,
  reducers: {
    BddtestReport(state, { payload }) {
      state.open = !state.open;
      state.component = payload.component ?? initialState.component;
    },
    ViewPageResult(state, { payload }) {
      state.ViewPage = !state.ViewPage;
    },
    // Add a new reducer to enable/disable a page
    disablePage(state, { payload }) {
      const { pageName, disabled } = payload;
      if (disabled) {
        state.disabledPages = [...state.disabledPages, pageName];
      } else {
        state.disabledPages = state.disabledPages.filter(
          (page) => page !== pageName
        );
      }
    },
  },
});

export const { BddtestReport, ViewPageResult, disablePage } = testSlice.actions;
export default testSlice.reducer;
