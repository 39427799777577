import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  Button,
  Divider,
  Avatar,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { ContentCopyOutlined, FileDownloadOutlined } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { Logout } from "../../../assets";
import MenuIcon from "@mui/icons-material/Menu";
import { LocalStorageKeys } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../router/routes";
import { useSelector } from "react-redux";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DownloadIcon from "@mui/icons-material/Download";
import { useStyles } from "./style";
import { TopLeftNav } from "./topLeftNav";
import { LoadingButton } from "@mui/lab";

export const TopNavBar = ({
  showTopLeftNav = false,
  showBackBtn = true,
  breadcrumbsLink,
  title = "",
  subTitle = "",
  prevURL = null,
  bddUrl=false,
  showActionBtn = false,
  actionBtnName = "",
  showActionBtnView = false,
  actionBtnNameView = "",
  isDownloadRefreshRequired = false,
  isSaveButton=false,
  saveButtonDisabled=false,
  saveChangeLoader=false,
  onChangeSaveButton=() => false,
  onCopyfeatures=()=>false,
  onDownloadFeatures=()=>false,
  onLinkcopyFeatures=()=>false,
  isBddFeatures = false,
  onDownload = () => {},
  onHomePage = () => false,
  viewProjects = () => false,
  onRefresh = () => false,
  actionBtnOnClick = () => false,
  loadingDownload = false,
  onbackScreenBtn = () => false,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.auth.userDetails);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogout = () => {
    localStorage.removeItem(LocalStorageKeys.authToken);
    window.location.href = AppRoutes.login;
  };

  const homeScreen = () => {
    navigate(AppRoutes.home);
    onHomePage();
  };

  return (
    <div className={classes.grow}>
      <div>
        {/* card */}
        <Box className={classes.card}>
          {/* Title */}
          <Box className={classes.header}>
            {showTopLeftNav === false && (
              <Box sx={{ cursor: "pointer" }} onClick={homeScreen}>
                Automatly
                <Typography
                  component={"p"}
                  variant={"caption"}
                  color={"textSecondary"}
                >{`v${localStorage.getItem(
                  LocalStorageKeys.version
                )}`}</Typography>
              </Box>
            )}
            {showTopLeftNav === true && (
              <TopLeftNav
                prevURL={prevURL}
                showBackBtn={showBackBtn}
                breadcrumbsLink={breadcrumbsLink}
                title={title}
                subTitle={subTitle}
                onbackScreenBtn={onbackScreenBtn}
                bddUrl={bddUrl}
              />
            )}
          </Box>

          {/*img & Dropdown */}
          <Box>
            <Box className={classes.profile}>
              {/* Action Button */}
              <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                {showActionBtn === true && (
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ fontFamily: "crayond_medium", fontSize: "14px" }}
                      onClick={actionBtnOnClick}
                    >
                      {actionBtnName}
                    </Button>
                  </Box>
                )}
                {showActionBtnView === true && (
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        fontFamily: "crayond_medium",
                        fontSize: "14px",
                        marginLeft: "12px",
                      }}
                      onClick={viewProjects}
                    >
                      {actionBtnNameView}
                    </Button>
                  </Box>
                )}
              </Box>
              <Box sx={{ display: { xs: "flex", sm: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  className={classes.menuList}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                    zIndex: 99,
                  }}
                >
                  {showActionBtn === true && (
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ fontFamily: "crayond_medium", fontSize: "14px" }}
                        onClick={actionBtnOnClick}
                      >
                        {actionBtnName}
                      </Button>
                    </Box>
                  )}
                  {showActionBtnView === true && (
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          fontFamily: "crayond_medium",
                          fontSize: "14px",
                          marginTop: "12px",
                        }}
                        onClick={viewProjects}
                      >
                        {actionBtnNameView}
                      </Button>
                    </Box>
                  )}
                </Menu>
              </Box>

              {isDownloadRefreshRequired && (
                <>
                  <Box>
                    <IconButton
                      onClick={onRefresh}
                      className={classes.downloadbtnSx}
                    >
                      <AutorenewIcon className={classes.iconSx} />
                    </IconButton>
                  </Box>
                  <Box>
                    {loadingDownload ? (
                      <CircularProgress size="small" color="inherit" />
                    ) : (
                      <IconButton
                        onClick={onDownload}
                        className={classes.downloadbtnSx}
                      >
                        <DownloadIcon className={classes.iconSx} />
                      </IconButton>
                    )}
                  </Box>
                </>
              )}

              {isBddFeatures && (
                <Box>
                  {isSaveButton && 
                  <LoadingButton
                  
                  className={classes.addFeaturesSx}
                  loading={saveChangeLoader}
                  disabled={saveButtonDisabled}
                  onClick={onChangeSaveButton}
                >
                  Save Changes
                </LoadingButton>
                }
                  <IconButton onClick={onCopyfeatures}>
                    <ContentCopyOutlined  className={classes.iconSx} size="small" />
                  </IconButton>
                  <IconButton>
                    <FileDownloadOutlined
                      onClick={onDownloadFeatures}
                      className={classes.iconSx}
                    />
                  </IconButton>
                  <IconButton onClick={onLinkcopyFeatures}>
                    <LinkIcon  className={classes.iconSx} size="small" />
                  </IconButton>
                </Box>
              )}
              {/* Divider*/}
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ margin: "8px 8px", height: "32px" }}
              />

              {/* Refresh  and Download */}
              {/*pro-img*/}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <HomeOutlinedIcon
                  onClick={homeScreen}
                  className={classes.profileicon}
                  sx={{
                    backgroundColor: "#f7f7f7",
                    color: "#c3c3c3",
                    borderRadius: "6px",
                    fontSize: "29px",
                  }}
                />

                <Box
                  onClick={handleClick}
                  onMouseEnter={handleClick}
                  className={classes.dropdown}
                >
                  <Avatar
                    alt=""
                    className={classes.profileicon}
                    sx={{
                      backgroundColor: "#ededed",
                      borderRadius: "20px",
                      fontSize: "16px",
                      color: "#8d8d8d",
                      opacity: anchorEl ? 0.3 : 1,
                    }}
                  >
                    {userData?.name?.charAt(0)?.toUpperCase()}
                  </Avatar>

                  {/*icons-up&down*/}
                  {anchorEl ? (
                    <KeyboardArrowUpIcon className={classes.icon} />
                  ) : (
                    <KeyboardArrowDownIcon className={classes.icon} />
                  )}
                </Box>
              </Box>
            </Box>
            {/* Menu list */}
            <Menu
              className={classes.Menu}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              <MenuItem onClick={handleLogout} className={classes.logout}>
                <Logout />
                <span className={classes.move}>Logout</span>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </div>
    </div>
  );
};
