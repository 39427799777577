export const BugIcon = () => 
(
  <svg xmlns="http://www.w3.org/2000/svg" width={"20px"} height={"19px"} >
    <path
      d="M6.178-.004a.76.76 0 0 0-.652.333.826.826 0 0 0-.094.749 5.181 5.181 0 0 0 1.869 2.26 2.486 2.486 0 0 0-1.3 1.1 5.751 5.751 0 0 1-.733-.287 3.183 3.183 0 0 1-.685-.44c-.123-.107-.119-.112-.119-.112a.76.76 0 0 0-.619-.325H2.308a.8.8 0 0 0 0 1.6h1.228c.032.031.024.031.06.062a4.718 4.718 0 0 0 1.012.66 10.275 10.275 0 0 0 4.573.877.741.741 0 0 0 .158-.016c.223.006.428.016.675.016s.435-.009.65-.014a.741.741 0 0 0 .155.014 10.275 10.275 0 0 0 4.573-.877 4.719 4.719 0 0 0 1.009-.661c.036-.031.028-.032.06-.062h1.227a.8.8 0 0 0 0-1.6h-1.534a.76.76 0 0 0-.619.325s0 .005-.119.112a3.183 3.183 0 0 1-.685.44 5.737 5.737 0 0 1-.748.292 2.509 2.509 0 0 0-1.282-1.107 5.182 5.182 0 0 0 1.863-2.253.826.826 0 0 0-.079-.726.763.763 0 0 0-.614-.356.771.771 0 0 0-.753.537 2.88 2.88 0 0 1-1.675 1.678 2.212 2.212 0 0 0-2.9 0A2.88 2.88 0 0 1 6.877.537a.773.773 0 0 0-.7-.537ZM3.049 6.471a.743.743 0 0 0-.215.041l-2.308.8a.807.807 0 0 0-.487 1.012.763.763 0 0 0 .973.506l2.042-.707c.074.038.291.137.478.223a6.894 6.894 0 0 0-.455 2.463 8.556 8.556 0 0 0 .611 3.219l-.487 1.52-2.088 1.448a.819.819 0 0 0-.212 1.108.752.752 0 0 0 1.067.222l2.307-1.6a.8.8 0 0 0 .3-.412l.144-.451a6.047 6.047 0 0 0 3.741 2.6.753.753 0 0 0 .769-.8v-7.196a.77.77 0 1 1 1.538 0v7.193a.753.753 0 0 0 .769.8 6.047 6.047 0 0 0 3.741-2.6l.144.451a.8.8 0 0 0 .3.412l2.308 1.6a.752.752 0 0 0 1.067-.222.819.819 0 0 0-.213-1.108l-2.082-1.445-.49-1.522a8.556 8.556 0 0 0 .611-3.219 6.894 6.894 0 0 0-.455-2.463l.478-.223 2.042.707a.763.763 0 0 0 .973-.506.807.807 0 0 0-.487-1.012l-2.308-.8a.744.744 0 0 0-.587.044 16.405 16.405 0 0 1-4.645 1.431c-.6.048-1.229.083-1.933.083s-1.338-.035-1.933-.083a16.406 16.406 0 0 1-4.645-1.43.745.745 0 0 0-.373-.084Z"
      fill="#bf0f9c"
    />
  </svg>
)

export const DefaulBugIcon = ()=>(
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={14.768} >
    <path
      d="M4.943-.004a.608.608 0 0 0-.522.266.66.66 0 0 0-.075.6 4.144 4.144 0 0 0 1.494 1.8 1.989 1.989 0 0 0-1.037.88 4.6 4.6 0 0 1-.586-.23 2.546 2.546 0 0 1-.548-.352c-.1-.086-.095-.09-.095-.09a.608.608 0 0 0-.5-.26H1.847a.64.64 0 0 0 0 1.279h.982c.025.024.019.025.048.05a3.775 3.775 0 0 0 .81.527 8.22 8.22 0 0 0 3.658.7.593.593 0 0 0 .126-.012c.178 0 .342.012.54.012s.348-.007.52-.011a.593.593 0 0 0 .124.011 8.22 8.22 0 0 0 3.658-.7 3.775 3.775 0 0 0 .81-.527c.029-.025.023-.026.048-.05h.982a.64.64 0 0 0 0-1.279h-1.23a.608.608 0 0 0-.5.26s0 0-.095.09a2.546 2.546 0 0 1-.548.352 4.59 4.59 0 0 1-.6.234 2.007 2.007 0 0 0-1.022-.887 4.145 4.145 0 0 0 1.49-1.8.661.661 0 0 0-.063-.581.61.61 0 0 0-.484-.282.617.617 0 0 0-.6.43 2.3 2.3 0 0 1-1.34 1.343 1.769 1.769 0 0 0-2.317 0A2.3 2.3 0 0 1 5.501.43.619.619 0 0 0 4.942 0Zm-2.5 5.177a.6.6 0 0 0-.172.032l-1.85.643a.646.646 0 0 0-.389.809.611.611 0 0 0 .779.4l1.633-.561c.059.03.233.11.382.179a5.515 5.515 0 0 0-.364 1.971 6.845 6.845 0 0 0 .489 2.575l-.39 1.218L.89 13.596a.655.655 0 0 0-.171.887.6.6 0 0 0 .853.177l1.846-1.279a.637.637 0 0 0 .243-.33l.115-.361a4.838 4.838 0 0 0 2.993 2.077.6.6 0 0 0 .615-.639V8.374a.616.616 0 1 1 1.231 0v5.755a.6.6 0 0 0 .615.639 4.838 4.838 0 0 0 2.993-2.072l.115.361a.637.637 0 0 0 .243.33l1.846 1.279a.6.6 0 0 0 .853-.177.655.655 0 0 0-.171-.887l-1.671-1.158-.391-1.218a6.845 6.845 0 0 0 .489-2.575 5.515 5.515 0 0 0-.364-1.971c.149-.069.323-.148.382-.179l1.633.566a.611.611 0 0 0 .779-.4.646.646 0 0 0-.389-.809l-1.846-.639a.6.6 0 0 0-.47.035 13.124 13.124 0 0 1-3.716 1.145c-.476.038-.983.066-1.547.066s-1.071-.028-1.547-.066a13.125 13.125 0 0 1-3.714-1.155.6.6 0 0 0-.298-.067Z"
      fill="#18283e"
    />
  </svg>
)

export const DefaultLockIcon =()=>(
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={17.5} >
  <path
    d="M7 0a3.51 3.51 0 0 0-3.5 3.5v1.75H1.969A1.979 1.979 0 0 0 0 7.219v8.313A1.979 1.979 0 0 0 1.969 17.5h10.062A1.979 1.979 0 0 0 14 15.531V7.219a1.979 1.979 0 0 0-1.969-1.969H4.813V3.5a2.186 2.186 0 0 1 4.366-.169.656.656 0 1 0 1.309-.1A3.507 3.507 0 0 0 7 0ZM1.969 6.563h2.08a.656.656 0 0 0 .213 0h7.77a.646.646 0 0 1 .656.656v8.313a.646.646 0 0 1-.656.656H1.969a.646.646 0 0 1-.656-.656V7.219a.646.646 0 0 1 .656-.656ZM7 10.063a1.313 1.313 0 1 0 1.312 1.312A1.312 1.312 0 0 0 7 10.063Z"
    fill="#18283E"
  />
</svg>

)