import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Button, Box, Grid, Avatar, IconButton, Modal, Divider } from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import ClearIcon from '@mui/icons-material/Clear';
// import Paper from '@mui/material/Paper';
// import { styled } from '@mui/material/styles';
import moment from "moment";
// import { Menu, MenuItem, IconButton } from "@mui/material"
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
// import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const useStyles = makeStyles((theme) => ({
  root: {},
  listview: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: "15px 15px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    borderRadius: "7px",
    margin: "0px auto",
    position: "relative",
  },
  listbox: {
    display: "flex",
    width: "300px",
    alignItems:'center'
  },
  more: {
    position: "absolute",
    right: "0px",
    top: "15px",
  },
  edit: {
    marginLeft: "8px",
  },
  border: {
    borderBottom: "1.5px solid #CBCBCB",
    margin: "4px",
  },
  morelist: {
    "& .MuiPopover-paper": {
      boxShadow: "0px 0px 7px 1px #b7b7b7",
    },
  },
  btn: {
    marginRight: "24px",
    fontFamily: "crayond_medium",
    fontSize: "14px",
    margin: "2px",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  btnt: {
    marginRight: "24px",
    fontFamily: "crayond_medium",
    fontSize: "14px",
  },
  iconBtn: {
    color: "#848484"
  },
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "55%",
    maxHeight: "60%",
    backgroundColor: '#FFF',
    border: '1px solid #0000',
    borderRadius: 10,
    boxShadow: 24,
    p: 4,
    [theme.breakpoints.down('sm')]: {
      width: "75%",
      height: "60%"
    }
  },
  titleSec: {
    padding: "10px 20px 10px 20px",
    display: "flex",
    justifyContent: "space-between",

  },
  fadedTitle: {
    color: "#18283ea6",
    fontWeight: "border",
    fontSize: 12,
  },
  boldBody: {
    color: "#18283E",
    fontSize: 16,
    fontWeight: "border"
  },
  scrollBody: {
    padding: 3,
    height: "60% !important",
    overflow: "auto"
  }
}));


export const ListView = ({ images = "", title = "", btnName = "", alter = "", onBtnClick = () => { }, onBtnView = () => { }, btnHelperText = "",
  btnVarient = "", btnViewText = "",titleSx={}, handleDelete = () => { }, data }) => {

  const classes = useStyles();


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const [anchorEl, setAnchorEl] = React.useState(null);


  // for future use
  // function handleClick(event) {
  //   if (anchorEl !== event.currentTarget) {
  //     setAnchorEl(event.currentTarget);
  //   }
  // }

  // function handleClose() {
  //   setAnchorEl(null);
  // }


  return (
    <div className={classes.listview}>
      {/* Cards */}
      <Box className={classes.listbox}>
        {/* Avatar */}
        <Avatar alt={alter} src={images} sx={{marginRight:'5px'}} />

        {/* Title */}
        <Typography gutterBottom variant="subtitle1" sx={{...titleSx, fontFamily: "Crayond_regular", padding: "5px 16x", margin: "0px", }} component="div">
          {title}
        </Typography>
        <IconButton className={classes.iconBtn} size="small" onClick={handleOpen}>
          <InfoOutlined fontSize="small" />
        </IconButton>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box>
          {btnHelperText.trim().length > 0 && (<Typography variant="caption" color="textSecondary"> {btnHelperText} </Typography>)}
          <Button className={classes.btn} variant={btnVarient} onClick={() => onBtnClick()}>
            {btnName}
          </Button>
          {btnViewText.trim().length > 0 && (
            <Button className={classes.btnt} variant="outlined" onClick={() => onBtnView()}>
              {btnViewText}
            </Button>
          )}

          {/* for future useage */}

          {/* Moreicon */}
          {/* <IconButton
            className={classes.more}
            onClick={handleClick}
          >
            <MoreVertIcon color="disabled" />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} className={classes.morelist}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }} 
          >
            <MenuItem onClick={handleClose} className={classes.border}>
              {" "}
              <EditOutlinedIcon color="primary" />
              <span className={classes.edit}>Edit</span>
            </MenuItem>
            <MenuItem onClick={handleDelete}>
              {" "}
              <DeleteOutlineOutlinedIcon color="warning" />
              <span className={classes.edit}>Delete</span>{" "}
            </MenuItem>
          </Menu> */}

        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box className={classes.modalBox}>
          <Box className={classes.titleSec} >
            <Typography id="modal-modal-title">
              {data?.name}
            </Typography>
            <IconButton className={classes.iconBtn} size="small" onClick={handleClose}>
              <ClearIcon fontSize="small" />
            </IconButton>
          </Box>
          <Divider />
          <Box sx={{
            padding: 3,
            height: "85% !important",
            overflow: "auto"
          }}>
            <Grid container spacing={2}>
              <Grid item sm={4} md={3} xs={12}>
                <Typography fontWeight={600} className={classes.fadedTitle}>Created On</Typography>
                <Typography fontWeight={600} classes={classes.boldBody}>{moment(data?.created_at).format("DD MMM, YY")}</Typography>
              </Grid>
              <Grid item sm={4} md={3} xs={12}>
                <Typography fontWeight={600} className={classes.fadedTitle}>Updated On</Typography>
                <Typography fontWeight={600} classes={classes.boldBody}>{moment(data?.updated_at).format("DD MMM, YY")}</Typography>
              </Grid>

              {data?.desc?.length > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} className={classes.fadedTitle}>{data?.server_count ? 'Project Description' : data?.os ? 'Instance Description' : 'Description'}</Typography>
                  <Typography fontWeight={600} classes={classes.boldBody}>{data?.desc}</Typography>
                </Grid>
              )}

              {
                data?.provider?.length > 0 && (
                  <Grid item sm={4} md={3} xs={12}>
                    <Typography fontWeight={600} className={classes.fadedTitle}>Instance Provider</Typography>
                    <Typography fontWeight={600} classes={classes.boldBody}>{data?.provider}</Typography>
                  </Grid>
                )
              }

              {
                data?.os?.length > 0 && (
                  <Grid item sm={4} md={3} xs={12}>
                    <Typography fontWeight={600} className={classes.fadedTitle}>Instance OS</Typography>
                    <Typography fontWeight={600} classes={classes.boldBody}>{data?.os}</Typography>
                  </Grid>
                )
              }

              {
                data?.version?.length > 0 && (
                  <Grid item sm={4} md={3} xs={12}>
                    <Typography fontWeight={600} className={classes.fadedTitle}>Instance Version</Typography>
                    <Typography fontWeight={600} classes={classes.boldBody}>{data?.version}</Typography>
                  </Grid>
                )
              }

              {
                data?.host?.length > 0 && (
                  <Grid item sm={4} md={3} xs={12}>
                    <Typography fontWeight={600} className={classes.fadedTitle}>Host</Typography>
                    <Typography fontWeight={600} classes={classes.boldBody}>{data?.host}</Typography>
                  </Grid>
                )
              }

              {
                data?.applications_count?.toString()?.length > 0 && (
                  <Grid item sm={4} md={3} xs={12}>
                    <Typography fontWeight={600} className={classes.fadedTitle}>Application Count</Typography>
                    <Typography fontWeight={600} classes={classes.boldBody}>{data?.applications_count}</Typography>
                  </Grid>
                )
              }


              {data?.git_group?.length > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} className={classes.fadedTitle}>Git Group</Typography>
                  <Typography fontWeight={600} classes={classes.boldBody}>{data?.git_group}</Typography>
                </Grid>
              )}


              {data?.git_repo?.length > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} className={classes.fadedTitle}>Git Repo</Typography>
                  <Typography fontWeight={600} classes={classes.boldBody}>{data?.git_repo}</Typography>
                </Grid>
              )}


              {data?.git_branch?.length > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} className={classes.fadedTitle}>Git Branch</Typography>
                  <Typography fontWeight={600} classes={classes.boldBody}>{data?.git_branch}</Typography>
                </Grid>
              )}


              {data?.run_command?.length > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} className={classes.fadedTitle}>Run Command</Typography>
                  <Typography fontWeight={600} classes={classes.boldBody}>{data?.run_command}</Typography>
                </Grid>
              )}


              {data?.build_command?.length > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} className={classes.fadedTitle}>Build Command</Typography>
                  <Typography fontWeight={600} classes={classes.boldBody}>{data?.build_command}</Typography>
                </Grid>
              )}


              {data?.build_directory?.length > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} className={classes.fadedTitle}>Build Directory</Typography>
                  <Typography fontWeight={600} classes={classes.boldBody}>{data?.build_directory}</Typography>
                </Grid>
              )}


              {data?.port?.lenth > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} className={classes.fadedTitle}>Port</Typography>
                  <Typography fontWeight={600} classes={classes.boldBody}>{data?.port}</Typography>
                </Grid>
              )}

              {data?.custom_domain?.length > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} className={classes.fadedTitle}>Custom Domain</Typography>
                  <Typography fontWeight={600} classes={classes.boldBody}>{data?.custom_domain}</Typography>
                </Grid>
              )}


              {data?.platform?.length > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} className={classes.fadedTitle}>Platform</Typography>
                  <Typography fontWeight={600} classes={classes.boldBody}>{data?.platform}</Typography>
                </Grid>
              )}




              {data?.dast?.toString()?.length > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} className={classes.fadedTitle}>Is DAST Activated</Typography>
                  <Typography fontWeight={600} classes={classes.boldBody}>{data?.dast ? 'Yes' : 'No'}</Typography>
                </Grid>
              )}


              {data?.sast?.toString()?.length > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} className={classes.fadedTitle}>Is SAST Activated</Typography>
                  <Typography fontWeight={600} classes={classes.boldBody}>{data?.sast ? 'Yes' : 'No'}</Typography>
                </Grid>
              )}


              {data?.need_testing?.toString()?.length > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} className={classes.fadedTitle}>Is Testing Activated</Typography>
                  <Typography fontWeight={600} classes={classes.boldBody}>{data?.need_testing ? 'Yes' : 'No'}</Typography>
                </Grid>
              )}


            </Grid>
          </Box>

        </Box>
      </Modal>
    </div>
  );
};
