import React from "react";
import { InstanceDetail } from "./instanceDetail";

class InstanceDetailParent extends React.Component {
    render() {
        return <InstanceDetail/>;
    }
}

export default InstanceDetailParent;
