import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useParams } from "react-router-dom";
import { TopNavBar } from "../../components";
import { useBDDReportQuery } from "../../redux/services";
import { AppRoutes } from "../../router/routes";
import { bddReportStyle } from "./style";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "#0063E70A",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const getColorForScenario = (val) => {
  let statusArray = val?.steps?.map((s) => {
    return s?.result?.status;
  });
  // temp2.filter((i) => { return i === "passed" })
  return (
    <div style={{ display: "flex" }}>
      {statusArray?.filter((i) => {
        return i === "passed";
      })?.length > 0 && (
        <Typography
          style={{
            height: "25px",
            width: "25px",
            backgroundColor: "#5CB85C",
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
            border: "1px",
            borderRadius: "3px",
            color: "white",
          }}
        >
          {
            statusArray?.filter((i) => {
              return i === "passed";
            })?.length
          }
        </Typography>
      )}

      {statusArray?.filter((i) => {
        return i === "failed";
      })?.length > 0 && (
        <Typography
          style={{
            height: "25px",
            width: "25px",
            backgroundColor: "#D9534F",
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
            border: "1px",
            borderRadius: "3px",
            marginLeft: "4px",
            color: "white",
          }}
        >
          {
            statusArray?.filter((i) => {
              return i === "failed";
            })?.length
          }
        </Typography>
      )}

      {statusArray?.filter((i) => {
        return i === "skipped";
      })?.length > 0 && (
        <Typography
          style={{
            height: "25px",
            width: "25px",
            backgroundColor: "#ffc107",
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
            border: "1px",
            borderRadius: "3px",
            marginLeft: "4px",
            color: "white",
          }}
        >
          {
            statusArray?.filter((i) => {
              return i === "skipped";
            })?.length
          }
        </Typography>
      )}
    </div>
  );
};

const getColorForFeature = (val) => {
  let hasFailed = val?.elements?.some((i) =>
    i?.steps?.some((s) => s?.result?.status === "failed")
  );
  return hasFailed ? ["#D9534F"] : ["#5CB85C"];
};

let getFlag = (val) => {
  if (val === "passed") {
    return <CheckCircleIcon style={{ color: "#1cb45f" }} />;
  }
  if (val === "failed") {
    return <CancelIcon style={{ color: "#f44336" }} />;
  }
  if (val === "skipped") {
    return <DoNotDisturbOnIcon style={{ color: "#ffc107" }} />;
  }
};

export const BDDReport = () => {
  const [viewScreenShot, setViewScreenShot] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  let {
    project_id,
    instance_id,
    application_id,
    linkText,
    linkTextTwo,
    linkTextThree,
    linkTextFour,
  } = useParams();

  const {
    data: bdd_data,
    isLoading,
    refetch,
  } = useBDDReportQuery(application_id);

  const [expanded, setExpanded] = React.useState("panel1");

  const [features, setFeatures] = useState({
    passed: [],
    failed: [],
  });

  const [scenarios, setScenarios] = useState({
    passed: [],
    failed: [],
  });

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [instance_expanded, setInstanceExpanded] = React.useState("panel1");

  const handleChangeInstanceExpand = (panel) => (event, newExpanded) => {
    setInstanceExpanded(newExpanded ? panel : false);
  };

  const setViewScreenShotFunc = () => {
    setViewScreenShot(!viewScreenShot);
  };

  const setErrorViewFunc = () => {
    setShowError(!showError);
  };

  const getColorForFeatureChart = () => {
    let unique = {
      passed: [],
      failed: [],
    };
    bdd_data.data.forEach((item, index) => {
      const id = index.toString();
      if (!item.elements) {
        return;
      }
      // item.elements.forEach((element) => {
      //   if (!element.id) {
      //     return;
      //   }
      //   const failed = element.steps.some(
      //     (step) => step.result?.status === "failed"
      //   );
      //   console.log(index,failed)
      //   if (!failed) {

      //     hasFailedStep = true;
      //   }
      // });

      let hasFailedStep = item.elements?.some((i) =>
        i?.steps?.some((s) => s?.result?.status === "failed")
      );

      if (hasFailedStep) {
        unique.failed.push(id);
      } else {
        unique.passed.push(id);
      }
    });
    setFeatures(unique);
  };

  const getColorForSceniorsChart = () => {
    let unique = {
      passed: [],
      failed: [],
    };
    bdd_data.data.forEach((item) => {
      if (!item.elements) {
        return;
      }
      item.elements.forEach((element, index) => {
        const id = index.toString();
        if (!element.id) {
          return;
        }
        const hasFailedStep = element.steps.some(
          (step) => step.result?.status === "failed"
        );
        if (hasFailedStep) {
          unique.failed.push(id);
        } else {
          unique.passed.push(id);
        }
      });
    });
    setScenarios(unique);
  };

  const getTotalLength = (obj) => {
    let totalLength = 0;

    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        totalLength += obj[key].length;
      }
    }

    return totalLength;
  };

  const options = {
    legend: "none",
    is3D: true,
    colors: ["#5CB85C", "#D9534F"],
    width: 280,
    height: 280,
  };

  useEffect(() => {
    refetch();
    if (bdd_data && bdd_data.data) {
      getColorForFeatureChart();
      getColorForSceniorsChart();
    }
    // eslint-disable-next-line
  }, [bdd_data]);

  return (
    <>
      <TopNavBar
        isDownloadRefreshRequired
        showTopLeftNav={true}
        breadcrumbsLink={[
          {
            pathName: linkText,
            link: AppRoutes.projectInstancesParent + project_id,
            subText: "Instance",
          },
          {
            pathName: linkTextTwo,
            link:
              AppRoutes.projectInstanceDetailParent +
              project_id +
              "/" +
              instance_id +
              "/" +
              linkText +
              "/" +
              linkTextTwo,
            subText: "Application",
          },
          {
            pathName: linkTextThree,
            link:
              AppRoutes.applications +
              project_id +
              "/" +
              instance_id +
              "/" +
              application_id +
              "/" +
              linkText +
              "/" +
              linkTextTwo +
              "/" +
              linkTextThree,
            subText: "Testing Report",
          },
        ]}
        title={linkTextFour}
        subTitle={"Perfomance"}
      />
      {isLoading && (
        <Box
          sx={{
            height: "100vh",
            width: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
      {!isLoading && (!bdd_data || bdd_data?.data === null) && (
        <Box
          sx={{
            height: "100vh",
            width: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Typography color={"gray"} variant="h4">
            No reports to show !
          </Typography>
        </Box>
      )}
      {!isLoading && bdd_data?.data?.length > 0 && (
        <Box sx={bddReportStyle.root}>
          {/* <Typography sx={bddReportStyle.applicationSx}>
                        {linkTextFour}
                    </Typography> */}
          <Grid container sx={bddReportStyle.gridContainer}>
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={4}
              sx={bddReportStyle.featureGrid}
            >
              <Paper sx={bddReportStyle.featurePaper}>
                <Box sx={bddReportStyle.featureDiv}>
                  <Typography
                    variant="subtitle2"
                    sx={bddReportStyle.featureTypo}
                  >
                    {bdd_data?.data?.length} {"Features"}
                  </Typography>
                </Box>
                <Box sx={{ ...bddReportStyle.totalTitleSx }}>
                  {features?.passed && (
                    <Typography sx={{ ...bddReportStyle.featuresSub }}>
                      <CircleIcon
                        sx={{ ...bddReportStyle.circleSx, color: "#5CB85C" }}
                      />{" "}
                      Passed
                    </Typography>
                  )}
                  {features?.failed && (
                    <Typography sx={{ ...bddReportStyle.featuresSub }}>
                      <CircleIcon
                        sx={{ ...bddReportStyle.circleSx, color: "#D9534F" }}
                      />
                      Failed
                    </Typography>
                  )}
                </Box>
                {/* <Box sx={bddReportStyle.featureCircDiv}> */}
                {bdd_data &&
                bdd_data?.data &&
                bdd_data?.data.length > 0 &&
                (features?.passed?.length > 0 ||
                  features?.failed?.length > 0) ? (
                  <Chart
                    chartType="PieChart"
                    data={[
                      ["bdd", "report"],
                      ["Passed", features.passed.length],
                      ["Failed", features.failed.length],
                    ]}
                    options={options}
                  />
                ) : (
                  <Box sx={{ ...bddReportStyle.noSx }}>
                    <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                      No Features Founded !!!
                    </Typography>
                  </Box>
                )}
                {/* </Box> */}
              </Paper>
            </Grid>

            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={4}
              sx={bddReportStyle.scenarioGrid}
            >
              <Paper sx={bddReportStyle.scenarioPaper}>
                <Box sx={bddReportStyle.scenarioDiv}>
                  <Typography
                    variant="subtitle2"
                    sx={bddReportStyle.scenarioTypo}
                  >
                    {getTotalLength(scenarios)} {"Scenario"}
                  </Typography>
                </Box>
                <Box sx={{ ...bddReportStyle.totalTitleSx }}>
                  {scenarios?.passed && (
                    <Typography sx={{ ...bddReportStyle.featuresSub }}>
                      <CircleIcon
                        sx={{ ...bddReportStyle.circleSx, color: "#5CB85C" }}
                      />{" "}
                      Passed
                    </Typography>
                  )}
                  {scenarios?.failed && (
                    <Typography sx={{ ...bddReportStyle.featuresSub }}>
                      <CircleIcon
                        sx={{ ...bddReportStyle.circleSx, color: "#D9534F" }}
                      />
                      Failed
                    </Typography>
                  )}
                </Box>
                {bdd_data &&
                bdd_data?.data &&
                bdd_data?.data.length > 0 &&
                (scenarios?.passed?.length > 0 ||
                  scenarios?.failed?.length > 0) ? (
                  <Chart
                    chartType="PieChart"
                    data={[
                      ["bdd", "report"],
                      ["Passed", scenarios.passed.length],
                      ["Failed", scenarios.failed.length],
                    ]}
                    options={options}
                    width={"100%"}
                    height={"212px"}
                  />
                ) : (
                  <Box sx={{ ...bddReportStyle.noSx }}>
                    <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                      No scenarios Founded !!!
                    </Typography>
                  </Box>
                )}
              </Paper>
            </Grid>

            {/* <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={bddReportStyle.metaGrid}
            >
              <Paper sx={bddReportStyle.metaPaper}>
                <Box sx={bddReportStyle.metaDiv}>
                  <Typography variant="subtitle2" sx={bddReportStyle.metaTypo}>
                    {"Meta Data"}
                  </Typography>
                </Box>
                <Box sx={bddReportStyle.metaContParentDiv}>
                  <Box sx={bddReportStyle.metaContParentSubDiv1}>
                    <Box sx={bddReportStyle.metaContAppDiv}>
                      <Typography
                        sx={bddReportStyle.metaContApVeTypo}
                        variant="body2"
                      >
                        App Version
                      </Typography>
                      <Typography
                        sx={bddReportStyle.metaContApVeVrTypo}
                        variant="subtitle2"
                      >
                        0.3.2
                      </Typography>
                    </Box>

                    <Box sx={bddReportStyle.metaContBrowserDiv}>
                      <Typography
                        sx={bddReportStyle.metaContBrowserTypo}
                        variant="body2"
                      >
                        Browser
                      </Typography>
                      <Typography
                        sx={bddReportStyle.metaContBrowserVr}
                        variant="subtitle2"
                      >
                        Chrome 0.3.2
                      </Typography>
                    </Box>

                    <Box sx={bddReportStyle.metaContScenariosDiv}>
                      <Typography
                        sx={bddReportStyle.metaContParaTypo}
                        variant="body2"
                      >
                        Parallel
                      </Typography>
                      <Typography
                        sx={bddReportStyle.metaContScenrTypo}
                        variant="subtitle2"
                      >
                        Scenarios
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={bddReportStyle.metaContParentSubDiv2}>
                    <Box sx={bddReportStyle.metaContStagingDiv}>
                      <Typography
                        sx={bddReportStyle.metaContTeTypo}
                        variant="body2"
                      >
                        Test Environment
                      </Typography>
                      <Typography
                        sx={bddReportStyle.metaContStagTypo}
                        variant="subtitle2"
                      >
                        STAGING
                      </Typography>
                    </Box>

                    <Box sx={bddReportStyle.metaContPlatDiv}>
                      <Typography
                        sx={bddReportStyle.metaContPlatTypo}
                        variant="body2"
                      >
                        Platfom
                      </Typography>
                      <Typography
                        sx={bddReportStyle.metaContPlatOsTypo}
                        variant="subtitle2"
                      >
                        Windows 10
                      </Typography>
                    </Box>

                    <Box sx={bddReportStyle.metaContExeDiv}>
                      <Typography
                        sx={bddReportStyle.metaContExeTypo}
                        variant="body2"
                      >
                        Executed
                      </Typography>
                      <Typography
                        sx={bddReportStyle.metaContRemoTypo}
                        variant="subtitle2"
                      >
                        Remote
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid> */}

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={bddReportStyle.bddAcord}
            >
              <Typography sx={{ ...bddReportStyle.featuresHead }}>
                Features
              </Typography>

              {bdd_data?.data?.map((item, index) => {
                return (
                  <Accordion
                    expanded={expanded === index}
                    onChange={handleChange(index)}
                  >
                    <AccordionSummary
                      key={index}
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <Typography
                          key={index}
                          sx={{ pr: 1, fontWeight: "600" }}
                        >
                          {item?.name + " "}
                        </Typography>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            pr: 1,
                          }}
                        >
                          {item?.tags?.map((val) => (
                            <Typography
                              key={index}
                              sx={{
                                color: "#F17C00",
                                pr: 1,
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              {val?.name}
                            </Typography>
                          ))}
                        </Box>
                      </Box>

                      <Box
                        style={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "flex-end",
                        }}
                      >
                        <Chip
                          label={
                            getColorForFeature(item)[0] === "#D9534F"
                              ? "Failed"
                              : "Passed"
                          }
                          sx={{
                            backgroundColor: getColorForFeature(item)[0],
                            color: "white",
                            textAlign: "center",
                            justifyContent: "center",
                            display: "flex",
                            height: "25px",
                            width: "85px",
                          }}
                        />
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        sx={{ ...bddReportStyle.featuresHead, py: 1 }}
                      >
                        Scenarios
                      </Typography>

                      {item?.elements?.map((el, index) => {
                        return (
                          <Accordion
                            key={index}
                            expanded={instance_expanded === index}
                            onChange={handleChangeInstanceExpand(index)}
                          >
                            <AccordionSummary
                              key={index}
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexWrap: "wrap",
                                }}
                              >
                                <Typography
                                  key={index}
                                  sx={{ pr: 1, fontWeight: "600" }}
                                >
                                  {el?.name}
                                </Typography>
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    pr: 1,
                                  }}
                                >
                                  {el?.tags?.map((val) => (
                                    <Typography
                                      key={index}
                                      sx={{
                                        color: "#F17C00",
                                        pr: 1,
                                        fontWeight: "500",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {val?.name}
                                    </Typography>
                                  ))}
                                </Box>
                              </Box>
                              <Box
                                style={{
                                  display: "flex",
                                  flex: 1,
                                  justifyContent: "flex-end",
                                }}
                              >
                                {getColorForScenario(el)}
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                              {el.steps?.map((st, index) => {
                                return (
                                  <>
                                    <Box
                                      key={index}
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <Box
                                        style={{
                                          display: "flex",
                                          width: "100%",
                                        }}
                                      >
                                        <Typography pr={0.7}>
                                          {getFlag(st?.result?.status)}
                                        </Typography>
                                        <Typography
                                          sx={{ ...bddReportStyle.seneiorsSx}}
                                        >
                                          <span style={{color:'#0063E7'}}>{st?.keyword}</span>{st?.name}
                                        </Typography>
                                        {/* <Box style={{ width: "10%" }}>{st?.result?.duration}</Box> */}
                                      </Box>
                                      <Box>
                                        {st?.result?.error_message ? (
                                          <Button
                                            onClick={() => {
                                              setErrorViewFunc();
                                            }}
                                          >
                                            {"show error"}
                                          </Button>
                                        ) : (
                                          <></>
                                        )}
                                        {st?.embeddings?.length > 0 ? (
                                          <Button
                                            onClick={() => {
                                              setViewScreenShotFunc();
                                            }}
                                          >
                                            {"view screenshot"}
                                          </Button>
                                        ) : (
                                          <></>
                                        )}
                                      </Box>
                                      {st?.result?.error_message?.length > 0 ? (
                                        <Box style={{ width: "100%" }}>
                                          {showError && (
                                            <Box sx={bddReportStyle.errorTest}>
                                              {st?.result?.error_message?.split('\n')?.filter(line => !line.trim().startsWith('at'))?.map((line, index) => (
                                                    <React.Fragment key={index}>
                                                      <Typography sx={{ fontSize:'13px', color:'#333' }}>{line}</Typography>
                                                    </React.Fragment>
                                              ))}
                                              </Box>
                                          )}
                                        </Box>
                                      ) : (
                                        <></>
                                      )}
                                      {st?.embeddings?.length > 0 ? (
                                        <Box style={{ width: "100%" }}>
                                          {viewScreenShot ? (
                                            <img
                                              src={
                                                "data:" +
                                                st?.embeddings[0]?.mime_type +
                                                ";base64," +
                                                st?.embeddings[0]?.data
                                              }
                                              alt="img"
                                            ></img>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      ) : (
                                        <></>
                                      )}
                                    </Box>
                                  </>
                                );
                              })}
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};
