export const dialogStyle = {
    rootSx: {},
    closebtnSx: {
        color: "#fff",
        textTransform: "capitalize",
        fontSize: "12px",
        fontWeight: "500",
        fontFamily: "crayond_regular",
        // p: "4px 12px",
        lineHeight: '1rem',
        padding: "0.25rem 0.5rem",
        // columnGap: "0.5rem",
        "&:hover": {
          // backgroundColor: "#c35252",
          color: "#fff",
        },
          borderRadius: '0.25rem',
         
      },
      bodyContent:{
        color:'#000',fontSize:'14px'
      },
      btnSx:{
      marginTop:'16px',padding:'0px'
      }
  };
  