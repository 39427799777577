import AssignmentIcon from "@mui/icons-material/Assignment";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Menu, MenuItem, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
// import { FeatureWriter } from "../featureWriter";
import { BddFeatures } from "../bddFeatures";
import { useNavigate } from "react-router-dom";

// import { AppRoutes } from '../../router/routes';

const useStyles = makeStyles((theme) => ({
  root: {},
  tablebox: {
    backgroundColor: "#fff",
    boxShadow: "0px 1px 7px 1px #e1e1e1",
    padding: "5px 5px",
    borderRadius: "12px",
  },
  linkto: {
    textDecoration: "none",
    color: "#0063E7",
  },
  btnsm: {
    width: "120px",
    textTransform: "capitalize",
    color: "#18283E",
    fontFamily: "crayond_regular",
  },
  btnsmView: {
    marginRight: "6px",
    width: "120px",
    textTransform: "capitalize",
    color: "#18283E",
    fontFamily: "crayond_regular",
  },
  aicon: {
    fontSize: "17px",
    margin: "-5px 5px",
  },
  issues: {
    padding: "0px 5px",
  },
  loadingSx: {
    width: " 20px !important",
    height: "20px !important",
  },
  modalSx: { display: "grid", placeItems: "center" },
  modalInnerWrapperSx: {
    position: "absolute",
    minHeight: "90%",
    maxHeight: "90%",
    overflow: "auto",
    borderRadius: "8px",
    padding: "16px",
    width: "88%",
    backgroundColor: "#f8fafc",
  },
  progressWrapper: {
    display: "grid",
    placeItems: "center",
    minHeight: "500px",
  },
  closeSx: {
    position: "absolute",
    right: "8px",
    top: "2px",
  },
}));

export const ApplicationMenu = ({ className = "", application, ...rest }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modalShow, setModalShow] = React.useState(null);
  const navigate = useNavigate()
  const classes = useStyles();
  function handleClose() {
    setAnchorEl(null);
  }

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  const handleModalOpen = (application) => {
    // setModalShow(application?.id);
    handleClose();
    navigate(`/bddFeatures/${application?.project_id}/${application?.data?.server_id}/${application?.id}/${application?.linkText}/${application?.linkTextTwo}/${application?.secondarytext}`)
  };

  const handleAccept = () => {
    setModalShow(null);
  };
  

  return (
    <Box sx={{ display: "inline",margin:'0px 4px' }} className={`${className}`} {...rest}>
      <IconButton className={classes.more} onClick={handleClick}>
        <MoreVertIcon color="disabled" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className={classes.morelist}
      >
        <MenuItem
          onClick={() => { handleModalOpen(application)}} className={classes.border}>
          {" "}
          <AssignmentIcon color="primary" />
          <span className={classes.edit}>Write Test</span>
        </MenuItem>
        <MenuItem onClick={handleClose} className={classes.border}>
          {" "}
          <EditOutlinedIcon color="primary" />
          <span className={classes.edit}>Edit</span>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          {" "}
          <DeleteOutlineOutlinedIcon color="warning" />
          <span className={classes.edit}>Delete</span>{" "}
        </MenuItem>
      </Menu>
      {/* {modalShow && (
        <FeatureWriter
          handleModalClose={handleModalClose}
          applicationId={modalShow}
        />
      )} */}
      {modalShow && (
        <Modal
          open={true}
          // closeAfterTransition
          // disableEnforceFocus
          className={classes.modalSx}
        >
          <Box className={classes.modalInnerWrapperSx}>
            {/* <IconButton
              className={classes.closeSx}
              onClick={() => {
                handleClickOpen();
              }}
            >
              <CloseIcon />
            </IconButton> */}
            <Box sx={{ mt: 2 }}>
              <BddFeatures  application_id={modalShow} handleAccept={handleAccept} />
            </Box>
          </Box>
        </Modal>
      )}
    </Box>
  );
};
