import { BugIcon, LockIcon, ModeIcon, ProtectIcon } from "../../assets";

export const filteredData = [
  {
    key: "bugs",
    icon: <BugIcon />,
    card_key: "bugs",
    color: "#BF0F9C",
    metric_key: "reliability_rating",
    toolTip:
      "A - 0 Bugs, B - atleast 1 minor bug, C - atleast 1 major bug, D - atleast 1 critical bug, E - atleast 1 blocker",
  },
  {
    key: "vulnerabilities",
    card_key: "vulnerabilities",
    icon: <LockIcon />,
    color: "#4A0FBF",
    metric_key: "security_rating",
    toolTip:
      "A - 0 vulnerability B - atleast 1 minor vulnerability C - atleast 1 critical vulnerability E - atleast 1 blocker vulnerability",
  },
  {
    key: "code_smells",
    card_key: "code_smells",
    icon: <ModeIcon />,
    color: "#0F9CBF",
    metric_key: "sqale_rating",
    toolTip:
      "A - 0 - 0.05 B -> 0.06 - 0.1 C -> 0.11 - 0.20 D -> 0.21 - 0.5 E -> 0.51 - 1",
  },
  {
    key: "security_hotspots",
    card_key: "security_hotspots",
    s_key: "security_hotspots_reviewed",
    metric_key: "security_review_rating",
    icon: <ProtectIcon />,
    color: "#0F9CBF",
    toolTip:
      "A -> >80% B -> >=70% and < 80% C -> >=50% and <70% D -> >=30% and <50% E -> <30%",
  },
];

export const filtereDublicatedData = [
  {
    d_key: "duplicated_blocks",
    prs: "duplicated_lines_density",
    value: "duplicated_blocks",
    ncloc: "ncloc",
    color: "#0063E7",
  },
];
