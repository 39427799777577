export const dashboard_styles = {
  rootSx: {
    paddingTop: "75px",
    backgroundColor: "#FAFAFA",
    position: "relative",
    "& .MuiTabs-flexContainer": {
      paddingTop: "10px",
    },
  },
  applicationCardSx: {
    // paddingTop: { xs: "171px",sm:'67px', md: "74px", lg: "0px" },
    "@media (max-width: 990px)": {
      paddingTop: "70px",
    },
    "@media (max-width: 600px)": {
      paddingTop: "170px",
    },

    justifyContent: { xs: "center", md: "space-evenly", lg: "space-between" },
  },
  filedContainer: {
    borderColor: "rgba(0, 0, 0, 0.12)",
    position: "fixed",
    zIndex: "99",
    "@media (max-width: 990px)": {
      justifyContent: "center",
      backgroundColor: "#fff",
      top: "130px",
      left: "0px",
    },
    "@media (max-width: 600px)": {
      justifyContent: "center",
      top: "234px",
      left: "0px",
    },
    top: "70px",

    right: {
      lg: "8px",
      md: "0px",
      xs: "12px",
    },
    justifyContent: "end",
    padding: "10px 0",
    display: "flex",
    alignItems: "center",
    gap: "16px",
    // zIndex: "5",
  },
  dropRootSx: {
    ".MuiPaper-root": {
      left: 4,
    },
    borderRadius: "8px",
    minWidth: "100px",
  },
  filterBtn: {
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  detailsContainer: {
    padding: {
      xs: "62px 14px",
      sm: "62px 14px",
      md: "62px 40px",
      lg: "84px 40px",
    },
  },
  applications: {
    backgroundColor: "#fff",
    width: "298px",
    height: "77px",
    borderRadius: "8px",
    border: "1px solid #DFDFDF",
    padding: "12px 18px",
  },
  applicationsText: {
    fontWeight: "700",
    fontSize: "18px",
    color: "#18283E",
  },
  applicationsText2: {
    fontWeight: "700",
    fontSize: "24px",
    color: "#18283E",
  },
  title: {
    fontWeight: "700",
    fontSize: "20px",
    color: "#18283E",
  },
  subTitle: {
    fontWeight: "700",
    fontSize: "14px",
    color: "#18283E",
    marginTop: "24px",
    marginBottom: "20px",
  },
  subTitle2: {
    fontWeight: "700",
    fontSize: "16px",
    color: "#18283E",
    marginBottom: "12px",
  },
  label: {
    backgroundColor: "#305AAE2f",
    color: "#305AAE",
    fontSize: "12px",
    padding: "2px 6px",
    borderRadius: "6px",
  },
  progressCount: {
    fontWeight: "800",
    fontSize: "44px",
  },
  progressCount2: {
    fontWeight: "800",
    fontSize: "28px",
  },
  progressPercentage: {
    fontSize: "14px",
    color: "#848484",
    fontWeight: "600",
  },
  progressLabel: {
    marginTop: "10px",
    fontWeight: "500",
    fontSize: "12px",
    borderRadius: "6px",
    display: "inline-block",
    padding: "3px 8px",
  },
  borderCard: {
    height: "100%",
    border: "1px solid #DFDFDF",
    borderRadius: "8px",
    padding: "18px",
    backgroundColor: "#FFFFFF",
  },
  inputLabel: {
    fontSize: "14px",
    color: "#848484",
    marginBottom: "8px",
  },
  autocompeleteSx: {
    "& .MuiAutocomplete-option[data-focus='true']": {
      backgroundColor: "#000",
    },
    "& .MuiAutocomplete-option[data-focus='false'][aria-selected='true']": {
      backgroundColor: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
      paddingLeft: "14px !important",
      paddingRight: "28px !important",
      height: "42px!important",
      backgroundColor: "#fff",
      "&.MuiAutocomplete-option.Mui-focused": {
        backgroundColor: "#000",
      },
    },
  },
  optionListSx: {
    border: "1.5px solid",
    borderColor: "#eaeaea",
    boxShadow: "0px -5px 10px #0000000A",
    borderRadius: "8px",
    mt: 1,
  },
  autoListSx: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "border.main",
      borderRadius: "8px",
    },
    "& .MuiOutlinedInput-root": {
      paddingTop: "10px !important",
      paddingBottom: "10px !important",
      paddingLeft: "14px !important",
      paddingRight: "14px !important",
      "& fieldset": {
        borderRadius: "8px",
        border: "1.5px solid",
        borderColor: "border.main",
      },
      "&:hover fieldset": {
        borderColor: "border.hover",
      },
      "&.Mui-focused fieldset": {
        borderColor: "border.focus",
      },
    },
    "& .MuiFormHelperText-root": {
      mx: 0,
    },
  },
  underTab: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      "@media (max-width: 600px)": {
        display: "grid",
      },
    },
  },
  dataTabRootSx: {
    position: "fixed",
    width: "100%",
    zIndex: "99",
    margin: "-5px 0px",
  },
  loaderSx: {
    padding: "10px 10px",
    justifyContent: { xs: "center", md: "space-evenly", lg: "space-between" },
  },
  root: {
    textAlign: "center",
    backgroundImage: "url('/images/jpg/signInSignUpBg.png')",
    height: "100%",
    backgroundSize: "cover",
  },
  applicationSx: {
    marginTop: "20px",
  },
  emptyState: {
    textAlign: "center",
    padding: "12px",
  },
  emptyTitle: {
    fontSize: "14px",
    fontWeight: "600",
    paddingTop: "8px",
  },
  performanceCard: {
    cursor: "pointer",
  },
};
