import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "./routes";
import { Access } from "./access";
import { LocalStorageKeys, parseJwt } from "../utils";
import moment from "moment";

const PrivateRoute = ({ path, children, ...rest }) => {
  const navigate = useNavigate();
  const location = useLocation();


// get the authToken from local storage
const authToken = localStorage.getItem(LocalStorageKeys?.authToken);

  const isAuthenticated = (path) => {
    if (authToken) {
      const _ = Access("role", path);
      if (_ >= 0) {
        return true;
      };
      return false;
    } else {
      return false;
    }
  };

  useEffect(()=>{
    if (authToken) {
      const data = parseJwt(authToken);
      // If expire date is lesser than current date & time clear the auth token in local storage and redirect to login page
      if (moment().unix() >= data?.exp) {
        localStorage.clear();
        navigate(AppRoutes?.login);
        return;
      }
    }
    // eslint-disable-next-line
  },[location])

  return (
    <>
      {
        isAuthenticated(path) ? children : (
          <Navigate
            to={AppRoutes.login}
            state={{ from: path }}
          />
        )
      }
    </>
  );
};

export default PrivateRoute;
