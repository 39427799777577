import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TextField from "@mui/material/TextField";
import { CircularProgress } from "@mui/material";

export const AutocompleteCmp = (props) => {
  return (
    <Autocomplete
      multiple={props?.multiple}
      className={props?.className}
      limitTags={props?.limitTags}
      id={props?.id}
      onClose={props?.onClose}
      options={props?.options}
      disableClearable={props?.disableClearable}
      getOptionLabel={(option) => option.label || ""}
      // defaultValue={props?.defaultValue ?? ''}
      value={props?.value}
      onChange={props?.onChange}
      popupIcon={<KeyboardArrowDownIcon sx={{color:'#6A7888'}}/>}
      ListboxProps={props?.ListboxProps}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props?.label}
          placeholder={props?.placeholder}
         
          InputProps={ {...params.InputProps,
            
            endAdornment: (
              <React.Fragment>
                {props?.isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      
      sx={{
        width: props?.width ? props?.width : "320px",
        ...props?.autocompletedClass,
      }}
    />
  );
};
