import React from "react";
import { PerformanceReport } from "./performanceReport";

class ErrorLogParent extends React.Component {
    render() {
        return <PerformanceReport/>
    }
}

export default ErrorLogParent;
