import React from "react";
import { BddFeaturesReport } from "./bddFeatures";

class BddFeaturesReportParent extends React.Component {
  render() {
    return <BddFeaturesReport />;
  }
}

export default BddFeaturesReportParent;
