import AnnouncementIcon from "@mui/icons-material/Announcement";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Modal,
  Grid,
  IconButton,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  CircularProgress,
} from "@mui/material";
// import { makeStyles } from "@mui/styles";
import React, {  useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useLazyRunBDDQuery } from "../../redux/services";
import { ApplicationMenu } from "../applicartionMenu";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment";
import { useDispatch } from "react-redux";
import { BddtestReport } from "../../redux/slices/testReport";
import { useSelector } from "react-redux";
import { healthlistStyle } from "./style";

export const HealthList = ({
  healthData = [],
  loading = true,
  progressData = {},
}) => {
  // const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { application_id } = useParams();
  const [runBDD, { isFetching: bddFetching }] = useLazyRunBDDQuery();
  let BddLoader = useSelector((state) => state.test.open);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const handleOpen = (data) => {
    setData(data);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const Status = {
    "No issue": (
      <CheckCircleIcon sx={{ ...healthlistStyle.aicon, color: "#00B17E" }} />
    ),
    Incident: (
      <HandymanOutlinedIcon color="primary" sx={healthlistStyle.aicon} />
    ),
    Notice: (
      <AnnouncementIcon sx={{ ...healthlistStyle.aicon, color: "#0063E7" }} />
    ),
    Maintenance: (
      <ReportProblemIcon sx={{ ...healthlistStyle.aicon, color: "#F9BB40" }} />
    ),
    Outage: (
      <RemoveCircleIcon sx={{ ...healthlistStyle.aicon, color: "#FF4141" }} />
    ),
  };
  // const isButtonDisabled = useSelector((state) => {
  //   // Replace 'examplePage' with the actual page name you want to check
  //   return state.test.disabledPages.includes('examplePage');
  // });
  
  const clickMoniterBtn = (e, key) => {
    if (key === "toastReport") {
      let linkTextThree = e.secondarytext;
      let findTab =
        `/application/${e?.project_id}/${e?.instance_id}/${e?.application_id}/${e?.linkText}/${e?.linkTextTwo}?tab=1`
          ?.replaceAll("?tab=1", "")
          .replaceAll("?tab=2", "");
      navigate(`${findTab}/${linkTextThree}?tab=${1}`);
    }
    let linkTextThree = e.secondarytext;
    let findTab = e.route?.replaceAll("?tab=1", "").replaceAll("?tab=2", "");
    navigate(`${findTab}/${linkTextThree}?tab=${1}`);
  };

  const testingReport = (val) => {
    dispatch(BddtestReport({ open: true }));
    runBDD(application_id)
      .then((data) => {
        // Close the loading toast when you have a message
        if (data?.data?.message || data?.isError) {
          toast.dismiss();
          dispatch(BddtestReport({ open: false }));
        }
        if (data?.isError) {
          toast.error("BDD Test Ran Unsuccessful!!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast(
            <Box>
              <Box sx={{ ...healthlistStyle.loadingCompSx, pb: 1 }}>
                <CheckCircleIcon sx={{ color: "#00B17E", mr: 1 }} />
                <Typography sx={{ ...healthlistStyle.bddTitleSx }}>
                  {" "}
                  BDD Test Ran successfully !!
                </Typography>
              </Box>
             <Box sx={{ ...healthlistStyle.loadingCompSx, pb: 1 }}>

             <Button
                sx={{ ...healthlistStyle.addFeaturesSx, }}
                onClick={() => viewTestingReport("BDD Report", "bdd")}
              >
                View 
              </Button>
              <Typography sx={{...healthlistStyle.bddTitleSx,fontWeight:'500',
              }}>BDD Report For {val?.linkTextThree}</Typography>
             </Box>
                     
            
            </Box>,
            {
              autoClose: false, // Set autoClose to false
              position: "top-right",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch(
      BddtestReport({
        component: toast(
          <Box sx={healthlistStyle.loadingCompSx}>
            <CircularProgress sx={{ ...healthlistStyle.loaderSx }} />
            <Typography sx={{ ...healthlistStyle.bddTitleSx }}>
              {" "}
              Running BDD Test !!!
            </Typography>
          </Box>,
          {
            position: "top-right",

            theme: "light",
          }
        ),
      })
    );
    dispatch(BddtestReport({ open: false }));
    // dispatch(disablePage({ pageName: 'examplePage', disabled: false }));
  };

  function runReport(test, val) {
    switch (test) {
      case "BDD Report":
        testingReport(val);
        break;
      default:
    }
  }

  function disableView(test) {
    if (test?.btnViewDisable) {
      return true;
    }
    switch (test.secondarytext) {
      case "BDD Report":
        if (bddFetching) {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  }

  function isItLoading(test) {
    switch (test) {
      case "BDD Report":
        if (bddFetching) {
          return BddLoader;
        } else {
          return BddLoader;
        }
      default:
        return false;
    }
  }

  const viewLogReport = (e, key) => {
    let linkTextFour = e.secondarytext;
    navigate(
      `${e.route}/${e.linkText}/${e.linkTextTwo}/${e.linkTextThree}/${linkTextFour}`
    );
  };

  const viewTestingReport = (e, key) => {
    let results = healthData?.map((val) => val);
    if (key === "bdd") {
      let val = results?.[3];
      navigate(
        `${val.view_route}/${val.linkText}/${val.linkTextTwo}/${val.linkTextThree}/${e}`
      );
    } else {
      let linkTextFour = e.secondarytext;
      navigate(
        `${e.view_route}/${e.linkText}/${e.linkTextTwo}/${e.linkTextThree}/${linkTextFour}`
      );
    }
  };

  return (
    <>
      <Box>
        {/* Title */}
        {/* Health Table*/}
        <TableContainer component={Paper} sx={healthlistStyle.tablebox}>
          <Table
            sx={{ minWidth: 950, borderRadius: "12px" }}
            size="small"
            aria-label="a dense table"
          >
            {healthData?.length > 0 &&
              healthData?.map((e, index) => (
                <TableBody key={index}>
                  <TableRow>
                    <TableCell align="left">
                      <div>
                        {e.secondarytext}
                        {Object.keys(progressData).length
                          ? progressData[e.id] &&
                            progressData[e.id].value !== 100 &&
                            (progressData[e.id].value ? (
                              <LinearProgress
                                variant="determinate"
                                value={
                                  Object.keys(progressData).length
                                    ? progressData[e.id].value
                                    : 0
                                }
                              />
                            ) : (
                              ""
                            ))
                          : ""}
                        <IconButton
                          aria-label="info"
                          sx={healthlistStyle.infoIconBtn}
                          onClick={(event) => handleOpen(e)}
                        >
                          <InfoOutlinedIcon sx={healthlistStyle.infoIcon} />
                        </IconButton>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      {Status[e.infotext]}
                      {Object.keys(progressData).length &&
                      progressData[e.id] &&
                      progressData[e.id].value !== 100
                        ? progressData[e.id].message
                        : e.infotext}
                    </TableCell>
                    <TableCell align="right">
                      {/* {loading && <CircularProgress />} */}

                      {e?.btnView && (
                        <Button
                          color="primary"
                          variant="outlined"
                          sx={healthlistStyle.btnsmView}
                          onClick={() => viewTestingReport(e)}
                          // disabled={running[e?.secondarytext]?true:false}
                          // onClick={onLoad}
                          disabled={disableView(e)}
                        >
                          {e?.btnView}
                        </Button>
                      )}

                      <LoadingButton
                        color="primary"
                        variant="outlined"
                        sx={healthlistStyle.btnsm}
                        onClick={() => {
                          if (e.btntext === "Run") {
                            runReport(e.secondarytext, e);
                          } else if (e?.btntext === "view") {
                            viewLogReport(e);
                          } else {
                            clickMoniterBtn(e);
                          }
                        }}
                        loading={isItLoading(e.secondarytext)}
                        // onClick={onLoad}
                      >
                        {e.btntext}
                        {/* {success ? "view" : loading ? "Runing" : "Run"} */}
                      </LoadingButton>
                      {!e?.HideMoreBtn && (
                        <span>
                          <ApplicationMenu application={e} />
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}

            {healthData?.length === 0 ? (
              <TableRow>
                {" "}
                {loading ? (
                  <Typography variant="subtitle2">{"Loading..."}</Typography>
                ) : (
                  <Typography variant="subtitle2">
                    {"No data Found ! Please Add Atleast One Application."}
                  </Typography>
                )}
              </TableRow>
            ) : (
              <></>
            )}
          </Table>
        </TableContainer>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box sx={healthlistStyle.modalBox}>
          <Box sx={healthlistStyle.titleSec}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 600 }}
              id="modal-modal-title"
            >
              {data?.secondarytext}
            </Typography>
            <IconButton
              sx={healthlistStyle.iconBtn}
              size="small"
              onClick={handleClose}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          </Box>
          <Divider />
          <Box
            sx={{
              padding: 3,
              height: "85% !important",
              overflow: "auto",
            }}
          >
            <Grid container spacing={2}>
              <Typography sx={{ fontSize: "14px", fontWeight: 600, p: 1 }}>
                {data?.tooltip}
              </Typography>
              <Grid item sm={4} md={3} xs={12}>
                <Typography fontWeight={600} sx={healthlistStyle.fadedTitle}>
                  Created On
                </Typography>
                <Typography fontWeight={600} sx={healthlistStyle.boldBody}>
                  {moment(data?.created_at).format("DD MMM, YY")}
                </Typography>
              </Grid>
              <Grid item sm={4} md={3} xs={12}>
                <Typography fontWeight={600} sx={healthlistStyle.fadedTitle}>
                  Updated On
                </Typography>
                <Typography fontWeight={600} sx={healthlistStyle.boldBody}>
                  {moment(data?.updated_at).format("DD MMM, YY")}
                </Typography>
              </Grid>

              {data?.desc?.length > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} sx={healthlistStyle.fadedTitle}>
                    {"Application Description"}
                  </Typography>
                  <Typography fontWeight={600} sx={healthlistStyle.boldBody}>
                    {data?.desc}
                  </Typography>
                </Grid>
              )}

              {data?.version?.length > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} sx={healthlistStyle.fadedTitle}>
                    Instance Version
                  </Typography>
                  <Typography fontWeight={600} sx={healthlistStyle.boldBody}>
                    {data?.version}
                  </Typography>
                </Grid>
              )}

              {data?.git_group?.length > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} sx={healthlistStyle.fadedTitle}>
                    Git Group
                  </Typography>
                  <Typography fontWeight={600} sx={healthlistStyle.boldBody}>
                    {data?.git_group}
                  </Typography>
                </Grid>
              )}

              {data?.git_repo?.length > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} sx={healthlistStyle.fadedTitle}>
                    Git Repo
                  </Typography>
                  <Typography fontWeight={600} sx={healthlistStyle.boldBody}>
                    {data?.git_repo}
                  </Typography>
                </Grid>
              )}

              {data?.git_branch?.length > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} sx={healthlistStyle.fadedTitle}>
                    Git Branch
                  </Typography>
                  <Typography fontWeight={600} sx={healthlistStyle.boldBody}>
                    {data?.git_branch}
                  </Typography>
                </Grid>
              )}

              {data?.app_run_command?.length > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} sx={healthlistStyle.fadedTitle}>
                    Run Command
                  </Typography>
                  <Typography fontWeight={600} sx={healthlistStyle.boldBody}>
                    {data?.app_run_command}
                  </Typography>
                </Grid>
              )}

              {data?.app_build_command?.length > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} sx={healthlistStyle.fadedTitle}>
                    Build Command
                  </Typography>
                  <Typography fontWeight={600} sx={healthlistStyle.boldBody}>
                    {data?.app_build_command}
                  </Typography>
                </Grid>
              )}

              {data?.build_dir?.length > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} sx={healthlistStyle.fadedTitle}>
                    Build Directory
                  </Typography>
                  <Typography fontWeight={600} sx={healthlistStyle.boldBody}>
                    {data?.build_dir}
                  </Typography>
                </Grid>
              )}

              {data?.app_port?.lenth > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} sx={healthlistStyle.fadedTitle}>
                    Port
                  </Typography>
                  <Typography fontWeight={600} sx={healthlistStyle.boldBody}>
                    {data?.app_port}
                  </Typography>
                </Grid>
              )}

              {data?.custom_domain?.length > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} sx={healthlistStyle.fadedTitle}>
                    Custom Domain
                  </Typography>
                  <Typography fontWeight={600} sx={healthlistStyle.boldBody}>
                    {data?.custom_domain}
                  </Typography>
                </Grid>
              )}

              {data?.platform?.length > 0 && (
                <Grid item sm={4} md={3} xs={12}>
                  <Typography fontWeight={600} sx={healthlistStyle.fadedTitle}>
                    Platform
                  </Typography>
                  <Typography fontWeight={600} sx={healthlistStyle.boldBody}>
                    {data?.platform}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
