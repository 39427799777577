import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { ViewValues } from '../../utils';

export const Views = [
    {
        id: 1,
        icon: <ViewListIcon sx={{ color: "#6e6e6e" }} />,
        value: ViewValues.list
    },
    {
        id: 2,
        icon: <ViewModuleIcon sx={{ color: "#6e6e6e" }} />,
        value: ViewValues.grid
    }
]