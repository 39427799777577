import React from 'react'
import { Grid, Skeleton, } from '@mui/material';
import {skeleton_style} from './style'

export const SkeletonLoader = ({spacing={},coloumns={},arrayNumber,loaderSx={},justifyContent={},width='',height='',skeletonLoaderSx={}}) => {
    const skeletonArray = Array(arrayNumber).fill('');
    return (
        <Grid container spacing={spacing} columns={coloumns}  justifyContent={justifyContent}
        paddingTop={{xs:'54px',md: "36px",lg: "0px"}} sx={{...loaderSx}}>
           { skeletonArray?.map((e,index)=>{
                        return(
                            <Grid item key={index} xs={2} sm={4} md={3} sx={{...skeletonLoaderSx}}>
                                <Skeleton animation="wave" variant="rounded"  sx={{...skeleton_style.load}} width={width} height={height} />
                            </Grid>
                        )
                    })
                }
        </Grid>
    )
}
