import * as React from 'react';
import { Box, Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

const styles = makeStyles((theme) => ({
    navigateText: {
        fontSize: "14px",
        textDecoration: "none",
        "&:hover": {
            color: theme.palette.primary.light
        },
    },
    Breadcrumbs:{
        display:"flex",
        flexDirection:"row",
        alignItems:"center"
    }
}));

function handleClick(event) {
    event.preventDefault();
}

export const BreadcrumbsComponent = ({ breadcrumbsLink = [] }) => {
    const classes = styles();
    const navigate = useNavigate();
    const moveBreadcrmbs=(value)=>{
        navigate(value.link)
    }
    return (
        <div role="presentation" onClick={handleClick}>
            <Breadcrumbs separator={">"} maxItems={3}>
                {
                    breadcrumbsLink?.map((value, i) => {
                        return (
                            <React.Fragment key={value.pathName}>
                                <Box className={classes.Breadcrumbs}>
                                <Link underline='none' variant='caption' className={classes.navigateText} aria-current="page" color={"textSecondary"} key={i}  href={value?.link}  onClick={()=>moveBreadcrmbs(value)}>
                                {value?.pathName }
                                </Link>
                                <Typography  sx={{ fontSize: 10 }} ml={.5} variant="subtitle2" color={"textSecondary"}>{`(${value.subText})`}</Typography>
                                </Box>
                              
                            </React.Fragment>
                        )
                    })
                }
            </Breadcrumbs>
        </div>
    );
}