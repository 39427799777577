export const  MeterIcon = (props) => (
<svg xmlns="http://www.w3.org/2000/svg"  width="33.328" height="14.93" viewBox="0 0 29.328 14.93">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_56073" data-name="Rectangle 56073" width="29.328" height="14.93" fill="none"/>
    </clipPath>
  </defs>
  <g id="Group_110355" data-name="Group 110355" transform="translate(0 0)">
    <g id="Group_110354" data-name="Group 110354" transform="translate(0 0)" clip-path="url(#clip-path)">
      <path id="Path_98389" data-name="Path 98389" d="M6.084,20.032A14.59,14.59,0,0,0,.459,31.56H6.813a8.272,8.272,0,0,1,3.823-6.976Z" transform="translate(-0.387 -16.895)" fill="#67aaf4" fill-rule="evenodd"/>
      <path id="Path_98390" data-name="Path 98390" d="M132.445,31.56a14.6,14.6,0,0,0-5.625-11.528l-4.557,4.553a8.261,8.261,0,0,1,3.824,6.976Z" transform="translate(-103.119 -16.895)" fill="#004a9b" fill-rule="evenodd"/>
      <path id="Path_98391" data-name="Path 98391" d="M56.6,2.959a14.65,14.65,0,0,0-17,0L44.208,7.57a8.292,8.292,0,0,1,7.779,0Z" transform="translate(-33.401 -0.204)" fill="#1985f9" fill-rule="evenodd"/>
      <path id="Path_98392" data-name="Path 98392" d="M5.7,20.032A14.759,14.759,0,0,0,.144,31.56l-.072-.072c1.585-.006,4.77,0,6.354,0l-.074.074a8.308,8.308,0,0,1,3.871-7.017l-.008.077Zm0,0,4.587,4.518.044.043A8.324,8.324,0,0,0,7.5,27.616a8.2,8.2,0,0,0-1,3.944v.074H6.426c-1.583,0-4.771,0-6.354,0H0V31.56A14.566,14.566,0,0,1,5.7,20.032" transform="translate(0 -16.895)" fill="#2e353a"/>
      <path id="Path_98393" data-name="Path 98393" d="M131.81,31.045a14.772,14.772,0,0,0-5.669-11.472l.095-.006c-1.132,1.14-3.418,3.421-4.556,4.554l-.013-.115a8.378,8.378,0,0,1,2.824,3.028,8.292,8.292,0,0,1,1.008,4.01L125.452,31Zm0,0-6.359.049H125.4a8.252,8.252,0,0,0-3.815-6.962l-.078-.05.065-.065c1.135-1.136,3.42-3.421,4.559-4.551l.045-.045.05.039a14.579,14.579,0,0,1,5.581,11.585" transform="translate(-102.484 -16.38)" fill="#2e353a"/>
      <path id="Path_98394" data-name="Path 98394" d="M55.985,2.755a14.734,14.734,0,0,0-16.954.06L39.04,2.7c1.543,1.529,3.066,3.079,4.6,4.618l-.077-.011a8.345,8.345,0,0,1,7.83.019l-.051.008c1.528-1.526,3.09-3.039,4.642-4.581m0,0c-1.529,1.539-3.05,3.11-4.6,4.665a8.236,8.236,0,0,0-7.8.027C42.034,5.9,40.475,4.364,38.936,2.807l-.062-.062.071-.05a14.684,14.684,0,0,1,17.04.06" transform="translate(-32.787 0)" fill="#2e353a"/>
      <path id="Path_98395" data-name="Path 98395" d="M92.163,55.7a1.063,1.063,0,0,0,1.011-1.112l-1.213-6.247-.912,6.352A1.064,1.064,0,0,0,92.163,55.7" transform="translate(-76.793 -40.772)" fill="#2e353a" fill-rule="evenodd"/>
      <path id="Path_98396" data-name="Path 98396" d="M94.16,69.411a19.305,19.305,0,0,1-.326,2.451,19.3,19.3,0,0,1,.326-2.451" transform="translate(-79.141 -58.543)" fill="#fff"/>
    </g>
  </g>
</svg>
)
