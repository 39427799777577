import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useParams } from "react-router-dom";
import { HealthList, TopNavBar } from "../../components";
import { DataTabs } from "../../components/dataTabs";
import { AppRoutes } from "../../router/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    backgroundImage: "url('/images/jpg/signInSignUpBg.png')",
    height: "auto",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  instancebg: {
    textAlign: "center",
    backgroundImage: "url('/images/instance.svg')",
    height: "215px",
    backgroundSize: "cover",
  },
  progressbar: {
    marginTop: "-100px",
  },
  container: {
    width: "100%",
    maxWidth: "75%",
  },
}));

export const Application = () => {
  const classes = useStyles();
  // Component States
  let {
    project_id,
    instance_id,
    application_id,
    linkText,
    linkTextTwo,
    linkTextThree,
  } = useParams();


  let params = new URLSearchParams(window.location.search);

  const searchParamsTab = params.get("tab");

  const [activeTab, setActiveTab] = React.useState(1);

  const tabChange = (value) => {
    window.history.replaceState(null, null, `?tab=${value}`);
    setActiveTab(value);
  };

  React.useEffect(() => {
    if (searchParamsTab) {
      setActiveTab(parseInt(searchParamsTab));
    }
  }, [searchParamsTab]);

  const tabs = [
    {
      label: (
        <Typography
          variant="body2"
          gutterBottom
          component="div"
          sx={{
            fontFamily: "crayond_semibold",
            fontSize: "14px",
            textTransform: "capitalize",
          }}
        >
          Testing Reports
        </Typography>
      ),
      value: 1,
      body: (
        <div>
          <HealthList
            healthData={[
              {
                linkText: linkText,
                linkTextTwo: linkTextTwo,
                linkTextThree: linkTextThree,
                secondarytext: "Performance Test Report",
                infotext: "",
                btntext: "Run",
                btnView: "View",
                project_id:project_id,
                instance_id:instance_id,
                application_id:application_id,
                view_route: `/Performance_Test_Report/${project_id}/${instance_id}/${application_id}`,
                HideMoreBtn: true,
                value: activeTab,
                tooltip:
                  "Ensures page is optimised for users to be able to see and interact with page content. Uses Google lighthouse",
              },
              {
                linkText: linkText,
                linkTextTwo: linkTextTwo,
                linkTextThree: linkTextThree,
                secondarytext: "SAST Report",
                infotext: "",
                btntext: "Run",
                btnView: "View",
                project_id:project_id,
                instance_id:instance_id,
                application_id:application_id,
                view_route: `/SAST_Report/${project_id}/${instance_id}/${application_id}`,
                HideMoreBtn: true,
                value: activeTab,
                tooltip:
                  "SAST(Static Application Security Testing) is a testing methodology used to secure software by reviewing source code. Uses SonarQube",
              },
              {
                linkText: linkText,
                linkTextTwo: linkTextTwo,
                linkTextThree: linkTextThree,
                secondarytext: "DAST Report",
                infotext: "",
                btntext: "Run",
                btnView: "View",
                project_id:project_id,
                instance_id:instance_id,
                application_id:application_id,
                view_route: `/DAST_Report/${project_id}/${instance_id}/${application_id}`,
                HideMoreBtn: true,
                value: activeTab,
                tooltip:
                  "DAST(Dynamic Application Security Testing) is process of analysing a web application through front-end to find vulnerabilities through simulated attacks.",
              },
              {
                linkText: linkText,
                linkTextTwo: linkTextTwo,
                linkTextThree: linkTextThree,
                secondarytext: "BDD Report",
                infotext: "",
                btntext: "Run",
                btnView: "View",
                project_id:project_id,
                instance_id:instance_id,
                application_id:application_id,
                view_route: `/BDD_Report/${project_id}/${instance_id}/${application_id}`,
                HideMoreBtn: true,
                value: activeTab,
                tooltip:
                  "Helps to test based on behaviour of the product and user acceptance criteria",
              },
            ]}
          />
        </div>
      ),
    },
    {
      label: (
        <Typography
          variant="body2"
          gutterBottom
          component="div"
          sx={{
            fontFamily: "crayond_semibold",
            fontSize: "14px",
            textTransform: "capitalize",
          }}
        >
          Logs
        </Typography>
      ),
      value: 2,
      body: (
        <div>
          <HealthList
            healthData={[
              {
                linkText: linkText,
                linkTextTwo: linkTextTwo,
                linkTextThree: linkTextThree,
                secondarytext: "Access Log",
                infotext: "",
                btntext: "view",
                route: `/applicationlog/accesslog/${project_id}/${instance_id}/${application_id}`,
                HideMoreBtn: true,
                value: activeTab,
                tooltip:
                  "Records all events related to client applications and user access to a resource on the application",
              },
              {
                linkText: linkText,
                linkTextTwo: linkTextTwo,
                linkTextThree: linkTextThree,
                secondarytext: "Error Log",
                infotext: "",
                btntext: "view",
                route: `/applicationlog/errorlog/${project_id}/${instance_id}/${application_id}`,
                HideMoreBtn: true,
                value: activeTab,
                tooltip:
                  "Contains detailed seconds of error conditions an application encounters while running",
              },
              {
                linkText: linkText,
                linkTextTwo: linkTextTwo,
                linkTextThree: linkTextThree,
                secondarytext: "Output Log",
                infotext: "",
                btntext: "view",
                route: `/applicationlog/outputlog/${project_id}/${instance_id}/${application_id}`,
                HideMoreBtn: true,
                value: activeTab,
                tooltip:
                  "Provides error occurring during the processing of an output request",
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <TopNavBar
        showActionBtn={false}
        showTopLeftNav={true}
        subTitle={activeTab === 1 ? "Testing Reports" : "Logs"}
        title={linkTextThree}
        breadcrumbsLink={[
          {
            pathName: linkText,
            link: AppRoutes.projectInstancesParent + project_id,
            subText: "Instance",
          },
          {
            pathName: linkTextTwo,
            link:
              AppRoutes.projectInstanceDetailParent +
              project_id +
              "/" +
              instance_id +
              "/" +
              linkText +
              "/" +
              linkTextTwo,
            subText: "Application",
          },
        ]}
      />
      <div className={classes.root}>
        <Box sx={{ paddingTop: "70px" }}>
          <DataTabs
            tabs={tabs}
            activeTab={activeTab}
            handleChangeTab={(val) => tabChange(val)}
          />
        </Box>
      </div>
    </>
  );
};
