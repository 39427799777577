 export const styles = {
  rootSx: {
    backgroundColor:"#fff",
    width:"298px",
    height:"77px",
    borderRadius:"8px",
    border:"1px solid #DFDFDF"
  },
  mainBlockSx: {
    height:"100%",
    padding:"12px 18px" ,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  relativeBoxSx: {
    position: 'relative',
  },
  hideProgressSx: {
    position: 'absolute', 
    opacity:"16%",
    borderRadius:"50%"
  },
  showProgressSx: {
    strokeLinecap: 'round',
  },
  progressValue: {
    position: 'absolute',
    top: '46%',
    left: '52%',
    transform:"translate(-50%, -50%)",
    fontWeight: 700,
    fontSize: '12px',
  // borderRadius:"50%",
  // zIndex:"2"

  },
  titleSx: {
    fontWeight: '700',
    fontSize: '18px',
    color: '#18283E',
  },
 
};