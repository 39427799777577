import React from "react";
import { styles } from "./styles";
import { Box, CircularProgress, Typography } from "@mui/material";
import { getColorFromProgress } from "../../utils";

const Progress = (props) => {
  const { progress, size, thickness, isBg = false, type ="NEGATIVE", symbol = false} = props;

//   const giveMeProgressProps = (progress = 0, end = 100) => {
//     let percentage = (progress / end) * 100;

//     if (percentage === 0) {
//       return { percentage, color: "#ccc" };
//     } else if (percentage >= 0 && percentage < 80) {
//       return { percentage, color: "#2FE678" };
//     } else if (percentage >= 80 && percentage <= 100) {
//       return { percentage, color: "#FF4141" };
//     } else {
//       return { percentage, color: "#2FE678" };
//     }
//   };

  const progressProps = getColorFromProgress(progress, 100, type);

  return (
    <Box sx={styles.relativeBoxSx}>
      <CircularProgress
        variant="determinate"
        sx={{
          ...styles.hideProgressSx,
          color: progressProps?.color,
          background:  isBg ? progressProps?.color : "",
        }}
        size={size}
        thickness={thickness}
        value={100}
      />
      <Box>
        <CircularProgress
          sx={{ ...styles.showProgressSx, color: progressProps?.color }}
          variant="determinate"
          value={progress}
          size={size}
          thickness={thickness}
        />
        {progressProps?.percentage ? 
        <Typography sx={styles.progressValue}>{progressProps?.percentage}{symbol ? "%": ""}</Typography>:
        <Typography sx={styles.progressValue}>0%</Typography>
      }
      </Box>
    </Box>
  );
};

export default Progress;


Progress.defaultProps = {
    progress: 0,
    isBg:false,
    sx: "",
    size: 42,
    thickness: 3,
  };
  