import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { StatCard } from "../../components/cards";
import Chart from "../../components/chartComponent";
import { CodeSmall } from "../../components/codeSmall";
import { DataTabs } from "../../components/dataTabs";
// import { Error } from "../../components/errorMessage";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { FilterIcon } from "../../assets/filterIcon";
import { Issues } from "../../components/issues";
import { TopNavBar } from "../../components/navBars/topNavbar";
import {useLazyGetAllSASTReportQuery, useLazySASTChartReportQuery,useLazySASTIssuesQuery, useLazySASTReportQuery,} from "../../redux/services";
import { AppRoutes } from "../../router/routes";
import { colorCodes, numberToEncodedLetter, numberToText } from "../../utils";
import { filteredData, filtereDublicatedData } from "./utils";
import moment from "moment";
import { ListView, SkeletonLoader, ToggleButton } from "../../components";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { useDispatch } from "react-redux";
import html2pdf from "html2pdf.js";

import {Sast_styles} from './style';

export const SASTReport = () => {
 // General Hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(1);
  const [isLoading, setisLoading] = useState(true);
  const [isError, setisError] =useState(false);
  const [currentDate, setCurrentDate] = useState('');
  const [open, setOpen] = useState(true);
  const [requestType, setRequestType] = useState("");
  const [allExpended, setAllExpended] = React.useState(false);
  const [getSastReport,setGetSastReport] = useState()
  // const [chatIssue,setChatIssue] =useState()
  const [chatReport,setChatReport]=useState()
  const [sastIssueData,setSastIssueData]=useState()
  const [filterSastType,setFilterSastType]=React.useState({
    type:'',
    serverity:''
  })
  const [typeFilter, setTypeFilter]=React.useState()
  const [reportData, setReportData] = useState({
    sastData:[],
    reportFilterData:[]
  });
 const [openFilter, setOpenFilter] = useState(false);
 const [rowsPerPage, setRowsPerPage] = React.useState(10);
 const [currentPage, setCurrentPage] = useState(1);


let { project_id,instance_id, application_id,   linkText,linkTextTwo,linkTextThree, linkTextFour} = useParams();

// REdux Hooks for API
const [SASTReport,...sastReportParams]=useLazySASTReportQuery()
const [SASTIssues]=useLazySASTIssuesQuery()
const [GetAllSASTReport] =useLazyGetAllSASTReportQuery();
// const [SASTISSUESType]=useLazySASTIssuesTypesQuery()
const [SASTChartReport,...SastChartReport]=useLazySASTChartReportQuery()

// get AllCurrent Sast report
const getAllCurrentSastReport = async(FilterDate)=>{
 let getSastReport = await SASTReport ({application_id: application_id,since:FilterDate })
 setGetSastReport(getSastReport?.data)
 setisError(sastReportParams[0]?.isError)
 setisLoading(sastReportParams[0]?.isLoading)
}

// const getSastIssueType =async(FilterDate)=>{
//   let IssueData = await SASTISSUESType({application_id: application_id,type: requestType,since:FilterDate })
//   setChatIssue(IssueData?.data)
// }


// chart Report
const chartReport =async(FilterDate)=>{
let ChartReport = await SASTChartReport({
  application_id: application_id,from_date:currentDate ? FilterDate : moment().format('YYYY-MM-DD'),})
  setChatReport(ChartReport?.data)
  setisError(SastChartReport[0]?.isError)
  setisLoading(SastChartReport[0]?.isLoading)
}

// Get Sast Issue
const getSastIssue =async(FilterDate)=>{
  let SastIssue = await SASTIssues({application_id: application_id,type: requestType,since:FilterDate})
  setSastIssueData(SastIssue?.data)
  setTypeFilter(SastIssue?.data)
}

// BackdropLoader
const showBackdrop = () => {
  dispatch(openBackdrop( reportData ? "Collecting Sast Data.....  ": " Downloading Sast Data....."));
};

const hideBackdrop = () => {
  dispatch(closeBackdrop());
};

const handleClick = () => {
    setOpen(false);
};

const response = filteredData.map((_item) => {
    const m = getSastReport?.data?.component?.measures?.find(
      (c) => c.metric === _item?.metric_key
    );
    const value = getSastReport?.data?.component?.measures?.find(
      (c) => c.metric === _item?.card_key
    );
    return {
      ...getSastReport?.data?.metrics?.find((c) => c.key === _item?.key),

      metricData: m ? numberToEncodedLetter(parseInt(m?.value)) : "",
      BackgroundColor: m
        ? colorCodes[numberToEncodedLetter(parseInt(m?.value))]?.BackgroundColor
        : "",
      Color: m
        ? colorCodes[numberToEncodedLetter(parseInt(m?.value))]?.Color
        : "",
      [_item?.s_key]: getSastReport?.data?.metrics?.find((c) => c.key === _item?.s_key),
      cardValue: value?.value,
      icon: _item.icon,
      color: _item?.color,
      toolTip: _item?.toolTip,
    };
});
  
const duplicatedResponce = filtereDublicatedData.map((item) => {
    const duplicate_value = getSastReport?.data?.component?.measures?.find(
      (child) => child.metric === item?.value
    );
    const duplicate_prs = getSastReport?.data?.component?.measures?.find(
      (child) => child.metric === item?.prs
    );
    const duplicate_ncloc = getSastReport?.data?.component?.measures?.find(
      (child) => child.metric === item?.ncloc
    );

    return {
      ...getSastReport?.data?.metrics?.find((child) => child.key === item?.d_key),
      duplicateValue: duplicate_value?.value,
      duplicatePrs: duplicate_prs?.value,
      duplicateNcloc: duplicate_ncloc
        ? [numberToText(parseInt(duplicate_ncloc?.value))]
        : "",
      color: item?.color,
    };
});

// onChange Type
const onFilterChange =async(item)=>{
      let SastIssue = await SASTIssues({application_id: application_id,type:item?.val,serverty: ''})
       setSastIssueData(SastIssue?.data)
       setTypeFilter(SastIssue?.data) 
       setFilterSastType({
        ...filterSastType,
         type: item?.val
       })

}

// on SeverityChange Type
const onSeverityChange = async(data)=>{
  let SastIssue = await SASTIssues({application_id: application_id,type:filterSastType?.type,serverty: data})
  setTypeFilter(SastIssue?.data)
  setFilterSastType({
    ...filterSastType,
     serverity: data
   })
}

// pagination Start and End !
const end =  rowsPerPage
const startPage =  typeFilter?.data?.p
const lenght = typeFilter?.data?.total
const count = Math.ceil(lenght);

const onEventDialogChange=(value,key)=>{
 
}

// onPageChange
const onPageFun = async (e,start) => {
  setCurrentPage(start)
};

 // onChange Row for pagination
const onChangeRow = async (event, key) => {
  if (key === 'page') {
    let currentpages = event.target.value;
    if ( currentpages !== "0") {
      setCurrentPage(currentpages);
    }
  }

  if (key === 'increase') {
    const parsedValue = parseInt(currentPage, 10) + 1;
    setCurrentPage(parsedValue);
  }

  if (key === 'decrease') {
    if (currentPage > 1) {
    const parsedValue = parseInt(currentPage) - 1;
      setCurrentPage(parsedValue);
    }
  }

};

 //changes per Row
const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value));
  setCurrentPage(1)
};

 // when enter pagination settime out the function will call
React.useEffect(() => {
  if (currentPage) {
    const setData = setTimeout(async () => {
      let SastIssue = await SASTIssues({application_id: application_id,type:filterSastType?.type,serverty: filterSastType?.serverity,start: currentPage,end:end})
      setTypeFilter(SastIssue?.data)
      setSastIssueData(SastIssue?.data)
    }, 800);

    return () => clearTimeout(setData);
  }

  // eslint-disable-next-line
}, [currentPage]);

// Tab Contents
const tabs = [
    {
      label: (
        <Typography
          variant="body2"
          gutterBottom
          component="div"
          sx={{
            fontFamily: "crayond_semibold",
            fontSize: "14px",
            textTransform: "capitalize",
          }}
        >
          Overview
        </Typography>
      ),
      value: 1,
      body: ( 
          <Box sx={Sast_styles.outSX}>
                    <Grid container spacing={1.8} sx={{paddingTop: location?.state?.reportFilter ? '28px' : '0px'}}>
                    {isLoading === true  && isError === false &&(
                                  <SkeletonLoader spacing={{ xs: 2, md: 1, lg: 1 }}  coloumns={{ xs: 1, sm: 8, md: 12 }} loaderSx={Sast_styles.loaderSx} arrayNumber={4} skeletonLoaderSx ={{ width: '100%'}} height="127px"/>
                                    ) }
                    { isLoading === false && isError === false && response.map((val, i) => (
                       <Grid item xs={12} sm={6} md={6} lg={3}>
                       
                         <StatCard {...val} />
                    </Grid>  
                    ))}
                 <Grid item xs={12} mt={1}>
                    <Chart
                      isLoading={isLoading}
                      isError={isError}
                      chartData={chatReport}
                      duplicatedResponce={duplicatedResponce}
                    />
              </Grid>
            </Grid>
          </Box>
      ),
    },
    {
      label: (
        <Typography
          variant="body2"
          gutterBottom
          component="div"
          sx={{
            fontFamily: "crayond_semibold",
            fontSize: "14px",
            textTransform: "capitalize",
          }}
        >
          Issues
        </Typography>
      ),
      value: 2,
      body: (
        <div>
          <Box sx={{paddingTop: location?.state?.reportFilter ? '28px' : '0px'}}>
            {open ? (
              <Issues
                handleClick={handleClick}
                setRequestType={setRequestType}
                issuesData={sastIssueData}
                // issuesTypeData={chatIssue}
                allExpended={allExpended}
                onEventDialogChange={onEventDialogChange}
                onFilterChange={onFilterChange}
                onSeverityChange={onSeverityChange}
                filterSastData={filterSastType}
                typeFilter={typeFilter}
                onPageFun={onPageFun}
                currentPage={currentPage}
                count={count}
                rowsPerPage={rowsPerPage}
                startPage={startPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                onChangeRow={onChangeRow}
              />
            ) : (
              <CodeSmall setOpen={setOpen} />
            )}
          </Box>
        </div>
      ),
    },
];

// Options for filter date 
const options = [
    {
      name: "7D",
      value: "7",
    },
    {
      name: "30D",
      value: "30",
    },
    {
      name: "3M",
      value: "90",
    },
    {
      name: "6M",
      value: "180",
    },
];

const onStatusChange = (daysAgo) => {
    const currentDate = moment().format('YYYY-MM-DD');
    const startDate = moment().subtract(daysAgo, "days");
    const filteredData = reportData?.sastData?.filter((item) => {
      const itemDate = moment(item.date, "YYYY-MM-DD");
      return itemDate.isBetween(startDate, currentDate, null, '[]');
    });
    // setReportData(filteredData);
    setReportData({
      ...reportData,
      reportFilterData:filteredData
    })
};

// Get all sast Report
const getAllSastReport = async (date) => {
    let allSastReport = await GetAllSASTReport({
      application_id: application_id,
    });
    const currentDate = moment().format('YYYY-MM-DD');
    const startDate = moment().subtract(7, "days");
    const filteredData = allSastReport?.data?.data?.analyses.filter((item) => {
      const itemDate = moment(item.date, "YYYY-MM-DD");
      return itemDate.isBetween(startDate, currentDate, null, '[]');
    });
    setReportData({
      ...reportData,
      sastData:allSastReport?.data?.data?.analyses,
      reportFilterData:filteredData
    })

    
    setOpenFilter(false);
};

// on Performance Click
const onPerformanceClick = (value) => {
    let FilterDate = moment(value?.date).format("YYYY-MM-DD")
    setCurrentDate(FilterDate)
    // getSastIssueType(FilterDate)
    getSastIssue(FilterDate)
    chartReport(FilterDate);
    getAllCurrentSastReport(FilterDate);
    setOpenFilter(true)
};

const OnCloseClick = () => {
    setOpenFilter(false);
    setCurrentDate(7);
    getAllSastReport(moment().subtract(7, "days").format("YYYY-MM-DD"));
};

// initial Load Data
const initialData = () => {
    // await SASTReport();
    // await getSastIssue();
    // await getSastIssueType();
    // await chartReport();
    // getSastIssueType()
    getSastIssue()
    chartReport();
    getAllCurrentSastReport();
    setOpenFilter(true);
};

React.useEffect(() => {
    if (location?.state?.reportFilter) {
      setCurrentDate(7)
      getAllSastReport(moment().subtract(7, "days").format("YYYY-MM-DD"));
    } else {
      initialData();
    }
    // eslint-disable-next-line
}, []);

// React.useEffect(() => {
//     getSastIssue()
//     // eslint-disable-next-line
// }, [requestType]);

  // const giveMeTopNavbarPrimaryText = () => {
  //   return "SAST Report";
  //};

 // convert html to pdf packages
function convertHtmlToPdf() {
    const element = document.getElementById('screen');
    const options = {
      margin: 1,
      filename: 'Sast_Test_Reports.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 1.5,scrollX: 0,
        scrollY: -window.scrollY,},
      jsPDF: { unit: 'mm', format: 'a2', orientation: 'portrait',width: 1020 },
      pagebreak: { mode: 'avoid-all' }
    };
    setTimeout(()=>{
    html2pdf().set(options).from(element).save();
     setAllExpended(false)
     hideBackdrop()
    //  setisLoadingPdf(false)
     // eslint-disable-next-line
  },700)

}

// onClick Download
const onClickDownload = async (element) => {
    // setisLoadingPdf(true)
    setAllExpended(true)
    showBackdrop()
    convertHtmlToPdf()
};

return (
    <> 
      <TopNavBar  onDownload={onClickDownload} isDownloadRefreshRequired={openFilter}  showActionBtn={false} showTopLeftNav={true} subTitle={activeTab === 1 ? "Overview" : " Issues"} title={linkTextFour}
        breadcrumbsLink={[
          {
            pathName: linkText,
            link: AppRoutes.projectInstancesParent + project_id,
            subText: "Instance",
          },
          {
            pathName: linkTextTwo,
            link:
              AppRoutes.projectInstanceDetailParent +
              project_id +
              "/" +
              instance_id +
              "/" +
              linkText +
              "/" +
              linkTextTwo,
            subText: "Application",
          },
          {
            pathName: linkTextThree,
            link:
              AppRoutes.applications +
              project_id +
              "/" +
              instance_id +
              "/" +
              application_id +
              "/" +
              linkText +
              "/" +
              linkTextTwo +
              "/" +
              linkTextThree,
            subText: "Testing Report",
          },
        ]}
      />
      <Box sx={Sast_styles.root}>
        {location?.state?.reportFilter && !openFilter && (
        <Box sx={{paddingTop:'72px'}}>

        <Box sx={Sast_styles.filterSx}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontSize: "14px", fontWeight: "600", mr: "8px" }}>
            Filter
          </Typography>
          <FilterIcon />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ToggleButton
            options={options}
            disable={reportData?.reportFilterData?.length > 0 ? false : true}
            value={JSON.stringify(currentDate)}
            onChange={onStatusChange}
          />
        </Box>
      </Box>
      </Box>
        )}

      {/* //Back */}
      {location?.state?.reportFilter && openFilter && (  
        <Box sx={{position:'relative'}}>
            <Box sx={{ display: "flex",alignItems: "center", position:'absolute',cursor: "pointer",  top:'107px',
                                  left:'27px'}} onClick={OnCloseClick}>
                            <ArrowBackIosNewIcon sx={{ color: "#0063e7", fontSize: "14px", marginRight: "5px" }} fontSize="small" />
                            <Typography sx={{ color: "#0063e7", fontSize: "14px", fontWeight: "600" }} size="small"> Back To SAST Report List </Typography>
              </Box> 
        </Box>
      )}
      <Box sx={{ paddingTop: "12px" }}>
        {" "}
        {!openFilter &&
          reportData?.reportFilterData?.length > 0 &&
          reportData?.reportFilterData?.map((val, index) => (
            <Grid
              sx={{...Sast_styles.listi, padding: "12px 26px" }}
              key={index}
            >
              <ListView
                data={val || {}}
                titleSx={{ padding: "5px 5px" }}
                images="/images/homeScreenLogo.png"
                title={moment(val?.date).format("MMMM Do YYYY, h:mm a")}
                btnName={"View Performance"}
                btnVarient="outlined"
                onBtnClick={() => onPerformanceClick(val, index)}
              />
            </Grid>
          ))}
      </Box>
      {openFilter && (
        <Box sx={Sast_styles.root} id="screen">
          <DataTabs
            tabs={tabs}
            activeTab={activeTab}
            handleChangeTab={(val) => setActiveTab(val)}
          />
        </Box>
      )}
      {((location?.state?.reportFilter && reportData?.reportFilterData?.length === 0)) && 
          <div style={{ display: "flex",alignItems: "center",justifyContent: "center",height: "75%",}}>
            <Box sx={Sast_styles.emptyState}>
              <img src="/images/emptyState.svg" alt="emptyState" />
              <Typography sx={Sast_styles.emptyTitle}>
                There is no Sast report to show.
              </Typography>
            </Box>
          </div>
          }
      </Box>
    </>
  );
};
