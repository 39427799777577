export const  SecurityIcon = (props) => (
<svg xmlns="http://www.w3.org/2000/svg" id="Group_110379" data-name="Group 110379" width="20.142" height="20.614" viewBox="0 0 20.142 25.614">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_56070" data-name="Rectangle 56070" width="20.142" height="25.614" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-3">
      <rect id="Rectangle_56068" data-name="Rectangle 56068" width="20.07" height="25.544" fill="none"/>
    </clipPath>
  </defs>
  <g id="Group_110348" data-name="Group 110348" clip-path="url(#clip-path)">
    <g id="Group_110347" data-name="Group 110347">
      <g id="Group_110346" data-name="Group 110346" clip-path="url(#clip-path)">
        <path id="Path_98352" data-name="Path 98352" d="M18.4,3.169,10.223.234l-8.093,2.9C1.1,3.617.19,3.91.19,4.872V9.664A18.112,18.112,0,0,0,2.856,19.2,16.6,16.6,0,0,0,10.3,25.778c3.308-1.075,5.778-3.939,7.287-6.389a18.111,18.111,0,0,0,2.666-9.538V5.542A2.261,2.261,0,0,0,18.4,3.169" transform="translate(-0.163 -0.2)" fill="#3183ef"/>
        <g id="Group_110345" data-name="Group 110345" transform="translate(0.027 0.034)" opacity="0.3">
          <g id="Group_110344" data-name="Group 110344">
            <g id="Group_110343" data-name="Group 110343" clip-path="url(#clip-path-3)">
              <path id="Path_98353" data-name="Path 98353" d="M10.3,23a13.2,13.2,0,0,1-5.891-5.246A14.328,14.328,0,0,1,2.3,10.206V6.844a2.12,2.12,0,0,1,1.372-1.9l6.551-2.449V.234l-8.093,2.9C1.28,3.5.19,3.91.19,4.872V9.664A18.112,18.112,0,0,0,2.856,19.2,16.593,16.593,0,0,0,10.3,25.778Z" transform="translate(-0.19 -0.234)"/>
              <path id="Path_98354" data-name="Path 98354" d="M69.966,25.778a9.863,9.863,0,0,0,1.606-.688,14.409,14.409,0,0,0,3.4-2.673,18.312,18.312,0,0,0,2.363-3.175A17.434,17.434,0,0,0,79.92,9.612l.005-3.956c-.005-1.97-1.016-2.115-1.865-2.487L69.887.234v2.26l6.627,2.489a2.12,2.12,0,0,1,1.372,1.9v3.362a14.329,14.329,0,0,1-2.109,7.546A12.9,12.9,0,0,1,69.966,23Z" transform="translate(-59.854 -0.234)"/>
            </g>
          </g>
        </g>
        <path id="Path_98355" data-name="Path 98355" d="M20.131,9.412,20.126,5.4a3.628,3.628,0,0,0-.135-.988,2.078,2.078,0,0,0-1.4-1.317C18.433,3.051,10.474.207,10.109.064L5.842,1.6c-.7.253-2.524.907-3.2,1.152-.3.109-.763.271-1.053.413C.958,3.46.122,3.832.121,4.637l0,2.266,0,2.268C-.022,15.961,3.883,22.8,10.194,25.541a13.347,13.347,0,0,0,5.927-4.455,18.088,18.088,0,0,0,4.01-11.674m0,0a17.615,17.615,0,0,1-2.89,10.151,17.836,17.836,0,0,1-1.894,2.518,12.234,12.234,0,0,1-5.185,3.533A17.417,17.417,0,0,1,.1,11.443C-.064,10.13.027,8.239.007,6.9L.013,4.634c0-.847.872-1.261,1.536-1.56a10.3,10.3,0,0,1,1.059-.411l3.206-1.14L10.1,0c.54.19,8.225,2.981,8.507,3.067A2.1,2.1,0,0,1,20.01,4.4a3.643,3.643,0,0,1,.132.994Z" transform="translate(0)" fill="#2e353a"/>
        <path id="Path_98356" data-name="Path 98356" d="M35.048,17.368c1.642-.666,3.3-1.3,4.954-1.925.026-.011.021-.01.048,0,2.179.811,4.351,1.642,6.506,2.517-2.2-.76-4.38-1.565-6.554-2.389-1.407.575-3.355,1.232-4.954,1.8" transform="translate(-30.003 -13.213)" fill="#2e353a"/>
        <path id="Path_98357" data-name="Path 98357" d="M19.605,80.533a16.324,16.324,0,0,0,4.333,6.337,10.673,10.673,0,0,0,2.971,1.837h-.05c4.561-2.046,7.407-6.757,7.908-11.639-.378,4.926-3.256,9.73-7.859,11.766-3.526-1.348-6.032-4.846-7.3-8.3" transform="translate(-16.783 -65.974)" fill="#2e353a"/>
        <path id="Path_98358" data-name="Path 98358" d="M96.32,50.925a5.663,5.663,0,0,1-1.543,3.66c-.024.026-.048.051-.074.075a.4.4,0,0,1-.592.006.392.392,0,0,1-.017-.584,5.037,5.037,0,0,0,.884-1.272,4.958,4.958,0,0,0-.616-5.3c-.089-.112-.189-.214-.282-.323a.391.391,0,0,1-.066-.453.455.455,0,0,1,.449-.243.47.47,0,0,1,.276.172,5.737,5.737,0,0,1,1.1,1.662,6.154,6.154,0,0,1,.483,2.6" transform="translate(-80.444 -39.797)" fill="#fff"/>
        <path id="Path_98359" data-name="Path 98359" d="M35.827,46.935a.42.42,0,0,1-.147.268,4.73,4.73,0,0,0-.821,1.169,4.947,4.947,0,0,0,.788,5.6.5.5,0,0,1,.183.38.434.434,0,0,1-.27.368.326.326,0,0,1-.391-.047,5.032,5.032,0,0,1-.459-.5,5.585,5.585,0,0,1-1.11-2.414,5.722,5.722,0,0,1,1.294-4.944,3.224,3.224,0,0,1,.225-.244.364.364,0,0,1,.427-.084.446.446,0,0,1,.282.445" transform="translate(-28.664 -39.768)" fill="#fff"/>
        <path id="Path_98360" data-name="Path 98360" d="M46.36,54.383a.427.427,0,0,1-.138.317,3.1,3.1,0,0,0-.655.983,3.332,3.332,0,0,0,.481,3.493c.062.081.136.152.2.228a.4.4,0,0,1-.025.585.4.4,0,0,1-.585-.008,4.24,4.24,0,0,1-.661-.887,4.2,4.2,0,0,1,.428-4.744c.072-.088.147-.173.225-.256a.39.39,0,0,1,.454-.1.444.444,0,0,1,.273.388" transform="translate(-38.04 -46.194)" fill="#fff"/>
        <path id="Path_98361" data-name="Path 98361" d="M87.039,57.131a3.448,3.448,0,0,0-.928-2.383.443.443,0,0,1-.149-.431.423.423,0,0,1,.728-.188A4.234,4.234,0,0,1,86.679,60a.389.389,0,0,1-.523.062.415.415,0,0,1-.168-.518.855.855,0,0,1,.146-.194,3.285,3.285,0,0,0,.822-1.594c.048-.233.063-.472.083-.624" transform="translate(-73.577 -46.214)" fill="#fff"/>
        <path id="Path_98362" data-name="Path 98362" d="M79.4,63.588a2.546,2.546,0,0,1-.768,1.726.413.413,0,0,1-.664-.075.4.4,0,0,1,.013-.451c.035-.046.076-.088.115-.131a1.786,1.786,0,0,0,.014-2.383.6.6,0,0,1-.207-.335.426.426,0,0,1,.232-.425.369.369,0,0,1,.452.049,2.5,2.5,0,0,1,.669,1.039,2.86,2.86,0,0,1,.144.986" transform="translate(-66.688 -52.612)" fill="#fff"/>
        <path id="Path_98363" data-name="Path 98363" d="M55.441,63.432a2.6,2.6,0,0,1,.781-1.84.4.4,0,0,1,.584,0,.4.4,0,0,1,.008.591,2.076,2.076,0,0,0-.35.488,1.788,1.788,0,0,0,.257,1.953l.11.124a.419.419,0,0,1-.573.606,2.512,2.512,0,0,1-.583-.82,2.576,2.576,0,0,1-.234-1.1" transform="translate(-47.461 -52.615)" fill="#fff"/>
        <path id="Path_98364" data-name="Path 98364" d="M67.455,69.935a.916.916,0,0,1-.956.894.928.928,0,0,1-.86-.93.908.908,0,0,1,1.816.036" transform="translate(-56.19 -59.076)" fill="#fff"/>
        <path id="Path_98365" data-name="Path 98365" d="M69.5,92.708h-.2a.33.33,0,0,1-.33-.33V89.513a.33.33,0,0,1,.33-.33h.2a.33.33,0,0,1,.33.33v2.864a.33.33,0,0,1-.33.33" transform="translate(-59.042 -76.345)" fill="#fff"/>
        <path id="Path_98366" data-name="Path 98366" d="M96.2,50.81a5.687,5.687,0,0,1-1.622,3.76.445.445,0,0,1-.734-.231c-.062-.219.115-.389.247-.531a5,5,0,0,0-.179-6.761.445.445,0,0,1,.347-.69.38.38,0,0,1,.288.1,5.67,5.67,0,0,1,1.28,2.006A6.428,6.428,0,0,1,96.2,50.81m0,0a6.423,6.423,0,0,0-.389-2.343,5.652,5.652,0,0,0-1.286-1.985.383.383,0,0,0-.518.028.375.375,0,0,0-.058.511,4.98,4.98,0,0,1,.367,6.585c-.146.235-.5.414-.434.725a.441.441,0,0,0,.418.33c.3-.033.46-.357.651-.56A5.582,5.582,0,0,0,96.2,50.81" transform="translate(-80.322 -39.682)" fill="#2e353a"/>
        <path id="Path_98367" data-name="Path 98367" d="M35.706,46.923c-.018.205-.225.324-.336.48a4.953,4.953,0,0,0-1.1,3.9,4.845,4.845,0,0,0,.895,2.18q.177.239.378.459a.467.467,0,0,1-.385.831c-.1-.02-.178-.13-.246-.191s-.14-.144-.206-.219a5.8,5.8,0,0,1,.172-7.681.924.924,0,0,1,.22-.2.442.442,0,0,1,.606.441m0,0a.438.438,0,0,0-.6-.435,4.771,4.771,0,0,0-.923,1.162,5.777,5.777,0,0,0,.582,6.667c.065.075.133.147.2.218.054.047.14.16.217.176a.427.427,0,0,0,.459-.226.421.421,0,0,0-.138-.5q-.2-.224-.378-.466a4.889,4.889,0,0,1-.887-2.206A4.972,4.972,0,0,1,35.366,47.4c.113-.157.32-.272.339-.478" transform="translate(-28.542 -39.755)" fill="#2e353a"/>
        <path id="Path_98368" data-name="Path 98368" d="M46.228,54.365a.525.525,0,0,1-.2.387,3.347,3.347,0,0,0-.28.351,3.451,3.451,0,0,0-.52,2.567,3.559,3.559,0,0,0,.8,1.575c.265.235.339.573.02.8s-.584-.052-.784-.3a4.238,4.238,0,0,1-.925-3.385,4.165,4.165,0,0,1,1.233-2.353.434.434,0,0,1,.655.358m0,0a.43.43,0,0,0-.65-.35,4.17,4.17,0,0,0,.046,6,.394.394,0,0,0,.508-.564,3.756,3.756,0,0,1-.941-1.771,3.472,3.472,0,0,1,.548-2.58,3.365,3.365,0,0,1,.284-.349.523.523,0,0,0,.2-.384" transform="translate(-37.908 -46.175)" fill="#2e353a"/>
        <path id="Path_98369" data-name="Path 98369" d="M86.95,57.023A3.684,3.684,0,0,0,86.5,55.3a3.742,3.742,0,0,0-.539-.714.5.5,0,0,1-.107-.2.454.454,0,0,1,.356-.521c.334-.066.523.285.712.5a4.141,4.141,0,0,1-.055,5.23c-.148.164-.271.4-.5.444a.45.45,0,0,1-.48-.6c.163-.256.4-.447.546-.713a3.61,3.61,0,0,0,.519-1.7m0,0a3.62,3.62,0,0,1-.5,1.708c-.143.264-.383.468-.537.715a.419.419,0,0,0,.449.557c.221-.047.335-.278.477-.435a4.216,4.216,0,0,0,.845-1.8,4.326,4.326,0,0,0-.792-3.375c-.179-.2-.354-.548-.67-.49a.422.422,0,0,0-.335.48.472.472,0,0,0,.1.192,3.76,3.76,0,0,1,.534.722,3.694,3.694,0,0,1,.433,1.728" transform="translate(-73.489 -46.105)" fill="#2e353a"/>
        <path id="Path_98370" data-name="Path 98370" d="M79.24,63.476a2.569,2.569,0,0,1-.671,1.639.766.766,0,0,1-.229.2.447.447,0,0,1-.559-.627,1.9,1.9,0,0,0,.576-1.474A1.763,1.763,0,0,0,77.9,62.15a.467.467,0,0,1-.133-.571.456.456,0,0,1,.536-.228,2.011,2.011,0,0,1,.726.949,2.8,2.8,0,0,1,.213,1.176m0,0a2.462,2.462,0,0,0-.952-2.091.413.413,0,0,0-.508.49.6.6,0,0,0,.162.233,1.811,1.811,0,0,1,.169,2.261c-.1.172-.3.278-.338.477a.411.411,0,0,0,.553.442,2.394,2.394,0,0,0,.913-1.812" transform="translate(-66.527 -52.5)" fill="#2e353a"/>
        <path id="Path_98371" data-name="Path 98371" d="M55.44,63.325a2.659,2.659,0,0,1,.606-1.667c.128-.15.274-.341.5-.318a.451.451,0,0,1,.331.708,1.826,1.826,0,0,0,.036,2.655.453.453,0,0,1-.421.661c-.218-.011-.345-.212-.475-.36a2.678,2.678,0,0,1-.573-1.679m0,0a2.6,2.6,0,0,0,.59,1.665c.127.137.256.332.459.338a.406.406,0,0,0,.371-.6,1.848,1.848,0,0,1-.033-2.717.4.4,0,0,0-.289-.639c-.209-.026-.35.157-.476.3a2.588,2.588,0,0,0-.623,1.652" transform="translate(-47.459 -52.509)" fill="#2e353a"/>
        <path id="Path_98372" data-name="Path 98372" d="M67.261,69.831a.924.924,0,1,1-.891-.942.927.927,0,0,1,.891.942m0,0a.889.889,0,1,0-.924.875.9.9,0,0,0,.924-.875" transform="translate(-55.996 -58.972)" fill="#2e353a"/>
        <path id="Path_98373" data-name="Path 98373" d="M69.3,92.469c-.285.026-.545-.044-.531-.383v-.256L68.756,90.8c0-.338-.016-1.21-.015-1.537a.366.366,0,0,1,.363-.359c.3-.026.576.063.559.419v.256c-.006.511-.019,1.538-.025,2.049l0,.512a.333.333,0,0,1-.332.324m0,0a.33.33,0,0,0,.327-.324l0-.512c-.007-.511-.019-1.538-.026-2.049v-.256c.014-.321-.229-.369-.495-.347a.3.3,0,0,0-.3.289c0,.323-.011,1.2-.016,1.536l-.013,1.024v.256c-.018.335.241.407.523.383" transform="translate(-58.846 -76.107)" fill="#2e353a"/>
      </g>
    </g>
  </g>
</svg>
)
