export const toggleButton_style = {
  activeSx: {
    opacity: 1,
    color: "border.focus",
    fontSize: "12px",
  },
  nameActiveSx: {
    fontWeight: 600,
    color: "#fff",
  },
  nameInactiveSx: {
    // mr: '0.5px',
    fontWeight: 500,
    color: "black",
  },
  radioSx: {
    color: "text.secondary",
    fontSize: "12px",
  },
  buttonactiveSx: {
    color: "#FFF",
    fontWeight: "600",
    padding: "8px",
    borderRadius:'2px',
    minWidth: "44px",
  },
  buttoninactiveSx: {
    backgroundColor: "#fff",
    borderRight: "1px solid #E0E0E0",
    borderRadius: "0px",
    color: "#FFF",
    // boxShadow: '5px 10px',
    //   borderColor: '',
    minWidth: "44px",
    fontSize: "12px",
    padding: "8px",
    '&:hover':{
      backgroundColor:"#fff"
    }
    // "&:last-child": {
    //   borderRight: "0px solid #E0E0E0",
    // },
  },
};
