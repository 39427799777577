import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import {AddProjectDrawer,AutocompleteCmp,ProgressWidgetVertical,SkeletonLoader,TopNavBar,} from "../../components";
import { dashboard_styles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { openDrawer } from "../../redux/slices/drawer";
import { FilterIcon } from "../../assets/filterIcon";
import ReportCard from "../../components/reportCard";
import { BugIconBlue, VulnerabilitiesIcon } from "../../assets";
import Progress from "../../components/circularProgressWidgetVertical/progress";
import { getColorFromProgress } from "../../utils";
import {
  // useCurrentUsageQuery,
  useGetAllProjectsQuery,
  useLazyCurrentUsageQuery,
  useLazyDASTReportQuery,
  useLazyGetAllApplicationsByIdQuery,
  // useLazyGetAllSASTReportQuery,
  useLazyPerformanceTestReportQuery,
  useLazySASTReportQuery,
} from "../../redux/services";
import { DataTabs } from "../../components/dataTabs";
import SocSecurity from "./socSecurity";
import { refreshProjectListing } from "../../redux/slices/utils";
import { useNavigate } from "react-router-dom";
import { CodeSmellsIcon } from "../../assets/codeSmellsIcon";
import { SecurityIcon } from "../../assets/securityIcon";
import { MeterIcon } from "../../assets/meterIcon";
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { AppRoutes } from "../../router/routes";
// import { AppRoutes } from "../../router/routes";

export const Dashboard = () => {
  // General Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const canIRefresh = useSelector((state) => state.utils.refreshProjectListing);
  // eslint-disable-next-line
  const [state, setState] = React.useState({ data: "", start: 0, length: 200 });
  const [selectOption, setSelectOption] = React.useState([]);
  const [applicationSelectOption, setApplicationSelectOption] = React.useState(
    []
  ); 
  const [selectedProject, setSelectedProject] = React.useState({
    label: "",
    value: "",
    id: "",
  });
  const [selectedApplication, setSelectedApplication] = React.useState({
    name: "",
    label: "",
    id: "",
    server_id: "",
    server_name: "",
  });
  const [activeTab, setActiveTab] = React.useState(1);

  // Redux Hooks
  const {isLoading,isError, data,refetch: getAllProjects,} = useGetAllProjectsQuery({ start: state.start, length: state.length });
  const [getAllApplicationsById] = useLazyGetAllApplicationsByIdQuery();
  
  const getApplications = async (id) => {
    const applications = await getAllApplicationsById({ project_id: id });
    setSelectedApplication({
      name: applications?.data?.data?.[0]?.name,
      label: applications?.data?.data?.[0]?.name,
      id: applications?.data?.data?.[0]?.id,
      server_name: applications?.data?.data?.[0]?.server_name,
      server_id: applications?.data?.data?.[0]?.server_id,
    });
    setApplicationSelectOption(applications?.data?.data);
  };

  const onAddProjectButtonClicked = (navigate) => {
    dispatch(
      openDrawer({
        component: <AddProjectDrawer navigate={navigate} />,
        title: "Create Project",
        positiveActName: "save",
      })
    );
  };
  const tabChange = (value) => {
    window.history.replaceState(null, null, `?tab=${value}`);
    setActiveTab(value);
  };

  const tabs = [
    {
      label: (
        <Typography
          variant="body2"
          gutterBottom
          component="div"
          sx={{
            fontFamily: "crayond_semibold",
            fontSize: "14px",
            textTransform: "capitalize",
          }}
        >
          Overall Dashboard
        </Typography>
      ),
      value: 1,
      body: (
        <div>
          <OverallDashboard
            project={selectedProject}
            application={selectedApplication}
            applicationCount={applicationSelectOption?.length}
            projectApplication={applicationSelectOption}
          />
        </div>
      ),
    },
    {
      label: (
        <Typography
          variant="body2"
          gutterBottom
          component="div"
          sx={{
            fontFamily: "crayond_semibold",
            fontSize: "14px",
            textTransform: "capitalize",
          }}
        >
          SOC 2 Security
        </Typography>
      ),
      value: 2,
      body: (
        <div>
          <SocSecurity />
        </div>
      ),
    },
    {
      label: (
        <Typography
          variant="body2"
          gutterBottom
          component="div"
          sx={{
            fontFamily: "crayond_semibold",
            fontSize: "14px",
            textTransform: "capitalize",
          }}
        >
          ISO 27001
        </Typography>
      ),
      value: 3,
      body: <div></div>,
    },
  ];

  const showBackdrop = () => {
    dispatch(openBackdrop("Collecting Data....."));
  };

  const projectSelectOnChange = (e, data) => {
    showBackdrop();
    setSelectedProject(data);
    getApplications(data?.project_id);
  };

  const applicationSelectOnchange = (e, data) => {
    setSelectedApplication(data);
  };

  const onViewProject = () => {
    navigate(AppRoutes.projects,{
      state:{
        dashboard: 'true'
      }
    });
  };

  const onHome =()=>{
    window.history.replaceState(null, null, `?tab=1`);
    setActiveTab(1);
  }

  // project listing ---
  React.useEffect(() => {
    if (canIRefresh) {
      getAllProjects();
      dispatch(refreshProjectListing());
    }
    // eslint-disable-next-line
  }, [canIRefresh]);

  React.useEffect(() => {
    if (!isLoading && !isError) {
      setSelectOption(
        data?.projects?.map((list) => ({
          label: list?.name,
          value: list?.name,
          project_id: list?.id,
        }))
      );
      setSelectedProject({
        label: data?.projects?.[0]?.name,
        value: data?.projects?.[0]?.name,
        project_id: data?.projects?.[0]?.id,
      });
      getApplications(data?.projects?.[0]?.id);
    }

    // eslint-disable-next-line
  }, [isLoading, isError]);
 
  return (
    <>
      <TopNavBar
        showActionBtn={true}
        showActionBtnView={true}
        onHomePage={onHome}
        actionBtnNameView="View Projects"
        actionBtnName="+ Add Project"
        viewProjects={() => onViewProject()}
        actionBtnOnClick={() => onAddProjectButtonClicked(navigate)}
      />
      <Box sx={dashboard_styles.rootSx}>
        {/* Tab Data */}
           <DataTabs
        tabRootSx={dashboard_styles.dataTabRootSx}
          tabs={tabs}
          activeTab={activeTab}
          handleChangeTab={(val) => tabChange(val)}
        />

        {/* DropDown for project and application */}
          <Box sx={dashboard_styles.filedContainer}>
            {/* project- list */}
            <AutocompleteCmp
              width={"254px"}
              isLoading={isLoading}
              id={selectedProject?.project_id}
              autocompletedClass={dashboard_styles.autocompeleteSx}
              value={selectedProject ?? null}
              options={selectOption?.map((option) => {
                return {
                  value: option?.value,
                  label: option?.value,
                  project_id: option?.project_id,
                };
              })}
              onChange={(e, value) =>
                projectSelectOnChange(e.target.value, value)
              }
              disableClearable={true}
            />

            {/* Application- list */}
            <AutocompleteCmp
              width={"234px"}
              isLoading={isLoading}
              // id={selectedApplication?.project_id}
              autocompletedClass={dashboard_styles.autocompeleteSx}
              value={selectedApplication ?? null}
              options={
                applicationSelectOption
                  ? applicationSelectOption?.map((option) => {
                      return {
                        value: option?.name,
                        label: option?.name,
                        id: option?.id,
                        server_id: option?.server_id,
                      };
                    })
                  : []
              }
              onChange={(e, value) =>
                applicationSelectOnchange(e.target.value, value)
              }
              disableClearable={true}
            />

            {/*Filter Icon */}
            <Box sx={dashboard_styles.filterBtn}>
              <FilterIcon />
            </Box>
          </Box>
      </Box>
    </>
  );
};

const OverallDashboard = ({  projectApplication ,project, application, applicationCount }) => {
  // General Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = {
    project_id: project?.project_id,
    server_id: application?.server_id,
    application_id: application?.id,
  };

  const hideBackdrop = () => {
    dispatch(closeBackdrop());
  };

  // Redux Hooks ForAPI integration
  const [DASTReport,...DastParams] = useLazyDASTReportQuery();
  const [getCurrentUsageData,...CurrentUsageParams] = useLazyCurrentUsageQuery();
  const [SASTReport,...SastParams] = useLazySASTReportQuery();
  // const [GetAllSASTReport] =useLazyGetAllSASTReportQuery();
  const [PerformanceTestReport,...PerformanceParams] = useLazyPerformanceTestReportQuery();

  // General Hooks
  const [sastReportCount, setSastReportCount] = useState({});
  const [dastData, setDastData] = useState({});
  const [isLoading,setisLoading] = useState(true)
  const [performanceReportData, setPerformanceReportData] = useState(null);
  // const [num, setNum] = useState(0);
  const [usage, setUsage] = useState({
    cpu: "",
    disk: "",
    memory: "",
  });
  const usageCurrent = async () => {
    let usage = await getCurrentUsageData({
      project_id: data?.project_id,
      server_id: data?.server_id,
    });
    setisLoading(CurrentUsageParams?.[0]?.isLoading)
    setUsage({
      cpu: usage?.data?.cpu_usage,
      disk: usage?.data?.disk_usage,
      memory: usage?.data?.mem_usage,
    });
  };

  const getPerformanceData = async () => {
    let performanceData = await PerformanceTestReport({
      application_id: data?.application_id,
    });
    setPerformanceReportData(performanceData?.data);
    setisLoading(PerformanceParams?.[0]?.isLoading)
  };

  const getDASTData = async () => {
    let dataDAST = await DASTReport({ application_id: data?.application_id });
    const riskcode = dataDAST?.data?.data?.site?.[0]?.alerts?.map((data) => {
      return data?.riskcode;
    });
    // const Informational_Medium_riskcode = riskcode?.filter((i) => {
    //   return i === "0";
    // })?.length;
    const Low_riskcode = riskcode?.filter((i) => {
      return i === "1";
    })?.length;

    const Medium_riskcode = riskcode?.filter((i) => {
      return i === "2";
    })?.length;
    const high_riskcode = riskcode?.filter((i) => {
      return i === "3";
    })?.length;
    setDastData({
      Low_riskcode,
      Medium_riskcode,
      high_riskcode,
    });
    setisLoading(DastParams?.[0]?.isLoading)

  };

  const getSastData = async () => {
    let SastData = await SASTReport({
      application_id: data?.application_id,
    });
    setSastReportCount(SastData?.data);
    setisLoading(SastParams?.[0]?.isLoading)
  };

  const gotoPerformance = () => {
    navigate(
      `Performance_Test_Report/${data?.project_id}/${data?.server_id}/${data?.application_id}/${project?.value}/${application?.server_name}/${application?.name}/Performance%20Test%20Report`,
      {
        state: {
          reportFilter: "true",
        },
      }
    );
  };

  const gotoSastReport = () => {
    navigate(
      `SAST_Report/${data?.project_id}/${data?.server_id}/${data?.application_id}/${project?.value}/${application?.server_name}/${application?.name}/SAST_Report%20Test%20Report`,
      {
        state: {
          reportFilter: "true",
        },
      }
    );
  };

  const dastReport = () => {
    navigate(
      `DAST_Report/${data?.project_id}/${data?.server_id}/${data?.application_id}/${project?.value}/${application?.server_name}/${application?.name}/DAST%20Report`,
      {
        state: {
          reportFilter: "true",
        },
      }
    );
  };

  const initialData = () => {
    if (data.application_id) {
      getPerformanceData();
      getDASTData();
      getSastData();
      usageCurrent();
    }
    if (!data?.application_id) {
      setPerformanceReportData(null);
      setSastReportCount({});
      setDastData({});
    }
  };

  React.useEffect(() => {
    initialData();
    hideBackdrop();
    // eslint-disable-next-line
  }, [data?.application_id]);

  //  for Sast Count
  // React.useEffect(() => {
  //   const sastCount = 0;
  //   sastReportCount?.data?.component?.measures?.map((val) => {
  //     if (
  //       val.metric === "bugs" ||
  //       val.metric === "vulnerabilities" ||
  //       val.metric === "security_hotspots"
  //     ) {
  //       return sastCount + (val?.value ?? 0);
  //     }
  //     return null;
  //   });
  //   setNum(sastCount);
  //   // eslint-disable-next-line
  // }, [sastReportCount?.data]);

  return (

    <>
     {projectApplication === null ?
        <Box sx={{position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',}}>
        <img src="/images/emptyState.svg" alt="emptyState" />
        <Typography sx={dashboard_styles.emptyTitle}>
        There is no application available to showcase for this project!!!!
        </Typography>
      </Box>
      :
      <Box sx={dashboard_styles.detailsContainer}>
      {/* ProgressCard  */}
      { !isLoading && <Grid sx={dashboard_styles.applicationCardSx} container spacing={{ xs: 2, md: 2, lg: 4 }} columns={{ xs: 1, sm: 8, md: 12 }}  
      >
        <Grid item>
          <ProgressWidgetVertical title={"CPU"} progress={usage?.cpu} />
        </Grid>

        <Grid item>
          <ProgressWidgetVertical title={"Disk"} progress={usage?.disk} />
        </Grid>

        <Grid item>
          <ProgressWidgetVertical title={"Memory"} progress={usage?.memory} />
        </Grid>

        <Grid item>
          <Stack
            sx={dashboard_styles.applications}
            direction={"row"}
            gap={"10px"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography sx={dashboard_styles.applicationsText}>Applications</Typography>
            <Typography sx={dashboard_styles.applicationsText2}>
              {applicationCount}
            </Typography>
          </Stack>
        </Grid>
      </Grid>}
      {isLoading && application && <SkeletonLoader spacing={{ xs: 2, md: 2, lg: 4 }} skeletonLoaderSx={{width:'100%',maxWidth:'275px'}}  coloumns={{ xs: 1, sm: 8, md: 12 }} loaderSx={dashboard_styles.loaderSx} arrayNumber={4} height={"73px"}/>}

      {/* Title  */}
      <Stack
        direction={"row"}
        gap={"10px"}
        alignItems={"center"}
        sx={dashboard_styles.applicationSx}
      >
        <Typography sx={dashboard_styles.title}>{application?.label}</Typography>
        <Typography sx={dashboard_styles.label}>{application?.server_name}</Typography>
      </Stack>

      {/* Performance */}
      <Box>
       <Typography sx={dashboard_styles.subTitle}>Performance testing report</Typography>
       {!isLoading &&<Box>
        {performanceReportData?.data ? (
        <Grid
          container
          spacing={{ xs: 2, md: 2, lg: 4 }}
          columns={{ xs: 1, sm: 8, md: 12 }}
          // justifyContent={"space-between"}
        >
          <Grid  item sx={dashboard_styles.performanceCard} onClick={gotoPerformance} >
            <ReportCard width={"206px"} height={"154px"} label={"Performance"}>
              <Progress
                size={68}
                progress={Math.round(
                  performanceReportData?.data?.categories?.performance?.score *
                    100
                )}
                type="POSITIVE"
                isBg
              />
            </ReportCard>
          </Grid>
          <Grid
            item
            sx={dashboard_styles.performanceCard}
            onClick={gotoPerformance}
          >
            <ReportCard width={"206px"}  height={"154px"}label={"Accessibility"}>
              <Progress
                size={68}
                progress={Math.round(
                  performanceReportData?.data?.categories?.accessibility
                    ?.score * 100
                )}
                type="POSITIVE"
                isBg
              />
            </ReportCard>
          </Grid>
          <Grid
            item
            sx={dashboard_styles.performanceCard}
            onClick={gotoPerformance}
          >
            <ReportCard  width={"206px"} height={"154px"} label={"Best practice"}>
              <Progress
                size={68}
                progress={Math.round(
                  performanceReportData?.data?.categories["best-practices"]
                    .score * 100
                )}
                type="POSITIVE"
                isBg
              />
            </ReportCard>
          </Grid>
          <Grid
            item
            sx={dashboard_styles.performanceCard}
            onClick={gotoPerformance}
          >
            <ReportCard width={"206px"} height={"154px"} label={"SEO"}>
              <Progress
                size={68}
                progress={Math.round(
                  performanceReportData?.data.categories.seo.score * 100
                )}
                type="POSITIVE"
                isBg
              />
            </ReportCard>
          </Grid>
        </Grid>
      ) : (<Box sx={dashboard_styles.emptyState}>
          <img src="/images/emptyState.svg" alt="emptyState" />
          <Typography sx={dashboard_styles.emptyTitle}>
            There is no Performance report to show.
          </Typography>
        </Box>)}
         </Box>}
           {isLoading && data?.application_id  && <SkeletonLoader
            arrayNumber={4}  spacing={{ xs: 2, md: 2, lg: 4 }}  coloumns={{ xs: 1, sm: 8, md: 12 }}
           justifyContent= {{ xs: "start"}} skeletonLoaderSx={{width:'100%',maxWidth:'206px'}}  height={"154px"}/>}
      </Box>

      {/* Sast */}
      <Box>
        <Typography sx={dashboard_styles.subTitle}>SAST report</Typography>
        {!isLoading && <Box>
          {sastReportCount?.data?.component?.measures ? (
         <Grid
          container
          spacing={{ xs: 2, md: 2, lg: 4 }}
          columns={{ xs: 1, sm: 8, md: 12 }}
          // justifyContent={"space-between"}
        >
          <Grid item sx={dashboard_styles.performanceCard} onClick={gotoSastReport}>
            <ReportCard width={"299px"}height={"176px"} label={"Bugs"} icon={<BugIconBlue />} >
              <Typography
                sx={{
                  ...dashboard_styles.progressCount,
                  color: getColorFromProgress(
                    sastReportCount?.data?.component?.measures?.find(
                      (val) => val.metric === "bugs"
                    )?.value,
                    100
                  ),
                }}
              >
                {sastReportCount?.data?.component?.measures?.find(
                  (val) => val.metric === "bugs"
                )?.value ?? 0}
              </Typography>
            </ReportCard>
          </Grid>
          <Grid item sx={dashboard_styles.performanceCard} onClick={gotoSastReport}>
            <ReportCard width={"299px"}height={"176px"}label={"Vulnerabilities"} icon={<VulnerabilitiesIcon />} >
              <Typography
                sx={{
                  ...dashboard_styles.progressCount,
                  color: getColorFromProgress(
                    sastReportCount?.data?.component?.measures?.find(
                      (val) => val.metric === "vulnerabilities"
                    )?.value,
                    100
                  ),
                }}
              >
                {sastReportCount?.data?.component?.measures?.find(
                  (val) => val.metric === "vulnerabilities"
                )?.value ?? 0}
              </Typography>
            </ReportCard>
          </Grid>
          <Grid item sx={dashboard_styles.performanceCard} onClick={gotoSastReport}>
            <ReportCard width={"299px"} height={"176px"} label={"Code Smells"} icon={<CodeSmellsIcon />} >
              <Typography  sx={{  ...dashboard_styles.progressCount, color: getColorFromProgress( sastReportCount?.data?.component?.measures?.find( (val) => val.metric === "code_smells"
                    )?.value,
                    100
                  ),
                }}
              >
                {sastReportCount?.data?.component?.measures?.find(
                  (val) => val.metric === "code_smells"
                )?.value ?? 0}
              </Typography>
            </ReportCard>
          </Grid>
          <Grid item sx={dashboard_styles.performanceCard} onClick={gotoSastReport}>
            <ReportCard width={"299px"}   height={"176px"}label={"Security Hotspots"}icon={<SecurityIcon />}>
              <Typography
                sx={{
                  ...dashboard_styles.progressCount,
                  color: getColorFromProgress(
                    sastReportCount?.data?.component?.measures?.find(
                      (val) => val.metric === "security_hotspots"
                    )?.value,
                    100
                  ),
                }}
              >
                {sastReportCount?.data?.component?.measures?.find(
                  (val) => val.metric === "security_hotspots"
                )?.value ?? 0}
              </Typography>
            </ReportCard>
          </Grid>
         </Grid>
           ) : (
           <Box sx={dashboard_styles.emptyState}>
          <img src="/images/emptyState.svg" alt="emptyState" />
          <Typography sx={dashboard_styles.emptyTitle}>
            There is no SAST report to show.
          </Typography>
        </Box>
      )}
     </Box>}
      {isLoading && <SkeletonLoader 
      arrayNumber={4}  spacing={{ xs: 2, md: 2, lg: 4 }}  coloumns={{ xs: 1, sm: 8, md: 12 }}
      justifyContent= {{ xs: "start" }} skeletonLoaderSx={{width:'100%',maxWidth:'299px'}} height={"176px"}/>}
      </Box>

      {/* Dast */}
      <Box>
      <Typography sx={dashboard_styles.subTitle}>DAST report</Typography>
         {!isLoading && <Box>
        {JSON.stringify(dastData) !== "{}" ? (
        <Grid
          container
          spacing={{ xs: 2, md: 2, lg: 4 }}
          columns={{ xs: 1, sm: 8, md: 12 }}
          // justifyContent={"space-between"}
        >
          <Grid item sx={dashboard_styles.performanceCard} onClick={dastReport}>
            <ReportCard width={"299px"}  height={"176px"} label={"Low risk"}  icon={<MeterIcon />}  >
              <Typography sx={{
                  ...dashboard_styles.progressCount,
                  color: getColorFromProgress(dastData?.Low_riskcode, 100),
                }}
              >
                {dastData?.Low_riskcode ?? 0}
              </Typography>
            </ReportCard>
          </Grid>
          <Grid item sx={dashboard_styles.performanceCard} onClick={dastReport}>
            <ReportCard width={"299px"}height={"176px"}label={"Medium risk"} icon={<MeterIcon />} >
              <Typography
                sx={{
                  ...dashboard_styles.progressCount,
                  color: getColorFromProgress(dastData?.Medium_riskcode, 100),
                }}
              >
                {dastData?.Medium_riskcode ?? 0}
              </Typography>
            </ReportCard>
          </Grid>
          <Grid item sx={dashboard_styles.performanceCard} onClick={dastReport}>
            <ReportCard width={"299px"}height={"176px"}label={"High risk"} icon={<MeterIcon />}>
              <Typography
                sx={{
                  ...dashboard_styles.progressCount,
                  color: getColorFromProgress(dastData?.high_riskcode, 100),
                }}
              >
                {dastData?.high_riskcode ?? 0}
              </Typography>
            </ReportCard>
          </Grid>
        </Grid>
         ) : (
        <Box sx={dashboard_styles.emptyState}>
          <img src="/images/emptyState.svg" alt="emptyState" />
          <Typography sx={dashboard_styles.emptyTitle}>
            There is no DAST report to show.
          </Typography>
        </Box>
         )}
        </Box>}
      {isLoading && <SkeletonLoader 
      arrayNumber={4}  spacing={{ xs: 2, md: 2, lg: 4 }}  coloumns={{ xs: 1, sm: 8, md: 12 }}
      justifyContent= {{ xs: "start" }} skeletonLoaderSx={{width:'100%',maxWidth:'299px'}} height={"176px"}/>}
      </Box>
    </Box> }
    </>
    
  );
};
