import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { dialogStyle } from "./style";

export default function AlertDialog({
  headTitle,
  dialogRootsx = {},
  paperProps,
  handleClose = () => false,
  open,
  bodyContent = "",
  closeBtn = "",
  closesx={},
  acceptSx={},
  accpetBtn = "",
  handleAccept = () => false,
}) {
  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ ...dialogRootsx }}
      PaperProps={paperProps}
    >
      <DialogTitle sx={{ padding: "0px" }}>{headTitle}</DialogTitle>
      <DialogContent sx={{ padding: "0px" }}>
        <DialogContentText sx={{ ...dialogStyle.bodyContent }}>
          {bodyContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ ...dialogStyle.btnSx }}>
        <Button
          variant="outlined"
          onClick={(e)=>handleAccept(e)}
          sx={{
            ...dialogStyle.closebtnSx, ...acceptSx  
          }}
          autoFocus
        >
          {accpetBtn}
        </Button>
        <Button
          variant="text"
          onClick={handleClose}
          sx={{
            ...dialogStyle.closebtnSx,...closesx,
          }}
        >
          {closeBtn}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
