import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ApplicationDrawer, CreateInstanceDrawer, GridView, ListView, NavTitle, TopNavBar } from "../../components";
import { useGetAllCreateInstanceQuery, useGetProjectQuery } from "../../redux/services";
import { openDrawer } from "../../redux/slices/drawer";
import { refreshProjectInstanceListing } from '../../redux/slices/utils';
import { AppRoutes } from "../../router/routes";
import { ViewValues } from "../../utils";
import { ProjectInstanceLoading } from "./projectInstanceLoading";
import { ProjectInstancesNotFound } from "./projectInstancesNotFound";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    backgroundImage: "url('/images/jpg/signInSignUpBg.png')",
    height: "100%",
    backgroundSize: "cover",
  },
  cardbox: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "90px",
  },
}));

export const ProjectInstances = () => {
  // Gerneral hooks
  const classes = useStyles();
  
  const dispatch = useDispatch();
  
  const navigate = useNavigate();
  
  const canIRefresh = useSelector((state) => state.utils.refreshProjectInstanceListing);
    
  const [searchParams, setSearchParams] = useSearchParams();
  
  let { project_id } = useParams();

  // Component States
  
  const [search, setSearch] = React.useState("");
  //  const [locationData, setlocationData] = React.useState("");
  
  const [view, setView] = React.useState(ViewValues.list);
  
  const [state] = React.useState({ start: 0, length: 200 });

  //Redux  Hooks for API Integration
  const { isLoading, isError, data, refetch: getAllCreateInstance } = useGetAllCreateInstanceQuery({ project_id, ...state });
  
  const { isLoading: isProjectDetailLoading, data: projectDetail, isError: projectDetailError } = useGetProjectQuery({ id: project_id });
  
  const handleView = (view) => {
    setSearchParams({ view });
    setView(view);
  };

  const fetchAllApplications = (url = "") => {
    navigate(url)
  }

  const addApplications = (instance) => {
    let linkText = giveMeTopNavbarPrimaryText();
    dispatch(
      openDrawer({
        component: (
          <ApplicationDrawer instance={instance} project_id={project_id} host={instance.host} linkText={linkText} linkTextTwo={instance.name} refreshData={fetchAllApplications} />
        ),
        title: "Create Applications",
        positiveActName: "save",
      })
    );
  };
 
  const viewInstance = (instance) => {
    let linkText = giveMeTopNavbarPrimaryText();
    let linkTextTwo = instance?.name;
    navigate(`${AppRoutes.projectInstanceDetailParent}${project_id}/${instance.id}/${linkText}/${linkTextTwo}`);
  };

  const onListBtnClick = (instance) => {
    if (instance.applications_count === 0) {
      addApplications(instance);
    } else {
      viewInstance(instance);
    }
  };

  const onAddInstanceButtonClicked = () => {
    dispatch(
      openDrawer({
        component: <CreateInstanceDrawer id={project_id} navigate={navigate} />,
        title: "Create Instance",
        positiveActName: "save",
      })
    );
  };

  const giveMeTopNavbarPrimaryText = () => {
    if (isProjectDetailLoading) {
      return "Loading...";
    } else if (projectDetailError) {
      return "Automatly";
    } else {
      return projectDetail?.data?.name;
    }
  };
  // refresh the list 
  React.useEffect(() => {
    if (canIRefresh) {
      getAllCreateInstance();
      dispatch(refreshProjectInstanceListing());
    }
    // eslint-disable-next-line
  }, [canIRefresh]);

  React.useEffect(() => {
    // setlocationData(location.state)
    if (!Object.values(ViewValues).includes(searchParams.get('view'))) {
      setSearchParams({ view: ViewValues.list })
    } else {
      setSearchParams({ view: searchParams.get('view') });
      setView(searchParams.get('view'));
    }
    //eslint-disable-next-line
  }, [])
  return (
    <>
      {/* Top Navbar*/}
      <TopNavBar
        prevURL={AppRoutes?.projects}
        showActionBtn={true}
        actionBtnName={"+ Add Instance"}
        actionBtnOnClick={onAddInstanceButtonClicked}
        title={giveMeTopNavbarPrimaryText()}
        subTitle={"Instance"}
        showTopLeftNav={true}
        breadcrumbsLink={[
          {
            pathName: "Back",
            link: AppRoutes.projects,
            subText:"Projects"
          }
        ]}
      />

      {/* Project Instances */}
      <div className={classes.root}>
        {/* Header */}
        <NavTitle
          title={`Instance`}
          handleView={handleView}
          btntext="Add projects"
          searchprojects="search"
          search={search}
          setSearch={setSearch}
          view={view}
        />

        {/* No Projects Found */}
        {!isLoading && !isError && data.createInstance === null && (
          <ProjectInstancesNotFound
            onAddInstanceButtonClicked={onAddInstanceButtonClicked}
          />
        )}
        {/*Loader*/}
        {isLoading && (
          <ProjectInstanceLoading
            project_id={project_id}
            view={view}
          />
        )}

        {/* Instance List along with search filter */}
        {!isLoading && !isError && data.createInstance?.length > 0 && (
          <Grid
            container
            spacing={{ xs: 2, md: 2 }}
            columns={{ xs: 1, sm: 8, md: 12 }}
            sx={{ padding: "20px" }}
          >
            {data.createInstance &&
              data.createInstance.map((instance, index) => {
                // Props for the <GridView /> and <ListView />
                let props = {
                  images: "/images/homeScreenLogo.png",
                  title: instance.name,
                  onBtnClick: () => onListBtnClick(instance),
                  btnHelperText: instance.applications_count
                    ? `${instance.applications_count} application(s)`
                    : ``,
                  btnVarient:
                    instance.applications_count > 0 ? "outlined" : "text",
                  btnName:
                    instance.applications_count > 0
                      ? "view"
                      : "+ Add Applications",
                  onBtnView: () => viewInstance(instance),
                  btnViewText: instance.applications_count > 0 ? "" : "view",
                  data: instance || {},
                };

                let gridView = view === ViewValues.grid;

                // Component based on Grid and List View
                let list = (
                  <Grid
                    key={index}
                    item
                    xs={gridView ? 2 : 12}
                    sm={gridView ? 4 : 12}
                    md={gridView ? 3 : 12}
                  >
                    {gridView && <GridView {...props} />}
                    {!gridView && <ListView {...props} />}
                  </Grid>
                );
                if (search.trim().length > 0) {
                  if (
                    instance.name.toLowerCase().includes(search.toLowerCase())
                  ) {
                    return list;
                  }
                } else {
                  return list;
                }
                return instance;
              })}
          </Grid>
        )}
      </div>
    </>
  );
};
