export const issuesStyle = {
  rootBlock: {
    padding: "20px 20px",
    backgroundColor: "#F6F6F6",
  },
  paperBlock: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 3px 6px #00000014",
    opacity: 1,
    position: "relative",
  },

  iconOutlined: {
    display: "flex",
    alignItems: "flex-start",
    gap: "3px",
    marginBottom: "5px",
  },

  pathIcon: {
    display: "flex",
    width: "20px",
    height: "30px",
    color: "#0063E7",
    cursor: "pointer",
  },
  pathText: {
    color: "#0063E7",
    fontSize: "14px",
    cursor: "pointer",
  },
  paginationSx: {
    "& .MuiPagination-ul": {
      justifyContent: "end",
    },
  },
  rowpage: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      width: "54px",
    },
    "& .MuiOutlinedInput-input": {
      textAlign: "center",
      fontSize: "16px",
      fontWeight: "600",
      padding: "0px",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transperant",
      border: "0px",
    },
  },
  pagesBox: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  totalBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  pagination: {
    "& .MuiTablePagination-displayedRows": {
      display: "none",
    },
    "& .MuiTablePagination-actions": {
      display: "none",
    },
    "& .MuiTablePagination-selectLabel": {
      fontWeight: "600",
    },
  },
  totalArrowSx: {
    // display: 'flex',
    // justifyContent: 'center',
    // flexDirection:'',
    // position: 'absolute',
    // left: '39px',
    cursor: "pointer",
    // margin: '0px 4px',
    // fontSize: '17px',
  },
  dropDownSx: {
    input: {
      '&[type="number"]::-webkit-inner-spin-button, &[type="number"]::-webkit-outer-spin-button':
        {
          WebkitAppearance: "none",
          margin: "0",
        },
    },
  },
  paginationTitle: {
    fontWeight: "600",
    color: "#000",
    fontSize: "14px",
    ml: 1,
  },
  keyArrowSx: {
    width: "24px",
  },
  totalPaginationSx: {
    width: "104px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};
