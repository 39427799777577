export const  CodeSmellsIcon = (props) => (
<svg xmlns="http://www.w3.org/2000/svg" id="Group_110378" data-name="Group 110378" width="17.539" height="22.874" viewBox="0 0 17.539 22.874">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_56067" data-name="Rectangle 56067" width="17.539" height="22.874" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-3">
      <rect id="Rectangle_56064" data-name="Rectangle 56064" width="6.803" height="7.013" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-4">
      <rect id="Rectangle_56065" data-name="Rectangle 56065" width="6.733" height="2.601" fill="none"/>
    </clipPath>
  </defs>
  <g id="Group_110341" data-name="Group 110341" clip-path="url(#clip-path)">
    <g id="Group_110340" data-name="Group 110340" transform="translate(0 0)">
      <g id="Group_110339" data-name="Group 110339" clip-path="url(#clip-path)">
        <path id="Path_98339" data-name="Path 98339" d="M16.1,22.991H2.238A1.733,1.733,0,0,1,.5,21.257V2.069A1.733,1.733,0,0,1,2.238.336h8.79l6.793,7.013V21.254A1.733,1.733,0,0,1,16.1,22.991" transform="translate(-0.368 -0.245)" fill="#3183ef"/>
        <g id="Group_110335" data-name="Group 110335" transform="translate(10.658 0.091)" opacity="0.2">
          <g id="Group_110334" data-name="Group 110334">
            <g id="Group_110333" data-name="Group 110333" clip-path="url(#clip-path-3)">
              <path id="Path_98340" data-name="Path 98340" d="M39.225.336v5.4a1.614,1.614,0,0,0,1.614,1.614h5.19Z" transform="translate(-39.225 -0.336)" fill="#fff"/>
            </g>
          </g>
        </g>
        <g id="Group_110338" data-name="Group 110338" transform="translate(10.728 5.965)" opacity="0.2">
          <g id="Group_110337" data-name="Group 110337">
            <g id="Group_110336" data-name="Group 110336" clip-path="url(#clip-path-4)">
              <path id="Path_98341" data-name="Path 98341" d="M39.484,21.956a1.792,1.792,0,0,0,1.424,1.739,1.6,1.6,0,0,0,.171.038c.677.122,5.138.916,5.138.815V23.094h-5.19s-1.3-.043-1.543-1.138" transform="translate(-39.483 -21.956)"/>
            </g>
          </g>
        </g>
        <path id="Path_98342" data-name="Path 98342" d="M13.557,41.235l-2.651.969,2.651.962v1l-3.79-1.44V41.685l3.79-1.44Z" transform="translate(-7.114 -29.31)" fill="#fafafa"/>
        <path id="Path_98343" data-name="Path 98343" d="M27.186,33.047h1.1l-2.617,7.5h-1.1Z" transform="translate(-17.894 -24.068)" fill="#fafafa"/>
        <path id="Path_98344" data-name="Path 98344" d="M42.6,41.735v1.038l-3.78,1.436v-.983l2.651-.962L38.825,41.3v-.986Z" transform="translate(-28.276 -29.357)" fill="#fafafa"/>
        <path id="Path_98345" data-name="Path 98345" d="M13.483,41.2l-2.645.987v-.035l2.654.955.017.006v.018l0,1v.042q-1.927-.721-3.847-1.463v-.019l0-1.038v-.017l.016-.006L13.491,40.2v.011Zm0,0-.008-.99.011.007L9.7,41.678l.016-.023,0,1.038c1.139.37,2.547.96,3.773,1.413l-.039.027,0-1,.017.025-2.649-.969-.049-.018.049-.017Z" transform="translate(-7.039 -29.28)" fill="#455a64"/>
        <path id="Path_98346" data-name="Path 98346" d="M27.069,33.025l1.1-.008h.011s-2.6,7.512-2.6,7.516l-.008.023h-.024l-1.1,0h-.044c.008-.026,2.65-7.5,2.661-7.528m0,0c-.03-.015-2.567,7.665-2.616,7.466l1.1,0-.032.023c.009,0,2.635-7.529,2.649-7.478l-1.1-.008" transform="translate(-17.776 -24.046)" fill="#455a64"/>
        <path id="Path_98347" data-name="Path 98347" d="M42.53,41.648c0,.006.008,1.039.008,1.044s-3.778,1.451-3.779,1.454l-.034.013v-.036l0-.983v-.02l2.668-.974v.061l-2.649-.976-.017-.006v-.017l0-.986V40.19c.041,0,3.763,1.452,3.8,1.458m0,0-3.788-1.405.03-.021,0,.986-.016-.023,2.654.962.085.031-.085.031-2.653.957.018-.026,0,.983-.034-.023,3.785-1.421c-.01.043,0-1.04,0-1.031" transform="translate(-28.202 -29.27)" fill="#455a64"/>
        <path id="Path_98348" data-name="Path 98348" d="M17.457,7.1,10.607.14c.264.032-7.815.04-7.756.04l-.976,0A1.671,1.671,0,0,0,.231,2.022c.01,5.2.041,12.38.033,17.562l0,.976a3.4,3.4,0,0,0,.07.931,1.668,1.668,0,0,0,1.775,1.137c.3,0,1.151.005,1.463,0l3.9.01,7.805.02c.162,0,.327,0,.486,0a1.7,1.7,0,0,0,1.607-1.9v-.488c.008-3.281.042-9.854.085-13.166m0,0c0,3.294.075,9.866.069,13.166v.488a1.86,1.86,0,0,1-1.76,2.071c-.167.005-.326,0-.49,0l-7.805.02-4.879.012H2.106a1.911,1.911,0,0,1-2.015-1.3A3.322,3.322,0,0,1,0,20.56l0-.976C-.01,14.407.03,7.212.035,2.022A1.863,1.863,0,0,1,1.875,0l.976,0c.03,0,7.807.018,7.835.02S17.432,7.1,17.457,7.1" transform="translate(0 -0.001)" fill="#455a64"/>
        <path id="Path_98349" data-name="Path 98349" d="M39.041,5.815a46.858,46.858,0,0,1,0-5.076,46.858,46.858,0,0,1,0,5.076" transform="translate(-28.383 -0.538)" fill="#455a64"/>
        <path id="Path_98350" data-name="Path 98350" d="M50.8,25.962a45.807,45.807,0,0,1-5.018,0,45.807,45.807,0,0,1,5.018,0" transform="translate(-33.341 -18.858)" fill="#455a64"/>
        <path id="Path_98351" data-name="Path 98351" d="M39.222,20.314a1.707,1.707,0,0,0,1.225,1.522,1.464,1.464,0,0,1-1.225-1.522" transform="translate(-28.564 -14.795)" fill="#455a64"/>
      </g>
    </g>
  </g>
</svg>
)
