import React from "react";
import { SASTReport } from "./sastReport";

class SASTReportParent extends React.Component {
  render() {
    return <SASTReport />;
  }
}

export default SASTReportParent;
