import React from "react";
import { BDDReport } from "./bddReport";

class DASTReportParent extends React.Component {
    render() {
        return <BDDReport />
    }
}

export default DASTReportParent;
