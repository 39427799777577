export const healthlistStyle={
    root: {},
    tablebox: {
      backgroundColor: "#fff",
      boxShadow: "0px 1px 7px 1px #e1e1e1",
      padding: "5px 5px",
      borderRadius: "12px",
    },
    linkto: {
      textDecoration: "none",
      color: "#0063E7",
    },
    btnsm: {
      width: "120px",
      textTransform: "capitalize",
      color: "#18283E",
      fontFamily: "crayond_regular",
    },
    btnsmView: {
      marginRight: "6px",
      width: "120px",
      textTransform: "capitalize",
      color: "#18283E",
      fontFamily: "crayond_regular",
    },
    aicon: {
      fontSize: "17px",
      margin: "-5px 5px",
    },
    issues: {
      padding: "0px 5px",
    },
    loadingSx: {
      width: " 20px !important",
      height: "20px !important",
    },
    infoIcon: {
      height: "20px",
    },
    infoIconBtn: {
      marginLeft: "3px",
    },
    modalBox: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "55%",
      maxHeight: "60%",
      backgroundColor: "#FFF",
      border: "1px solid #0000",
      borderRadius: 10,
      boxShadow: 24,
      p: 4,
    //   [theme.breakpoints.down("md")]: {
    //     width: "75%",
    //     height: "60%",
    //   },
    },
    titleSec: {
      padding: "10px 20px 10px 20px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    fadedTitle: {
      color: "#18283ea6",
      fontWeight: "border",
      fontSize: 12,
    },
    boldBody: {
      color: "#18283E",
      fontSize: 16,
      fontWeight: "border",
    },
    addFeaturesSx: {
      backgroundColor: 'transparent',
      border:'0px solid #ccc',
      color: "#0063E7",
      textTransform: "capitalize",
      fontSize: "14px",
      fontWeight: "600",
      fontFamily: "crayond_regular",
      // p: "4px 12px",
      lineHeight: '1rem',
      padding: "0.25rem 0.5rem",
      minWidth:'36px',
      // columnGap: "0.5rem",
      "&:hover": {
        backgroundColor: "transparent",
        color: "#0063E7",
        textDecoration: 'underline',
      },
        borderRadius: '0.25rem',
    },
    loaderSx:{
      mr:1,width:'20px !important',height:'20px !important'
    },
    loadingCompSx:{
        display:'flex',
        alignItems:'center',
        justifyContent:'start'
    },
    bddTitleSx:{
      fontSize:'14px',
      fontWeight:'600',
      color:'#000'
    }
}