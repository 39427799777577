import React, {
  //  useContext,
  useState,
} from "react";
import { makeStyles } from "@mui/styles";
import { TopNavBar } from "../../components";
import { Box, Button, Typography } from "@mui/material";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import FileSaver from "file-saver";
import moment from "moment";
// import { WebSocketContext } from '../../socket';
import { useParams } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import { config } from "../../config";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "70px",
    backgroundColor: "#f9f9f9",
    height: "100vh",
  },
  parentDiv: {
    display: "flex",
    justifyContent: "center",
  },
  Div1: {
    height: "80px",
    width: "90%",
    backgroundColor: "#ffffff",
    marginTop: "22px",
  },
  topbarDiv: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    width: "100%",
    alignItems: "center",
  },
  topbarDiv2: {
    paddingLeft: "14px",
  },
  topbarTypo: {
    fontWeight: "600",
  },
  topbarBtnDiv: {
    paddingRight: "10px",
  },
  contentParentDiv: {
    height: "500px",
    width: "100%",
    backgroundColor: "black",
    overflow: "auto",
  },
  contentTypo: {
    color: "white",
    marginLeft: "6px",
    display: "flex",
    whiteSpace: "nowrap",
  },
  contentSpan: {
    fontWeight: 900,
  },
  git: {
    width: '100%',
    maxWidth: '36px',
    marginTop: '5px',
    marginLeft: '7px',
  },
}));

export const ActivityLog = () => {
  // const socket = useContext(WebSocketContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  // const [ flag, setFlag ] = React.useState(false)
  const [messages, setMessages] = useState([
    {
      message: "Deploying Inprogress..🚀",
      progress: 0,
      prevNumMessages: 0,
    },
  ]);

  const classes = useStyles();
  const {
    linkText,
    linkTextTwo,
    project_id,
    instance_id,
    applicationName,
    application_id,
  } = useParams();

  React.useEffect(() => {
    const socket = new WebSocket(
      `${config.websocket_url}${localStorage.getItem(
        "auth_token"
      )}&application_id=${application_id}`
    );

    if (socket) {
      socket.onmessage = (event) => {
        const {
          // server_id,
          // user,
          // project_id,
          progress,
          message,
          success,
          // application_id,
        } = JSON.parse(event.data);

        if (!success) {
          const state = {
            progress,
            message,
          };
          setMessages((prevMessage) => {
            state.prevNumMessages = state.prevNumMessages + 1;
            return [...prevMessage, state];
          });
        }
      };

      return () => {
        socket.close();
      };
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (messages[messages.length - 1].progress === 100) {
      // setFlag(true)
      enqueueSnackbar(
        "New Application has been created and deployed successfully. Redirecting...",
        {
          variant: "success",
          anchorOrigin: { horizontal: "center", vertical: "top" },
          className: classes.snackbar,
          autoHideDuration: 2000,
        }
      );
      setTimeout(
        () =>
          navigate(
            `/project/instance/${project_id}/${instance_id}/${linkText}/${linkTextTwo}`
          ),
        3000
      );
    }
    // if(flag){
    // 	console.log(`/project/instance/${project_id}/${instance_id}/${linkText}/${linkTextTwo}`)
    // 	setTimeout(() => navigate(`/project/instance/${project_id}/${instance_id}/${linkText}/${linkTextTwo}`), 3000)
    // }
    // eslint-disable-next-line
  }, [messages]);

  const writeDataToFile = (apiDataArray) => {
    let textFile = "";
    // loop through the API data array
    apiDataArray.forEach((data, index) => {
      textFile += index + 1 + " : " + data + "\n";
    });
    return textFile;
  };

  const DownloadActivityLog = (data) => {
    const textFile = writeDataToFile(data);
    const blob = new Blob([textFile], { type: "text/plain;charset=utf-8" });
    // save the text file using the FileSaver library
    FileSaver.saveAs(
      blob,
      "Automately (Activity Log) - " +
        moment().format("DD - MM - YYYY") +
        ".log"
    );
  };

  return (
    <>
      <TopNavBar
        showActionBtn={false}
        showTopLeftNav={true}
        subTitle={applicationName}
        title={"Deployment Log"}
        breadcrumbsLink={[
          {
            pathName: linkText,
            link: AppRoutes.projectInstancesParent + project_id,
            subText: "Instance",
          },

          {
            pathName: linkTextTwo,
            link:
              AppRoutes.projectInstanceDetailParent +
              project_id +
              "/" +
              instance_id +
              "/" +
              linkText +
              "/" +
              linkTextTwo,
            subText: "Applications",
          },
        ]}
      />

      <div className={classes.root}>
        <div className={classes.parentDiv}>
          <div className={classes.Div1}>
            {/* Top bar */}
            <div className={classes.topbarDiv}>
              <div className={classes.topbarDiv2}>
                <Typography variant="body1" className={classes.topbarTypo}>
                  Deployment Log
                </Typography>
              </div>
              <div className={classes.topbarBtnDiv}>
                <Button
                  onClick={() => {
                    DownloadActivityLog(messages);
                  }}
                >
                  <DownloadForOfflineOutlinedIcon />
                </Button>
              </div>
            </div>
            {/* content */}
            <div className={classes.contentParentDiv}>
              <div>
                {messages?.map((data, index) => {
                  return (
                    <>
                      {data?.message ? (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            variant="subtitle1"
                            className={classes.contentTypo}
                          >
                            {`${moment(new Date()).format(
                              "YYYY-MM-DD HH:mm:SS"
                            )}  ${data?.message}  ${data?.progress}%`}
                          </Typography>

                          {/* {[...Array(messages)].map(
                            (_, i) => */}
                          {index === messages?.length - 1 && (
                            <img
                              src="/images/loader.gif"
                              alt="Loading ....."
                              class={classes.git}
                            ></img>
                          )}
                          {/* //     )
                          // )} */}
                          {/* {!websocketMessageReceived && (
                            <img
                              style={{ marginLeft: "12px" }}
                              src="/images/loader.gif"
                              alt="Gitlab"
                              class={classes.git}
                            ></img>
                          )} */}
                        </Box>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
