import React from "react";
import { ErrorLog } from "./errorLog";

class ErrorLogParent extends React.Component {
    render() {
        return <ErrorLog/>
    }
}

export default ErrorLogParent;
