import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { gitTokenAPI } from "./services/oauth/gitLab/authToken";
import {
  authAPI,
  usersAPI,
  projectAPI,
  createInstanceAPI,
  gitLabAPI,
  gitlabIntegrationAPI,
  applicationAPI,
  reportAPI,
  boilerplateAPI,
  getOsAPI,
  getVersionAPI,
  bddAPI,
  bddFeaturesAPI,
  gitHubAPI,
  gitHubTokenAPI,
  gitHubIntegrationAPI,
} from "./services";
import {
  authReducer,
  backdropReducer,
  bddReducer,
  dialogReducer,
  drawerReducer,
  testReducer,
  utilsReducer,
} from "./slices";

const ReduxStore = configureStore({
  reducer: {
    [authAPI.reducerPath]: authAPI.reducer,
    [usersAPI.reducerPath]: usersAPI.reducer,
    [projectAPI.reducerPath]: projectAPI.reducer,
    [createInstanceAPI.reducerPath]: createInstanceAPI.reducer,
    [gitLabAPI.reducerPath]: gitLabAPI.reducer,
    [gitTokenAPI.reducerPath]: gitTokenAPI.reducer,
    [gitlabIntegrationAPI.reducerPath]: gitlabIntegrationAPI.reducer,
    [applicationAPI.reducerPath]: applicationAPI.reducer,
    [reportAPI.reducerPath]: reportAPI.reducer,
    [boilerplateAPI.reducerPath]: boilerplateAPI.reducer,
    [getOsAPI.reducerPath]: getOsAPI.reducer,
    [getVersionAPI.reducerPath]: getVersionAPI.reducer,
    [bddAPI.reducerPath]: bddAPI.reducer,
    [bddFeaturesAPI.reducerPath]: bddFeaturesAPI.reducer,
    [gitHubAPI.reducerPath]: gitHubAPI.reducer,
    [gitHubTokenAPI.reducerPath]: gitHubTokenAPI.reducer,
    [gitHubIntegrationAPI.reducerPath]: gitHubIntegrationAPI.reducer,
    auth: authReducer,
    backdrop: backdropReducer,
    dialog: dialogReducer,
    utils: utilsReducer,
    drawer: drawerReducer,
    bdd: bddReducer,
    test: testReducer,
    // bddFeaturesAPI: bddReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authAPI.middleware)
      .concat(projectAPI.middleware)
      .concat(createInstanceAPI.middleware)
      .concat(gitLabAPI.middleware)
      .concat(gitTokenAPI.middleware)
      .concat(getOsAPI.middleware)
      .concat(getVersionAPI.middleware)
      .concat(bddAPI.middleware)
      .concat(bddFeaturesAPI.middleware)
      .concat(gitHubAPI.middleware)
      .concat(gitHubTokenAPI.middleware)
      .concat(gitHubIntegrationAPI.middleware),
});

export const AppRedux = ({ children }) => {
  return <Provider store={ReduxStore}>{children}</Provider>;
};
