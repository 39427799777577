import { Box, CircularProgress,circularProgressClasses, Divider,Grid,IconButton,Tooltip,Typography,} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CircleIcon from "@mui/icons-material/Circle";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, { useState } from "react";
import { FilterIcon } from "../../assets/filterIcon";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useLocation, useParams } from "react-router-dom";
import { ListView, ToggleButton, TopNavBar } from "../../components";
import {
  useLazyGetAllPerformanceTestReportQuery,
  useLazyPerformanceTestReportQuery,
} from "../../redux/services";
import { AppRoutes } from "../../router/routes";
import { AccordionCmp } from "./accordionCmp";
import moment from "moment";
import html2pdf from "html2pdf.js"
import { closeBackdrop, openBackdrop } from "../../redux/slices/backdrop";
import { useDispatch } from "react-redux";


// import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
// import CircleIcon from '@mui/icons-material/Circle';
// import SquareIcon from '@mui/icons-material/Square';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "90px",
    paddingLeft: "40px",
    paddingRight: "40px",
    // backgroundColor: "#f9f9f9",
    // backgroundImage: "url('/images/jpg/signInSignUpBg.png')",
    // backgroundRepeat: "repeat-y",
    // backgroundSize: 'cover'
  },
  ParentDiv: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #eaeaea",
    margin: "26px 0px",
    borderRadius: "8px",
    // alignItems: 'center',
    // height: '90vh',
    width: "100%",
    // backgroundColor: "#f9f9f9",
    // backgroundImage: "url('/images/jpg/signInSignUpBg.png')",
    // backgroundRepeat: "repeat-y",
    // backgroundSize: 'auto'
  },
  paperErr: {
    width: "90%",
    backgroundColor: "#ffffff",
    height: "90vh",
    overflow: "auto",
  },
  errLoading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "90%",
  },
  notAvailable: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "90%",
  },
  bddAcord: {
    padding: "14px",
  },
  heading: {
    // background: "#f1f1f1",
    
  },
  applicationSx: {
    fontSize: "16px",
    fontweight: "500",
    color: "#000",
    paddingTop: "12px",
    paddingLeft: "32px",
  },
  colorSx: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  filterSx: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "890px",
    margin: "10px auto",
    marginTop: "18px",
    border: "1px solid #eaeaea",
    borderRadius: "8px",
    padding: "12px 12px",
  },
  autocompeleteSx: {
    marginLeft: "8px",
    "& .MuiAutocomplete-option[data-focus='true']": {
      backgroundColor: "#000",
    },
    "& .MuiAutocomplete-option[data-focus='false'][aria-selected='true']": {
      backgroundColor: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
      paddingLeft: "14px !important",
      paddingRight: "28px !important",
      height: "36px!important",
      backgroundColor: "#fff",
      "&.MuiAutocomplete-option.Mui-focused": {
        backgroundColor: "#000",
      },
    },
  },
  emptyState: {
    textAlign: "center",
    padding: "12px",
  },
}));

export const PerformanceReport = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  let {
    project_id,
    instance_id,
    application_id,
    linkText,
    linkTextTwo,
    linkTextThree,
    linkTextFour,
  } = useParams();

  // Redux Hooks
  const [PerformanceTestReport, ...PerformanceTestReportParams] = useLazyPerformanceTestReportQuery();
  const [GetAllPerformanceTestReport, ...ALlPerformanceTestReportParams] = useLazyGetAllPerformanceTestReportQuery();

  // General Hooks
  const [performaceReportData, setPerformaceReportData] = React.useState(null);
  const [currentDate, setCurrentDate] = React.useState(7);
  const [isLoading, setisLoading] = React.useState(true);
  // const [isLoadingPdf, setisLoadingPdf] = React.useState(false);
  const [allExpended, setAllExpended] = React.useState(false);
  // const [version, SetVersion] = React.useState();
  const [isError, setisError] = React.useState(false);
  const [currentTab, setCurrentTab] = React.useState("Performance");
  const [reportData, setReportData] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  // const [download, setdownload] = useState("");

  const hideBackdrop = () => {
    dispatch(closeBackdrop());
  };

  const options = [
    {
      name: "7D",
      value: 7,
    },
    {
      name: "30D",
      value: 30,
    },
    {
      name: "3M",
      value: 90,
    },
    {
      name: "6M",
      value: 180,
    },
  ];

  const CircularProgressWithLabel = (props) => {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          className={props?.className}
          variant={props.variant ?? "determinate"}
          size={props?.size}
          value={props?.value}
          thickness={props.thickness}
          sx={{
            color: props?.color,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="textPrimary"
            sx={{ fontSize: "20px", lineHeight: "12px" }}
          >
            {props?.value}
          </Typography>
        </Box>
      </Box>
    );
  };

  const onStatusChange = (daysAgo) => {
    const date = moment().subtract(daysAgo, "days").format("YYYY-MM-DD");
    getAllPerformanceData(date);
  };

  const getPerformanceData = async () => {
    let performanceData = await PerformanceTestReport({
      application_id: application_id,
    });
    setPerformaceReportData(performanceData?.data?.data);
    setisError(PerformanceTestReportParams[0]?.isError);
    setisLoading(PerformanceTestReportParams[0]?.isLoading);
    setOpenFilter(true);
  };

  const getAllPerformanceData = async (date) => {
    let allPerformanceData = await GetAllPerformanceTestReport({
      application_id: application_id,
      since: date ?? currentDate,
    });
    await fetchReportData(allPerformanceData?.data?.data);
    setisError(ALlPerformanceTestReportParams[0]?.isError);
    setisLoading(ALlPerformanceTestReportParams[0]?.isLoading);
    setOpenFilter(false);
  };

  const fetchReportData = async (data) => {
    try {
      dispatch(openBackdrop("collecting Performance Data....."));
      const arrData = await Promise.all(
        data.map(async (val) => {
          if (!val?.report_url) return null;
          const response = await fetch(val.report_url);
          const data = await response.json();
          return data;
        })
      );
      setReportData(arrData);
       hideBackdrop()
      return arrData.filter(Boolean);
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const TabChange = (e) => {
    if (e.target.id === "Performance") {
      setCurrentTab("Performance");
    }
    if (e.target.id === "accessibility") {
      setCurrentTab("accessibility");
    }
    if (e.target.id === "best_practices") {
      setCurrentTab("best_practices");
    }
    if (e.target.id === "seo") {
      setCurrentTab("seo");
    }
  };

  // item?.hasOwnProperty('cumulative-layout-shift');
  const checkOpportunities = (value) => {
    const opportunitiesKeys = [
      "unused-javascript",
      "server-response-time",
      "render-blocking-resources",
    ];
    return opportunitiesKeys.includes(value);
  };

  const checkPerformanceDiagnostics = (value) => {
    const performanceDiagnostics = [
      "uses-optimized-images",
      "modern-image-formats",
      "uses-long-cache-ttl",
      "font-display",
      "first-contentful-paint",
      "mainthread-work-breakdown",
      "bootup-time",
      "total-byte-weight",
      "critical-request-chains",
      "resource-summary",
      "largest-contentful-paint-element",
      "layout-shift-elements",
      "long-tasks",
      "non-composited-animations",
    ];
    return performanceDiagnostics.includes(value);
  };

  const checkPerformancePassedAudits = (value) => {
    const performancePassedAudits = [
      "uses-responsive-images",
      "offscreen-images",
      "unminified-css",
      "unminified-javascript",
      "unused-css-rules",
      "uses-text-compression",
      "uses-rel-preconnect",
      "redirects",
      "uses-rel-preload",
      "efficient-animated-content",
      "duplicated-javascript",
      "legacy-javascript",
      "preload-lcp-image",
      "dom-size",
      "user-timings",
      "third-party-summary",
      "third-party-facades",
      "lcp-lazy-loaded",
      "uses-passive-event-listeners",
      "no-document-write",
      "unsized-images",
      "viewport",
      "no-unload-listeners",
    ];
    return performancePassedAudits.includes(value);
  };

  const checkAccessbilityContrast = (value) => {
    const AccessbilityContrast = ["color-contrast"];
    return AccessbilityContrast.includes(value);
  };

  const checkAccessbilityadditionalItemManualCheck = (value) => {
    const AccessbilityadditionalItemManualCheck = [
      "logical-tab-order",
      "focusable-controls",
      "interactive-element-affordance",
      "managed-focus",
      "focus-traps",
      "custom-controls-labels",
      "custom-controls-roles",
      "visual-order-follows-dom",
      "offscreen-content-hidden",
      "use-landmarks",
    ];
    return AccessbilityadditionalItemManualCheck.includes(value);
  };

  const checkAccessbilityPassedAudit = (value) => {
    const AccessbilityPassedAudit = [
      "aria-allowed-attr",
      "aria-hidden-body",
      "aria-valid-attr",
      "aria-valid-attr-value",
      "button-name",
      "label",
      "meta-viewport",
      "aria-hidden-focus",
      "bypass",
      "document-title",
      "duplicate-id-active",
      "html-has-lang",
      "html-lang-valid",
      "link-name",
      "tabindex",
      "heading-order",
    ];
    return AccessbilityPassedAudit.includes(value);
  };

  const checkAccessbilityNotApplicable = (value) => {
    const AccessbilityNotApplicable = [
      "aria-command-name",
      "aria-meter-name",
      "aria-treeitem-name",
      "aria-input-field-name",
      "aria-progressbar-name",
      "aria-required-attr",
      "aria-required-children",
      "aria-required-parent",
      "aria-roles",
      "aria-toggle-field-name",
      "aria-tooltip-name",
      "aria-tooltip-name",
      "definition-list",
      "dlitem",
      "duplicate-id-aria",
      "form-field-multiple-labels",
      "frame-title",
      "image-alt",
      "input-image-alt",
      "list",
      "listitem",
      "meta-refresh",
      "object-alt",
      "td-headers-attr",
      "th-has-data-cells",
      "valid-lang",
      "video-caption",
    ];
    return AccessbilityNotApplicable.includes(value);
  };

  const checkBSBrowserCompatibility = (value) => {
    const AccessbilityNotApplicable = ["charset"];
    return AccessbilityNotApplicable.includes(value);
  };

  const checkBSTrustAndSafety = (value) => {
    const BSTrustAndSafety = ["csp-xss"];
    return BSTrustAndSafety.includes(value);
  };

  const checkBSGeneral = (value) => {
    const BSGeneral = ["js-libraries", "valid-source-maps"];
    return BSGeneral.includes(value);
  };

  const checkBSPassedAudit = (value) => {
    const BSPassedAudit = [
      "is-on-https",
      "geolocation-on-start",
      "notification-on-start",
      "no-vulnerable-libraries",
      "password-inputs-can-be-pasted-into",
      "image-aspect-ratio",
      "image-size-responsive",
      "doctype",
      "deprecations",
      "errors-in-console",
      "inspector-issues",
    ];
    return BSPassedAudit.includes(value);
  };

  const checkBSNotApplicable = (value) => {
    const BSNotApplicable = ["preload-fonts"];
    return BSNotApplicable.includes(value);
  };

  const checkSEOCrawlingIndex = (value) => {
    const SEOCrawlingIndex = ["robots-txt"];
    return SEOCrawlingIndex.includes(value);
  };

  const checkSEOAdditionalItemToManualCheck = (value) => {
    const SEOAdditionalItemToManualCheck = ["structured-data"];
    return SEOAdditionalItemToManualCheck.includes(value);
  };

  const checkSEOPassAudit = (value) => {
    const SEOPassAudit = [
      "viewport",
      "document-title",
      "meta-description",
      "http-status-code",
      "link-text",
      "crawlable-anchors",
      "is-crawlable",
      "hreflang",
      "font-size",
      "plugins",
      "tap-targets",
    ];
    return SEOPassAudit.includes(value);
  };

  const checkSEONotApplicable = (value) => {
    const SEONotApplicable = ["image-alt", "canonical"];
    return SEONotApplicable.includes(value);
  };

  const onPerformanceClick = (value, i) => {
    setOpenFilter(true);
    setPerformaceReportData(value);
  };

  const OnCloseClick = () => {
    setOpenFilter(false);
    setCurrentDate(7);
     getAllPerformanceData(moment().subtract(7, "days").format("YYYY-MM-DD"));
  };

  // Download PDF
  function convertHtmlToPdf() {
    const element = document.getElementById('screen');
    const options = {
      margin: 10,
      filename: 'Performance_Test_Reports.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 1.5 },
      jsPDF: { unit: 'mm', format: 'a2', orientation: 'portrait' },
    };
    setTimeout(()=>{
    html2pdf().set(options).from(element).save();
     setAllExpended(false)
     hideBackdrop()
    //  setisLoadingPdf(false)
     // eslint-disable-next-line
  },700)

  }
  
  // on Download Function
  const onClickDownload = async (element) => {
    // setisLoadingPdf(true)
    dispatch(openBackdrop("Downloading Performance Data....."));
    setAllExpended(true)
    convertHtmlToPdf()
  };

  React.useEffect(() => {
    if (location?.state?.reportFilter) {
      setCurrentDate(7);
      getAllPerformanceData(moment().subtract(7, "days").format("YYYY-MM-DD"));
      // getAllPerformanceData( moment().subtract(6, "months").format("YYYY-MM-DD"))
    } else {
      getPerformanceData();
    }
    // eslint-disable-next-line
  }, [application_id]);
  

 // circular - Color-code -function
  const giveMeProgressProps = (progress, end=100) => {
    let percentage = (progress) * 100;
    if (percentage === 0) {
      return { percentage, color: '#cc3300' }
    } else
     if (percentage >= 0 && percentage < 80) {
      return { percentage, color: '#ffcc00', }
    } else if (percentage >= 80 && percentage <= 100) {
      return { percentage, color: '#339900',  }
    } else {
      return { percentage, color: '#ccc', }
    }
  }

  // circular Color Progress Color Code
  const progressProps = giveMeProgressProps(performaceReportData?.categories?.performance?.score);
  const accessibility =giveMeProgressProps(performaceReportData?.categories?.accessibility?.score)
  const bestpractices  =giveMeProgressProps(performaceReportData?.categories['best-practices']?.score)
  const seo =giveMeProgressProps(performaceReportData?.categories?.seo?.score)

  return (
    <>
      <TopNavBar
        loadingDownload={isLoading}
        onDownload={onClickDownload}
        isDownloadRefreshRequired={openFilter}
        showActionBtn={false}
        showTopLeftNav={true}
        breadcrumbsLink={[
          {
            pathName: linkText,
            link: AppRoutes.projectInstancesParent + project_id,
            subText: "Instance",
          },
          {
            pathName: linkTextTwo,
            link:
              AppRoutes.projectInstanceDetailParent +
              project_id +
              "/" +
              instance_id +
              "/" +
              linkText +
              "/" +
              linkTextTwo,
            subText: "Application",
          },
          {
            pathName: linkTextThree,
            link:
              AppRoutes.applications +
              project_id +
              "/" +
              instance_id +
              "/" +
              application_id +
              "/" +
              linkText +
              "/" +
              linkTextTwo +
              "/" +
              linkTextThree,
            subText: "Testing Report",
          },
        ]}
        title={linkTextFour}
        subTitle={"Perfomance"}
      />
      <div className={classes.root}>
        {/* Filter */}
        {location?.state?.reportFilter && !openFilter && (
          <Box className={classes.filterSx}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{ fontSize: "14px", fontWeight: "600", mr: "8px" }}
              >
                Filter
              </Typography>
              <FilterIcon />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
             <ToggleButton  options={options} disable={reportData?.length > 0 ? false : true} value={currentDate} onChange={onStatusChange} />
              {/* <AutocompleteCmp
                width={"140px"}
                disableClearable={true}
                className={classes.autocompeleteSx}
                options={versionOptions}
                value={version ?? null}
                onChange={(e, value) => versionUpdate(e.target.value, value)}
              /> */}
            </Box>
          </Box>
        )}

        {/* Back */} 
        {location?.state?.reportFilter && openFilter && (
          <Box sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              paddingTop: "12px",
            }}
            onClick={OnCloseClick}
          >
            <ArrowBackIosNewIcon
              sx={{ color: "#0063e7", fontSize: "14px", marginRight: "5px" }}
              fontSize="small"
            />
            <Typography
              sx={{ color: "#0063e7", fontSize: "14px", fontWeight: "600" }}
              size="small"
            >
              Back
            </Typography>
          </Box>
        )}


        {/* PerformanceData */} 
        {openFilter &&  isError === false &&isLoading === false &&performaceReportData !== (null || undefined) && (
            <div className={classes.ParentDiv} id="screen">
            {
              performaceReportData &&(
                <Box>
                <Box
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                    marginTop: "20px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <CircularProgressWithLabel
                        value={Math.round(
                          performaceReportData?.categories?.performance
                            ?.score * 100
                        )}
                        className={classes.bar}
                        color={progressProps?.color}
                        size={60}
                        variant={"determinate"}
                        thickness={3}
                      />
                    </div>
                    <div>
                      <Typography
                        sx={{
                          color:
                            currentTab === "Performance" ? progressProps?.color : "",
                            "&:hover": { "-webkit-transform": "scale(1.2)", color: progressProps?.color },
                        }}
                        variant="subtitle2"
                        id="Performance"
                        onClick={(e) => {
                          TabChange(e);
                        }}
                      >
                        Performance
                      </Typography>
                    </div>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <CircularProgressWithLabel
                        value={Math.round(
                          performaceReportData?.categories?.accessibility
                            ?.score * 100
                        )}
                        className={classes.bar}
                        color={accessibility?.color}
                        size={60}
                        variant={"determinate"}
                        thickness={3}
                      />
                    </div>
                    <div>
                      <Typography
                        sx={{
                          color:
                            currentTab === "accessibility" ? accessibility?.color : "",
                            "&:hover": { "-webkit-transform": "scale(1.2)", color: accessibility?.color },
                        }}
                        variant="subtitle2"
                        id="accessibility"
                        onClick={(e) => {
                          TabChange(e);
                        }}
                      >
                        Accessibility
                      </Typography>
                    </div>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <CircularProgressWithLabel
                        value={Math.round(
                          performaceReportData?.categories["best-practices"]
                            .score * 100
                        )}
                        className={classes.bar}
                        color={bestpractices?.color}
                        size={60}
                        variant={"determinate"}
                        thickness={3}
                      />
                    </div>
                    <div>
                      <Typography
                        sx={{
                          color:
                            currentTab === "best_practices" ? bestpractices?.color : "",
                            "&:hover": { "-webkit-transform": "scale(1.2)", color: bestpractices?.color },
                        }}
                        variant="subtitle2"
                        id="best_practices"
                        onClick={(e) => {
                          TabChange(e);
                        }}
                      >
                        Best Practices
                      </Typography>
                    </div>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <CircularProgressWithLabel
                        value={Math.round(
                          performaceReportData.categories.seo.score * 100
                        )}
                        className={classes.bar}
                        color={seo?.color}
                        size={60}
                        variant={"determinate"}
                        thickness={3}
                      />
                    </div>
                    <div>
                      <Typography
                        sx={{
                          color: currentTab === "seo" ? seo?.color : "",
                          "&:hover": { "-webkit-transform": "scale(1.2)", color: seo?.color },
                        }}
                        variant="subtitle2"
                        id="seo"
                        onClick={(e) => {
                          TabChange(e);
                        }}
                      >
                        SEO
                      </Typography>
                    </div>
                  </Box>
                </Box>

                <Divider style={{ marginTop: "25px" }} />

                {/* performance part start */}
                {(currentTab === "Performance" || allExpended) && (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                          marginTop: "100px",
                        }}
                      >
                        <div>
                          <CircularProgressWithLabel
                            value={Math.round(
                              performaceReportData.categories.performance
                                .score * 100
                            )}
                            
                            className={classes.bar}
                            color={progressProps?.color}
                            size={110}
                            variant={"determinate"}
                            thickness={5}
                          />
                        </div>

                        <div>
                          <Typography variant="subtitle2">
                            Performance
                          </Typography>
                        </div>
                      </div>
                      <div>
                        <Typography
                          variant="subtitle1"
                          style={{
                            fontSize: "10px",
                            paddingLeft: "10%",
                            paddingRight: "10%",
                            marginTop: "12px",
                            textAlign: "center",
                          }}
                        >
                          {
                            performaceReportData.i18n.rendererFormattedStrings
                              .varianceDisclaimer
                          }
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          marginTop: "15px",
                        }}
                      >
                        <div>0–49</div>
                        <div>50–89</div>
                        <div>90–100</div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: "50px",
                      }}
                    >
                      <div>
                        <img
                        src={ performaceReportData?.audits?.['full-page-screenshot']?.details?.screenshot?.data}
                          alt="Red dot"
                          style={{
                            height: "300px",
                            width: "300px",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {(currentTab === "Performance"|| allExpended) && (
                  <div>
                    {/* metrics */}
                    <div style={{ width: "100%" }}>
                      <Typography style={{ paddingLeft: "14px" }}>
                        METRICS
                      </Typography>
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ width: "50%" }}>
                        <Divider
                          style={{ marginTop: "24px", marginBottom: "24px" }}
                        />
                        <div style={{ display: "flex", marginLeft: "14px" }}>
                          <div>
                            <CircleIcon
                              sx={{
                                fontSize: "14px",
                                color: "#000",
                                paddingTop: "5px",
                              }}
                            />
                          </div>
                          <div style={{ marginLeft: "8px" }}>
                            <Box
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography variant="subtitle1">
                                First Contentful Paint
                              </Typography>
                              <Tooltip
                                arrow
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      fontSize: "12px",
                                      fontWeight: 400,
                                      color: "#fff",
                                      padding: "7px",
                                      background: "#161515",
                                      marginTop: "0px !important",
                                    },
                                  },
                                  arrow: {
                                    sx: {
                                      color: "#000",
                                    },
                                  },
                                }}
                                title={
                                  <>
                                    <Box className={classes.colorSx}>
                                      <CircleIcon
                                        sx={{
                                          color: "green",
                                          fontSize: "14px",
                                        }}
                                      />
                                      <Typography>
                                        - 0 - 1.8 seconds
                                      </Typography>
                                    </Box>
                                    <Box className={classes.colorSx}>
                                      <CircleIcon
                                        sx={{
                                          color: "orange",
                                          fontSize: "14px",
                                        }}
                                      />
                                      <Typography> - 1.81 seconds</Typography>
                                    </Box>
                                    <Box className={classes.colorSx}>
                                      <CircleIcon
                                        sx={{
                                          color: "red",
                                          fontSize: "14px",
                                        }}
                                      />
                                      <Typography>
                                        -over 3.6 seconds
                                      </Typography>
                                    </Box>
                                  </>
                                }
                              >
                                <IconButton>
                                  <InfoOutlinedIcon
                                    sx={{ fontSize: "14px" }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>
                            <Typography variant="h6">
                              {
                                performaceReportData?.audits[
                                  "first-contentful-paint"
                                ]?.displayValue
                              }
                            </Typography>
                            <Typography variant="body2">
                              {
                                performaceReportData?.audits[
                                  "first-contentful-paint"
                                ]?.description
                              }
                            </Typography>
                          </div>
                        </div>
                      </div>

                      <div style={{ width: "50%" }}>
                        <Divider
                          style={{ marginTop: "24px", marginBottom: "24px" }}
                        />
                        <div style={{ display: "flex", marginLeft: "14px" }}>
                          <div>
                            <CircleIcon
                              sx={{
                                fontSize: "14px",
                                color: "#000",
                                paddingTop: "5px",
                              }}
                            />
                          </div>
                          <div style={{ marginLeft: "8px" }}>
                            <Typography variant="subtitle1">
                              Time to Interactive
                            </Typography>
                            <Typography variant="h6">
                              {
                                performaceReportData?.audits["interactive"]
                                  .displayValue
                              }
                            </Typography>
                            <Typography variant="body2">
                              {
                                performaceReportData?.audits["interactive"]
                                  .description
                              }
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ width: "50%" }}>
                        <Divider
                          style={{ marginTop: "24px", marginBottom: "24px" }}
                        />
                        <div style={{ display: "flex", marginLeft: "14px" }}>
                          <div>
                            <CircleIcon
                              sx={{
                                fontSize: "14px",
                                color: "#000",
                                paddingTop: "5px",
                              }}
                            />
                          </div>
                          <div style={{ marginLeft: "8px" }}>
                            <Box
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography variant="subtitle1">
                                Speed Index
                              </Typography>
                              <Tooltip
                                arrow
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      fontSize: "12px",
                                      fontWeight: 400,
                                      color: "#fff",
                                      padding: "7px",
                                      background: "#161515",
                                      marginTop: "0px !important",
                                    },
                                  },
                                  arrow: {
                                    sx: {
                                      color: "#000",
                                    },
                                  },
                                }}
                                title={
                                  <>
                                    <Box className={classes.colorSx}>
                                      <CircleIcon
                                        sx={{
                                          color: "green",
                                          fontSize: "14px",
                                        }}
                                      />
                                      <Typography>
                                        - 0 - 3.4 seconds
                                      </Typography>
                                    </Box>
                                    <Box className={classes.colorSx}>
                                      <CircleIcon
                                        sx={{
                                          color: "orange",
                                          fontSize: "14px",
                                        }}
                                      />
                                      <Typography>
                                        {" "}
                                        3.4 - 5.8 seconds
                                      </Typography>
                                    </Box>
                                    <Box className={classes.colorSx}>
                                      <CircleIcon
                                        sx={{
                                          color: "red",
                                          fontSize: "14px",
                                        }}
                                      />
                                      <Typography>
                                        - over 5.8 seconds
                                      </Typography>
                                    </Box>
                                  </>
                                }
                              >
                                <IconButton>
                                  <InfoOutlinedIcon
                                    sx={{ fontSize: "14px" }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>
                            <Typography variant="h6">
                              {
                                performaceReportData?.audits["speed-index"]
                                  .displayValue
                              }
                            </Typography>
                            <Typography variant="body2">
                              {
                                performaceReportData?.audits["speed-index"]
                                  .description
                              }
                            </Typography>
                          </div>
                        </div>
                      </div>

                      <div style={{ width: "50%" }}>
                        <Divider
                          style={{ marginTop: "24px", marginBottom: "24px" }}
                        />
                        <div style={{ display: "flex", marginLeft: "14px" }}>
                          <div>
                            <CircleIcon
                              sx={{
                                fontSize: "14px",
                                color: "#000",
                                paddingTop: "5px",
                              }}
                            />
                          </div>
                          <div style={{ marginLeft: "8px" }}>
                            <Box
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography variant="subtitle1">
                                Total Blocking Time
                              </Typography>
                              <Tooltip
                                arrow
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      fontSize: "12px",
                                      fontWeight: 400,
                                      color: "#fff",
                                      padding: "7px",
                                      background: "#161515",
                                      marginTop: "0px !important",
                                    },
                                  },
                                  arrow: {
                                    sx: {
                                      color: "#000",
                                    },
                                  },
                                }}
                                title={
                                  <>
                                    <Box className={classes.colorSx}>
                                      <CircleIcon
                                        sx={{
                                          color: "green",
                                          fontSize: "14px",
                                        }}
                                      />
                                      <Typography>
                                        - {"<"} 200 milliseconds
                                      </Typography>
                                    </Box>
                                    <Box className={classes.colorSx}>
                                      <CircleIcon
                                        sx={{
                                          color: "orange",
                                          fontSize: "14px",
                                        }}
                                      />
                                      <Typography>
                                        {" "}
                                        - {">"}200 milliseconds and {"<"}300
                                        milliseconds
                                      </Typography>
                                    </Box>
                                    <Box className={classes.colorSx}>
                                      <CircleIcon
                                        sx={{
                                          color: "red",
                                          fontSize: "14px",
                                        }}
                                      />
                                      <Typography>
                                        - {"<"}300 milliseconds
                                      </Typography>
                                    </Box>
                                  </>
                                }
                              >
                                <IconButton>
                                  <InfoOutlinedIcon
                                    sx={{ fontSize: "14px" }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>

                            <Typography variant="h6">
                              {
                                performaceReportData?.audits[
                                  "total-blocking-time"
                                ]?.displayValue
                              }
                            </Typography>
                            <Typography variant="body2">
                              {
                                performaceReportData?.audits[
                                  "total-blocking-time"
                                ]?.description
                              }
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ width: "50%" }}>
                        <Divider
                          style={{ marginTop: "24px", marginBottom: "24px" }}
                        />
                        <div style={{ display: "flex", marginLeft: "14px" }}>
                          <div>
                            <CircleIcon
                              sx={{
                                fontSize: "14px",
                                color: "#000",
                                paddingTop: "5px",
                              }}
                            />
                          </div>
                          <div style={{ marginLeft: "8px" }}>
                            <Box
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography variant="subtitle1">
                                Largest Contentful Paint
                              </Typography>
                              <Tooltip
                                arrow
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      fontSize: "12px",
                                      fontWeight: 400,
                                      color: "#fff",
                                      padding: "7px",
                                      background: "#161515",
                                      marginTop: "0px !important",
                                    },
                                  },
                                  arrow: {
                                    sx: {
                                      color: "#000",
                                    },
                                  },
                                }}
                                title={
                                  <>
                                    <Box className={classes.colorSx}>
                                      <CircleIcon
                                        sx={{
                                          color: "green",
                                          fontSize: "14px",
                                        }}
                                      />
                                      <Typography>
                                        - 0 - 2.5 seconds
                                      </Typography>
                                    </Box>
                                    <Box className={classes.colorSx}>
                                      <CircleIcon
                                        sx={{
                                          color: "orange",
                                          fontSize: "14px",
                                        }}
                                      />
                                      <Typography> - 2.51 - 4.0</Typography>
                                    </Box>
                                    <Box className={classes.colorSx}>
                                      <CircleIcon
                                        sx={{
                                          color: "red",
                                          fontSize: "14px",
                                        }}
                                      />
                                      <Typography>
                                        - 4.1 seconds and over
                                      </Typography>
                                    </Box>
                                  </>
                                }
                              >
                                <IconButton>
                                  <InfoOutlinedIcon
                                    sx={{ fontSize: "14px" }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>
                            <Typography variant="h6">
                              {
                                performaceReportData?.audits[
                                  "largest-contentful-paint"
                                ]?.displayValue
                              }
                            </Typography>
                            <Typography variant="body2">
                              {
                                performaceReportData?.audits[
                                  "largest-contentful-paint"
                                ]?.description
                              }
                            </Typography>
                          </div>
                        </div>
                        <Divider
                          style={{ marginTop: "24px", marginBottom: "24px" }}
                        />
                      </div>

                      <div style={{ width: "50%" }}>
                        <Divider
                          style={{ marginTop: "24px", marginBottom: "24px" }}
                        />
                        <div style={{ display: "flex", marginLeft: "14px" }}>
                          <div>
                            <CircleIcon
                              sx={{
                                fontSize: "14px",
                                color: "#000",
                                paddingTop: "5px",
                              }}
                            />
                          </div>
                          <div style={{ marginLeft: "8px" }}>
                            <Box
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography variant="subtitle1">
                                Cumulative Layout Shift
                              </Typography>
                              <Tooltip
                                arrow
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      fontSize: "12px",
                                      fontWeight: 400,
                                      color: "#fff",
                                      padding: "7px",
                                      background: "#161515",
                                      marginTop: "0px !important",
                                    },
                                  },
                                  arrow: {
                                    sx: {
                                      color: "#000",
                                    },
                                  },
                                }}
                                title={
                                  <>
                                    <Box className={classes.colorSx}>
                                      <CircleIcon
                                        sx={{
                                          color: "green",
                                          fontSize: "14px",
                                        }}
                                      />
                                      <Typography>
                                        - 0 - 0.1 seconds
                                      </Typography>
                                    </Box>
                                    <Box className={classes.colorSx}>
                                      <CircleIcon
                                        sx={{
                                          color: "orange",
                                          fontSize: "14px",
                                        }}
                                      />
                                      <Typography> - 0.1 - 0.25</Typography>
                                    </Box>
                                    <Box className={classes.colorSx}>
                                      <CircleIcon
                                        sx={{
                                          color: "red",
                                          fontSize: "14px",
                                        }}
                                      />
                                      <Typography>
                                        - over 0.25 seconds
                                      </Typography>
                                    </Box>
                                  </>
                                }
                              >
                                <IconButton>
                                  <InfoOutlinedIcon
                                    sx={{ fontSize: "14px" }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>
                            <Typography variant="h6">
                              {
                                performaceReportData?.audits[
                                  "cumulative-layout-shift"
                                ]?.displayValue
                              }
                            </Typography>
                            <Typography variant="body2">
                              {
                                performaceReportData?.audits[
                                  "cumulative-layout-shift"
                                ]?.description
                              }
                            </Typography>
                          </div>
                        </div>
                        <Divider
                          style={{ marginTop: "24px", marginBottom: "24px" }}
                        />
                      </div>
                    </div>
                    {/* OPPORTUNITIES */}
                    <div>
                      <div style={{ width: "100%", marginTop: "20px" }}>
                        <div>
                          <Typography style={{ marginLeft: "14px" }}>
                            OPPORTUNITIES
                          </Typography>
                        </div>
                      </div>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className={classes.bddAcord}
                      >
                        {Object.entries(performaceReportData.audits)?.map(
                          (item, index) => {
                            if (checkOpportunities(item[0])) {
                              return (
                                <AccordionCmp
                                  allExpended={allExpended}
                                  index={index}
                                  title={item[1]?.title.replace(
                                    /^"(.*)"$/,
                                    "$1"
                                  )}
                                  description={item[1]?.description}
                                />
                              );
                            }
                            return true;
                          }
                        )}
                      </Grid>
                    </div>
                    {/* DIAGNOSTICS */}
                    <div>
                      <div style={{ width: "100%" }}>
                        <div>
                          <Typography style={{ marginLeft: "14px" }}>
                            DIAGNOSTICS
                          </Typography>
                        </div>
                      </div>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className={classes.bddAcord}
                      >
                        {Object.entries(performaceReportData.audits)?.map(
                          (item, index) => {
                            if (checkPerformanceDiagnostics(item[0])) {
                              return (
                                <AccordionCmp
                                  allExpended={allExpended}
                                  index={index}
                                  title={item[1]?.title.replace(
                                    /^"(.*)"$/,
                                    "$1"
                                  )}
                                  description={item[1]?.description}
                                />
                              );
                            }
                            return true;
                          }
                        )}
                      </Grid>
                    </div>
                    {/* PASSED AUDITS */}

                    <div>
                      <div style={{ width: "100%" }}>
                        <div>
                          <Typography style={{ marginLeft: "14px" }}>
                            PASSED AUDITS
                          </Typography>
                        </div>
                      </div>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className={classes.bddAcord}
                      >
                        {Object.entries(performaceReportData.audits)?.map(
                          (item, index) => {
                            if (checkPerformancePassedAudits(item[0])) {
                              return (
                                <AccordionCmp
                                  allExpended={allExpended}
                                  index={index}
                                  title={item[1]?.title.replace(
                                    /^"(.*)"$/,
                                    "$1"
                                  )}
                                  description={item[1]?.description}
                                />
                              );
                            }
                            return true;
                          }
                        )}
                      </Grid>
                    </div>
                  </div>
                )}
                {/* performance part end */}

                {/* accessbility start */}
                {(currentTab === "accessibility"|| allExpended) && (
                  <div style={{ width: "100%", paddingTop: "85px" }}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgressWithLabel
                          value={Math.round(
                            performaceReportData?.categories?.accessibility
                              ?.score * 100
                          )}
                          className={classes.bar}
                          color={accessibility?.color}
                          size={110}
                          variant={"determinate"}
                          thickness={5}
                        />
                      </div>
                      <div
                        style={{ paddingLeft: "20%", paddingRight: "20%" }}
                      >
                        <Typography
                          variant="subtitle2"
                          style={{
                            textAlign: "center",
                            paddingBottom: "20px",
                            paddingTop: "20px",
                          }}
                        >
                          Accessibility
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ textAlign: "center" }}
                        >
                          {
                            performaceReportData?.categories?.accessibility
                              .description
                          }
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}

                {/* CONTRAST   */}
                {(currentTab === "accessibility"|| allExpended) && (
                  <div>
                    <div style={{ width: "100%", marginTop: "20px" }}>
                      <div>
                        <Typography style={{ marginLeft: "14px" }}>
                          CONTRAST
                        </Typography>
                      </div>
                    </div>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.bddAcord}
                    >
                      {Object.entries(performaceReportData.audits)?.map(
                        (item, index) => {
                          if (checkAccessbilityContrast(item[0])) {
                            return (
                              <AccordionCmp
                                allExpended={allExpended}
                                index={index}
                                title={item[1]?.title.replace(
                                  /^"(.*)"$/,
                                  "$1"
                                )}
                                description={item[1]?.description}
                              />
                            );
                          }
                          return true;
                        }
                      )}
                    </Grid>
                  </div>
                )}
                {/* CONTRAST   */}

                {/* ADDITIONAL ITEMS TO MANUALLY CHECK    */}
                {(currentTab === "accessibility"|| allExpended) && (
                  <div>
                    <div style={{ width: "100%", marginTop: "20px" }}>
                      <div>
                        <Typography style={{ marginLeft: "14px" }}>
                          ADDITIONAL ITEMS TO MANUALLY CHECK{" "}
                        </Typography>
                      </div>
                    </div>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.bddAcord}
                    >
                      {Object.entries(performaceReportData.audits)?.map(
                        (item, index) => {
                          if (
                            checkAccessbilityadditionalItemManualCheck(
                              item[0]
                            )
                          ) {
                            return (
                              <AccordionCmp
                                allExpended={allExpended}
                                index={index}
                                title={item[1]?.title.replace(
                                  /^"(.*)"$/,
                                  "$1"
                                )}
                                description={item[1]?.description}
                              />
                            );
                          }
                          return true;
                        }
                      )}
                    </Grid>
                  </div>
                )}
                {/* ADDITIONAL ITEMS TO MANUALLY CHECK    */}

                {/* PASSED AUDITS   */}
                {(currentTab === "accessibility"|| allExpended) && (
                  <div>
                    <div style={{ width: "100%", marginTop: "20px" }}>
                      <div>
                        <Typography style={{ marginLeft: "14px" }}>
                          PASSED AUDITS
                        </Typography>
                      </div>
                    </div>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.bddAcord}
                    >
                      {Object.entries(performaceReportData.audits)?.map(
                        (item, index) => {
                          if (checkAccessbilityPassedAudit(item[0])) {
                            return (
                              <AccordionCmp
                                allExpended={allExpended}
                                index={index}
                                title={item[1]?.title.replace(
                                  /^"(.*)"$/,
                                  "$1"
                                )}
                                description={item[1]?.description}
                              />
                            );
                          }
                          return true;
                        }
                      )}
                    </Grid>
                  </div>
                )}
                {/* PASSED AUDITS   */}

                {/* NOT APPLICABLE   */}

                {(currentTab === "accessibility" || allExpended) && (
                  <div>
                    <div style={{ width: "100%", marginTop: "20px" }}>
                      <div>
                        <Typography style={{ marginLeft: "14px" }}>
                          NOT APPLICABLE
                        </Typography>
                      </div>
                    </div>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.bddAcord}
                    >
                      {Object.entries(performaceReportData.audits)?.map(
                        (item, index) => {
                          if (checkAccessbilityNotApplicable(item[0])) {
                            return (
                              <AccordionCmp
                                allExpended={allExpended}
                                index={index}
                                title={item[1]?.title.replace(
                                  /^"(.*)"$/,
                                  "$1"
                                )}
                                description={item[1]?.description}
                              />
                            );
                          }
                          return true;
                        }
                      )}
                    </Grid>
                  </div>
                )}
                {/* NOT APPLICABLE   */}

                {/* accessbility end */}

                {/* best practice start */}
                {(currentTab === "best_practices"|| allExpended) && (
                  <div style={{ width: "100%", paddingTop: "85px" }}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgressWithLabel
                          value={Math.round(
                            performaceReportData?.categories["best-practices"]
                              .score * 100
                          )}
                          className={classes.bar}
                          color={bestpractices?.color}
                          size={110}
                          variant={"determinate"}
                          thickness={5}
                        />
                      </div>
                      <div
                        style={{ paddingLeft: "20%", paddingRight: "20%" }}
                      >
                        <Typography
                          variant="subtitle2"
                          style={{
                            textAlign: "center",
                            paddingBottom: "20px",
                            paddingTop: "20px",
                          }}
                        >
                          Best Practices
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ textAlign: "center" }}
                        >
                          {
                            performaceReportData.i18n.rendererFormattedStrings
                              .varianceDisclaimer
                          }
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}

                {/* BROWSER COMPATIBILITY   */}
                {(currentTab === "best_practices"|| allExpended) && (
                  <div>
                    <div style={{ width: "100%", marginTop: "20px" }}>
                      <div>
                        <Typography style={{ marginLeft: "14px" }}>
                          BROWSER COMPATIBILITY
                        </Typography>
                      </div>
                    </div>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.bddAcord}
                    >
                      {Object.entries(performaceReportData.audits)?.map(
                        (item, index) => {
                          if (checkBSBrowserCompatibility(item[0])) {
                            return (
                              <AccordionCmp
                                allExpended={allExpended}
                                index={index}
                                title={item[1]?.title.replace(
                                  /^"(.*)"$/,
                                  "$1"
                                )}
                                description={item[1]?.description}
                              />
                            );
                          }
                          return true;
                        }
                      )}
                    </Grid>
                  </div>
                )}
                {/* BROWSER COMPATIBILITY   */}

                {/* TRUST AND SAFETY   */}
                {(currentTab === "best_practices"|| allExpended) && (
                  <div>
                    <div style={{ width: "100%", marginTop: "20px" }}>
                      <div>
                        <Typography style={{ marginLeft: "14px" }}>
                          TRUST AND SAFETY
                        </Typography>
                      </div>
                    </div>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.bddAcord}
                    >
                      {Object.entries(performaceReportData.audits)?.map(
                        (item, index) => {
                          if (checkBSTrustAndSafety(item[0])) {
                            return (
                              <AccordionCmp
                                allExpended={allExpended}
                                index={index}
                                title={item[1]?.title.replace(
                                  /^"(.*)"$/,
                                  "$1"
                                )}
                                description={item[1]?.description}
                              />
                            );
                          }
                          return true;
                        }
                      )}
                    </Grid>
                  </div>
                )}
                {/* TRUST AND SAFETY   */}

                {/* GENERAL   */}
                {(currentTab === "best_practices"|| allExpended) && (
                  <div>
                    <div style={{ width: "100%", marginTop: "20px" }}>
                      <div>
                        <Typography style={{ marginLeft: "14px" }}>
                          GENERAL
                        </Typography>
                      </div>
                    </div>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.bddAcord}
                    >
                      {Object.entries(performaceReportData.audits)?.map(
                        (item, index) => {
                          if (checkBSGeneral(item[0])) {
                            return (
                              <AccordionCmp
                                allExpended={allExpended}
                                index={index}
                                title={item[1]?.title.replace(
                                  /^"(.*)"$/,
                                  "$1"
                                )}
                                description={item[1]?.description}
                              />
                            );
                          }
                          return true;
                        }
                      )}
                    </Grid>
                  </div>
                )}
                {/* GENERAL   */}

                {/* PASSED AUDITS   */}
                {(currentTab === "best_practices"|| allExpended) && (
                  <div>
                    <div style={{ width: "100%", marginTop: "20px" }}>
                      <div>
                        <Typography style={{ marginLeft: "14px" }}>
                          PASSED AUDITS
                        </Typography>
                      </div>
                    </div>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.bddAcord}
                    >
                      {Object.entries(performaceReportData.audits)?.map(
                        (item, index) => {
                          if (checkBSPassedAudit(item[0])) {
                            return (
                              <AccordionCmp
                                allExpended={allExpended}
                                index={index}
                                title={item[1]?.title.replace(
                                  /^"(.*)"$/,
                                  "$1"
                                )}
                                description={item[1]?.description}
                              />
                            );
                          }
                          return true;
                        }
                      )}
                    </Grid>
                  </div>
                )}
                {/* PASSED AUDITS   */}

                {/* NOT APPLICABLE   */}
                {(currentTab === "best_practices"|| allExpended) && (
                  <div>
                    <div style={{ width: "100%", marginTop: "20px" }}>
                      <div>
                        <Typography style={{ marginLeft: "14px" }}>
                          NOT APPLICABLE
                        </Typography>
                      </div>
                    </div>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.bddAcord}
                    >
                      {Object.entries(performaceReportData.audits)?.map(
                        (item, index) => {
                          if (checkBSNotApplicable(item[0])) {
                            return (
                              <AccordionCmp
                                allExpended={allExpended}
                                index={index}
                                title={item[1]?.title.replace(
                                  /^"(.*)"$/,
                                  "$1"
                                )}
                                description={item[1]?.description}
                              />
                            );
                          }
                          return true;
                        }
                      )}
                    </Grid>
                  </div>
                )}
                {/* NOT APPLICABLE   */}

                {/* best practice end */}

                {/* SEO start */}
                {(currentTab === "seo"|| allExpended) && (
                  <div style={{ width: "100%", paddingTop: "85px" }}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgressWithLabel
                          value={Math.round(
                            performaceReportData.categories.seo.score * 100
                          )}
                          className={classes.bar}
                          color={seo?.color}
                          size={110}
                          variant={"determinate"}
                          thickness={5}
                        />
                      </div>
                      <div
                        style={{ paddingLeft: "20%", paddingRight: "20%" }}
                      >
                        <Typography
                          variant="subtitle2"
                          style={{
                            textAlign: "center",
                            paddingBottom: "20px",
                            paddingTop: "20px",
                          }}
                        >
                          SEO
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ textAlign: "center" }}
                        >
                          {performaceReportData?.categories?.seo?.description}
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}

                {/* CRAWLING AND INDEXING */}
                {(currentTab === "seo"|| allExpended) && (
                  <div>
                    <div style={{ width: "100%", marginTop: "20px" }}>
                      <div>
                        <Typography style={{ marginLeft: "14px" }}>
                          CRAWLING AND INDEXING
                        </Typography>
                      </div>
                    </div>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.bddAcord}
                    >
                      {Object.entries(performaceReportData.audits)?.map(
                        (item, index) => {
                          if (checkSEOCrawlingIndex(item[0])) {
                            return (
                              <AccordionCmp
                                allExpended={allExpended}
                                index={index}
                                title={item[1]?.title.replace(
                                  /^"(.*)"$/,
                                  "$1"
                                )}
                                description={item[1]?.description}
                              />
                            );
                          }
                          return true;
                        }
                      )}
                    </Grid>
                  </div>
                )}
                {/* CRAWLING AND INDEXING */}

                {/* ADDITIONAL ITEMS TO MANUALLY CHECK  */}
                {(currentTab === "seo"|| allExpended) && (
                  <div>
                    <div style={{ width: "100%", marginTop: "20px" }}>
                      <div>
                        <Typography style={{ marginLeft: "14px" }}>
                          ADDITIONAL ITEMS TO MANUALLY CHECK
                        </Typography>
                      </div>
                    </div>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.bddAcord}
                    >
                      {Object.entries(performaceReportData.audits)?.map(
                        (item, index) => {
                          if (checkSEOAdditionalItemToManualCheck(item[0])) {
                            return (
                              <AccordionCmp
                                allExpended={allExpended}
                                index={index}
                                title={item[1]?.title.replace(
                                  /^"(.*)"$/,
                                  "$1"
                                )}
                                description={item[1]?.description}
                              />
                            );
                          }
                          return true;
                        }
                      )}
                    </Grid>
                  </div>
                )}
                {/* ADDITIONAL ITEMS TO MANUALLY CHECK  */}

                {/* PASSED AUDITS   */}
                {(currentTab === "seo"|| allExpended) && (
                  <div>
                    <div style={{ width: "100%", marginTop: "20px" }}>
                      <div>
                        <Typography style={{ marginLeft: "14px" }}>
                          PASSED AUDITS
                        </Typography>
                      </div>
                    </div>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.bddAcord}
                    >
                      {Object.entries(performaceReportData.audits)?.map(
                        (item, index) => {
                          if (checkSEOPassAudit(item[0])) {
                            return (
                              <AccordionCmp
                                allExpended={allExpended}
                                index={index}
                                title={item[1]?.title.replace(
                                  /^"(.*)"$/,
                                  "$1"
                                )}
                                description={item[1]?.description}
                              />
                            );
                          }
                          return true;
                        }
                      )}
                    </Grid>
                  </div>
                )}
                {/* PASSED AUDITS   */}

                {/* NOT APPLICABLE   */}
                {(currentTab === "seo"|| allExpended) && (
                  <div>
                    <div style={{ width: "100%", marginTop: "20px" }}>
                      <div>
                        <Typography style={{ marginLeft: "14px" }}>
                          NOT APPLICABLE
                        </Typography>
                      </div>
                    </div>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.bddAcord}
                    >
                      {Object.entries(performaceReportData.audits)?.map(
                        (item, index) => {
                          if (checkSEONotApplicable(item[0])) {
                            return (
                              <AccordionCmp
                                allExpended={allExpended}
                                index={index}
                                title={item[1]?.title.replace(
                                  /^"(.*)"$/,
                                  "$1"
                                )}
                                description={item[1]?.description}
                              />
                            );
                          }
                          return true;
                        }
                      )}
                    </Grid>
                  </div>
                )}
                {/* NOT APPLICABLE   */}

                {/* SEO end */}
              </Box>
              )
            }
            </div>
          )}
      </div>

        {/* List View */} 
        {!openFilter && reportData?.length > 0 && reportData?.map((val, index) => (
          <Grid
            sx={{ padding: "10px 26px" }}
            key={index}
            className={classes.listi}
          >
            <ListView
              data={val || {}}
              titleSx={{ padding: "5px 5px" }}
              images="/images/homeScreenLogo.png"
              title={moment(val?.fetchTime).format("MMMM Do YYYY, h:mm a")}
              btnName={"View Performance"}
              btnVarient="outlined"
              onBtnClick={() => onPerformanceClick(val, index)}
            />
          </Grid>
         ))}

      {/* Loader */}
      {isError === false &&
        isLoading === true &&
        performaceReportData === null && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "90%",
            }}
          >
            <CircularProgress color="inherit" />
          </div>
        )}

      {/* error message -500 */}
      {isError === true &&
        isLoading === false &&
        performaceReportData === null && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "90%",
            }}
          >
            {"OOPS Something Went Wrong !"}
          </div>
        )}

       {isError === false &&
        isLoading === false &&
        (performaceReportData === (null || undefined) || (!performaceReportData?.length<0 ) ||(location?.state?.reportFilter && reportData?.length === 0)) && (
         <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "75%",
            }}
          >
            <Box className={classes.emptyState}>
              <img src="/images/emptyState.svg" alt="emptyState" />
              <Typography className={classes.emptyTitle}>
                There is no Performance report to show.
              </Typography>
            </Box>
          </div>
        )}
    </>
  );
};
