import AnnouncementIcon from "@mui/icons-material/Announcement";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  ApplicationDrawer,
  HealthList,
  ProgressBar,
  TopNavBar,
  UpDownCalculation,
} from "../../components";
import {
  useCurrentUsageQuery,
  useGetCreateInstanceQuery,
  useLazyGetAllApplicationsQuery,
} from "../../redux/services";
import { openDrawer } from "../../redux/slices/drawer";
import { AppRoutes } from "../../router/routes";
import { InstanceApplication } from "./instanceApplicationNotFound";
import { WebSocketContext } from "../../socket";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    backgroundImage: "url('/images/jpg/signInSignUpBg.png')",
    height: "auto",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  instancebg: {
    textAlign: "center",
    backgroundImage: "url('/images/instance.svg')",
    height: "215px",
    backgroundSize: "cover",
  },
  progressbar: {
    marginTop: "-100px",
  },
  container: {
    width: "100%",
    maxWidth: "75%",
  },
  aicon: {
    fontSize: "17px",
    margin: "-5px 5px",
  },
  issues: {
    padding: "0px 6px",
  },
}));

export const InstanceDetail = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const socket = useContext(WebSocketContext);
  const [progress, setProgress] = React.useState({});
  const navigate = useNavigate();

  // Component States
  let { project_id, id, linkText, linkTextTwo } = useParams();

  const [getAllApplications, ...AllGetAllApplicationsParams] =
    useLazyGetAllApplicationsQuery();

  const {
    isLoading: isProjectDetailLoading,
    data: projectDetail,
    isError: projectDetailError,
  } = useGetCreateInstanceQuery({ project_id: project_id, server_id: id });

  const { isLoading: CurrentUsageLoading, data: CurrentUsageData } =
    useCurrentUsageQuery({ project_id: project_id, server_id: id });

  // eslint-disable-next-line
  const [tableData, setTableData] = React.useState([]);

  const [UpDown, setUpDown] = React.useState({ up: 0, down: 0 });

  const [loadingData, setLoadingData] = React.useState(false);

  const onAddApplicationButtonClicked = () => {
    dispatch(
      openDrawer({
        component: (
          <ApplicationDrawer
            instance={{ id: id }}
            linkText={linkText}
            linkTextTwo={linkTextTwo}
            project_id={project_id}
            refreshData={fetchAllApplications}
            host={projectDetail?.data?.host}
          />
        ),
        title: "Create Application",
        positiveActName: "save",
      })
    );
  };

  const fetchAllApplications = async (navigateLink = "") => {
    if (navigateLink) {
      navigate(navigateLink);
    }

    setLoadingData(true);
    let dataGetAllApplications = await getAllApplications({
      project_id: project_id,
      server_id: id,
    });

    setTableData(
      dataGetAllApplications?.data?.data?.map((i) => ({
        id: i?.id,
        secondarytext: i?.name,
        infotext: i?.is_health_ok ? "No issue" : "Outage",
        btntext: "Monitor",
        route: `/application/${project_id}/${i?.server_id}/${i?.id}/${linkText}/${linkTextTwo}?tab=1`,
        progress: 0,
        data: i,
        project_id:project_id,
        linkText:linkText,
        linkTextTwo:linkTextTwo,
      }))
    );

    let up = dataGetAllApplications?.data?.data.filter((i) => {
      return i?.is_health_ok;
    }).length;

    let down = dataGetAllApplications?.data?.data.filter((i) => {
      return !i?.is_health_ok;
    })?.length;

    setUpDown({
      up: up,
      down: down,
    });
    setLoadingData(false);
  };

  const giveMeTopNavbarPrimaryText = () => {
    if (isProjectDetailLoading) {
      return "Loading...";
    } else if (projectDetailError) {
      return "Automatly";
    } else {
      return projectDetail.data.name;
    }
  };

  React.useEffect(() => {
    if (socket) {
      socket.onmessage = (event) => {
        const {
          server_id,
          user,
          project_id,
          progress,
          message,
          application_id,
        } = JSON.parse(event.data);
        console.log("ws", {
          server_id,
          user,
          project_id,
          progress,
          message,
          application_id,
        });

        if (application_id) {
          setProgress((prevProgress) => ({
            ...prevProgress,
            [application_id]: {
              value: progress,
              message: message,
            },
          }));
        }
        // if (tableData.length) {
        // 	setTableData((prevState) => {
        // 		if (application_id) {
        // 			// debugger
        // 			const updatedTableData = tableData.map((application) => {
        // 				if (application.id === application_id) {
        // 					return {
        // 						...application,
        // 						progress: progress,
        // 					};
        // 				}

        // 				return application;
        // 			});
        // 			console.log(updatedTableData);

        // 			return updatedTableData;
        // 		}

        // 		return prevState;
        // 	});
        // }
      };

      return () => {
        socket.close();
      };
    }
  }, [socket]);

  React.useEffect(() => {
    fetchAllApplications();
    // eslint-disable-next-line
  }, [project_id, id, AllGetAllApplicationsParams[0]?.isLoading]);

  return (
    <>
      <TopNavBar
        showActionBtn={true}
        actionBtnName={"+ Add Application"}
        actionBtnOnClick={onAddApplicationButtonClicked}
        showTopLeftNav={true}
        title={giveMeTopNavbarPrimaryText()}
        subTitle={"Applications"}
        breadcrumbsLink={[
          {
            pathName: linkText,
            link: AppRoutes.projectInstancesParent + project_id,
            subText: "Instance",
          },
        ]}
      />
      <div className={classes.root}>
        {/* //Health-BG */}
        <Box className={classes.instancebg} sx={{ padding: "50px" }}></Box>
        {/* //ProgressBar */}
        <Box className={classes.progressbar}>
          <Container
            className={classes.container}
            sx={{ width: "100%", maxWidth: "95%" }}
          >
            <Box sx={{ display: "flex" }}>
              <Grid
                container
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 1, sm: 8, md: 12 }}
                sx={{ padding: "20px" }}
              >
                <Grid item xs={2} sm={4} md={3}>
                  <ProgressBar
                    title={CurrentUsageLoading ? "loading..." : "CPU"}
                    progress={CurrentUsageData?.cpu_usage}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={3}>
                  <ProgressBar
                    title={CurrentUsageLoading ? "loading..." : "Disk"}
                    progress={CurrentUsageData?.disk_usage}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={3}>
                  <ProgressBar
                    title={CurrentUsageLoading ? "loading..." : "Memory"}
                    progress={CurrentUsageData?.mem_usage}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={3}>
                  <UpDownCalculation
                    title={CurrentUsageLoading ? "loading..." : "Application"}
                    calculation={0}
                    numberDown={UpDown?.down}
                    numberUp={UpDown?.up}
                    numUp="up"
                    numDown="Down"
                  />
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>

        {/* //addInstance */}
        <Box sx={{ padding: "35px 35px" }}>
          {AllGetAllApplicationsParams[0].isLoading ? (
            <CircularProgress color="inherit" />
          ) : (
            <>
              {AllGetAllApplicationsParams[0]?.data?.totalRecords ? (
                <Box sx={{ paddingTop: "10px" }}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      component="div"
                      sx={{ fontFamily: "crayond_semibold" }}
                    >
                      Applicatons
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      component="div"
                      sx={{ fontFamily: "crayond_regular", color: "#848484" }}
                    >
                      <span className={classes.issues}>
                        <CheckCircleIcon
                          sx={{ color: "#00B17E" }}
                          className={classes.aicon}
                        />
                        No issue
                      </span>
                      <span className={classes.issues}>
                        <HandymanOutlinedIcon
                          color="primary"
                          className={classes.aicon}
                        />
                        Incident
                      </span>
                      <span className={classes.issues}>
                        <AnnouncementIcon
                          sx={{ color: "#0063E7" }}
                          className={classes.aicon}
                        />
                        Notice
                      </span>
                      <span className={classes.issues}>
                        <ReportProblemIcon
                          sx={{ color: "#F9BB40" }}
                          className={classes.aicon}
                        />
                        Maintenance
                      </span>
                      <span className={classes.issues}>
                        <RemoveCircleIcon
                          sx={{ color: "#FF4141" }}
                          className={classes.aicon}
                        />
                        Outage
                      </span>
                    </Typography>
                  </Box>
                  <HealthList
                    project_id={project_id}
                    loading={
                      AllGetAllApplicationsParams[0]?.isLoading ?? loadingData
                    }
                    healthData={
                      AllGetAllApplicationsParams[0]?.isLoading ? [] : tableData
                    }
                    progressData={progress}
                  />
                </Box>
              ) : (
                <Box sx={{ paddingTop: "15px" }}>
                  {" "}
                  <Typography
                    variant="h6"
                    sx={{ textAlign: "start", padding: "0px 20px" }}
                  >
                    Applications
                  </Typography>
                  <InstanceApplication
                    onAddApplicationButtonClicked={
                      onAddApplicationButtonClicked
                    }
                  />
                </Box>
              )}
            </>
          )}
        </Box>
      </div>
    </>
  );
};
