export const styles = {
  root: {
    border: "1px solid #DFDFDF",
    borderRadius: "8px",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
  },

  child: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  label:{
    backgroundColor:"#0063E70A",
    padding:"10px",
    fontSize:"16px",
    fontWeight:"600",
    color:"#18283E",
    width:"100%",
    textAlign:"center"
  },
  icon:{
    position:"absolute",
    top:"8px",
    left:"8px",
    borderRadius:"6px",
    backgroundColor:"#EEF5FF",
    padding:"8px",
    minWidth:"38px",
    minHeight:"38px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
  }
};
