import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Typography,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import { InputField } from "../inputField";

const useStyles = makeStyles((theme) => ({
  border: {
    border: "1.3px solid #CBCBCB",
    padding: "12px",
    borderRadius: "8px",
  },
  InputField: {
    marginBottom: "4px",
  },
}));

export const ApplicationDetails = ({
  dataAlert = [],
  handleChange = () => {},
}) => {
  const classes = useStyles();
  return (
    <div className={classes.border}>
      {/* Title */}
      <Typography variant="subtitle2" gutterBottom>
        Application Details
      </Typography>

      {dataAlert.map((_, key) => {
        return (
          <div key={key} className={classes.report}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="subtitle2" sx={{ fontSize: "14px" }}>
                {_?.label}
              </Typography>
              <Tooltip
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontSize: "12px",
                      fontWeight: 400,
                      color: "#fff",
                      padding: "7px",
                      background: "#161515",
                      marginTop: "0px !important",
                    },
                  },
                  arrow: {
                    sx: {
                      color: "#000",
                    },
                  },
                }}
                title={_?.tooltip}
              >
                <IconButton>
                  <InfoOutlinedIcon sx={{ fontSize: "14px" }} />
                </IconButton>
              </Tooltip>
            </Box>
            <Box className={classes.InputField}>
              <InputField
                // label=''
                isPaddingRequired={false}
                isMulti={_?.isMulti}
                placeholder={_?.placeholder}
                fullWidth
                helperText={_?.helperText}
                isError={_?.isError}
                value={_?.value}
                type={_?.type}
                onChange={(e) => handleChange(_?.key, e.target.value)}
                select={_.select}
                selectOption={_?.selectOptions}
                // endAdornment={
                //   _?.loader ? <CircularProgress color="success" /> : " "
                // }
              />
            </Box>
          </div>
        );
      })}
    </div>
  );
};
