import React from "react";
import { styles } from "./styles";
import { Box, Typography } from "@mui/material";

const ReportCard = (props) => {
  const { width, height, icon, label, children } = props;
  return (
    <Box sx={{ ...styles.root, width: width, height: height }}>
      {icon && <Box sx={styles.icon}>{icon}</Box>}
      <Box sx={styles.child} flexGrow={1}>
        {children}
      </Box>
      <Typography sx={styles.label}>{label}</Typography>
    </Box>
  );
};

export default ReportCard;
