/* eslint-disable no-unused-vars */
import {
  FeatureWriter,
  copyFeature,
  jsonToFeature,
  saveFeatureFile,
} from "@crayond_dev/feature-writer";
import { ContentCopyOutlined, FileDownloadOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import LinkIcon from "@mui/icons-material/Link";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, Typography } from "@mui/material";
import { useDebounceFn } from "ahooks";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import isEqual from "react-fast-compare";
import { useNavigate, useParams } from "react-router-dom";
import { FeatureListing, TopNavBar } from "../../components";
import AlertDialog from "../../components/alertDialog";
import {
  useDeleteFeaturseListMutation,
  useEditFeaturesListMutation,
  useLazyGetAllFeatureQuery,
  useLazyGetFeatureWriterQuery,
  useLazyGetFeaturesListQuery,
  useLazyUploadFeatureListQuery,
} from "../../redux/services";
import { AppRoutes } from "../../router/routes";
import { bddFeature_Style } from "./style";

export const BddFeaturesReport = (props) => {
  const { className = "", ...rest } = props;
  let {
    linkText,
    project_id,
    linkTextTwo,
    secondarytext,
    instance_id,
    applicationid,
  } = useParams();

  // redux Hooks for API Data
  const [getFeaturesList] = useLazyGetFeaturesListQuery();
  const [editFeaturesList, ...editFeaturesParams] =
    useEditFeaturesListMutation();
  const [
    getFeatureWriter, // ...getFeatureParams
  ] = useLazyGetFeatureWriterQuery();
  const [deleteFeaturseList] = useDeleteFeaturseListMutation();
  const [uploadFeatureList] = useLazyUploadFeatureListQuery();
  const [getAllFeature] = useLazyGetAllFeatureQuery();

  // General Hooks
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [features, setFeatures] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectName, setSelectedName] = useState();
  const [unsaveObject, setUnSaveObject] = useState({});
  const [saveStatus, setSaveStatus] = useState({});
  const [isEdit, setIsEdit] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [featuresDBData, setFeaturesDBData] = useState([]);
  const [isLoading, setIsLoading] = useState({
    save: false,
    add: false,
  });

  // comment and uncomment feature loader
  const [featureCommentLoader, setFeatureCommentLoader] = useState(false);

  // loader for update feature to specif id
  const [updateId, setUpdateId] = useState(null);

// the state perform to comment and uncomment feature toast message
  const [updateFeatureName, setUpdateFeatureName] = useState("");

  const featureSnapshot = useRef();

  const application_ids = applicationid;
  const numberString = application_ids.match(/\d+/);
  const application_id = parseInt(numberString);

  const handleClose = () => {
    setOpen(false);
  };

  // OnBack func
  const handleClickOpen = () => {
    if (!saveStatus[features[selectedIndex]?.ID]) {
      let unsavedChangeIds = Object.keys(saveStatus).filter(
        (id) => !saveStatus[id]
      );
      let unsavedChangesObjects = features.filter((feature) =>
        unsavedChangeIds.some((id) => id === feature.ID.toString())
      );
      setUnSaveObject(unsavedChangesObjects);
      setOpen(true);
    } else {
      navigate(-1);
    }
  };

  // Text to file-Name
  function textToFileName(text) {
    return text?.toLowerCase().replace(/\s+/g, "");
  }

  // Using debounce to  save the changes !!!!
  const { run } = useDebounceFn(
    (updatedFeature) => {
      handleSaveAll(updatedFeature);
    },
    {
      wait: 1000,
    }
  );

  // Add Features
  const addFeature = async (addToNewFeature) => {
    let feature_id = features[selectedIndex]?.ID;
    if (feature_id && !saveStatus[feature_id]) {
      enqueueSnackbar(
        `Before Adding New Features. Edit And Save Your Current ${features[selectedIndex]?.name}!!`,
        {
          variant: "warning",
          anchorOrigin: { horizontal: "center", vertical: "top" },
        }
      );
      return;
    } else if (isEdit) {
      enqueueSnackbar(`Before Adding New.Save Your Current Features!!`, {
        variant: "warning",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
    } else {
      const newFeatureName = "New Feature";
      const newList = {
        name: newFeatureName,
        keyword: "Feature",
        description: "",
        scenarios: [],
      };
      const newIndex = features.length;

      let response = await onSaveFeature(
        newList,
        newIndex,
        newFeatureName,
        "add",
        addToNewFeature
      );
      if (response?.error?.data?.type === "error") {
        return enqueueSnackbar(`Error While adding Features !!!!`, {
          variant: "error",
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      } else {
        // setFeatures((prevFeatures) => [...prevFeatures, newList]);
        setIsEdit(null);
        setSelectedIndex(newIndex);
      }
    }
  };

  // Handle Changes For FeatureWriter
  function handleFeatures(featureslist) {
    if (!featureslist) return;
    const featuresCopy = JSON.parse(JSON.stringify(features));
    featuresCopy.forEach((feature, index, array) => {
      if (feature?.ID === featureslist.ID) {
        // array[index] = featureslist;
        array[index] = { ...featureslist, name: feature?.name };
      }
    });
    setFeatures(featuresCopy);
    setSelectedName(featureslist?.name);
  }

  // On List Changes
  const onFeatureChange = async (val, i) => {
    let feature_id = features[selectedIndex]?.ID;
    if (!saveStatus[feature_id]) {
      enqueueSnackbar(
        `Before Switching. Edit And Save Your Current ${features[selectedIndex]?.name}!!`,
        {
          variant: "warning",
          anchorOrigin: { horizontal: "center", vertical: "top" },
        }
      );
      return;
    } else {
      if (val?.ID) {
        // -------------------
        // await getFeatureData(val?.ID);
        setSelectedIndex(i);
      }
      setIsEdit(null);
    }
  };

  // On Save Features
  // last params using for only update the new feature file not upload file
  const onSaveFeature = async (val, index, name, key, addToNewFeature) => {
    if(key === 'updateComment'){
      setFeatureCommentLoader(true);
    }
    if(key === 'commented'){
      setFeatureCommentLoader(true);
    }
    if(key === 'uncommented'){
      setFeatureCommentLoader(true);
    }
    if(key === 'updated'){
      setFeatureCommentLoader(true);
    }
    if (key === "add") {
      setIsLoading({
        ...isLoading,
        add: !editFeaturesParams?.[0]?.isLoading,
      });
    }

    let featureListResponse;
    if (val?.ID) {
      featureListResponse = await editFeaturesList({
        application_id: application_id,
        id: val.ID,
        name: name || val.name,
      });
    } else {
      featureListResponse = await editFeaturesList({
        application_id: application_id,
        name: name || val.name,
        keyword: "Feature",
      });
    }
    if (featureListResponse?.data?.type === "success") {
      const updatedFeature = {
        ID: featureListResponse?.data?.feature_id,
        name: name || val?.name,
        keyword: "Feature",
      };
      setSaveStatus({ ...saveStatus, [updatedFeature?.ID]: true });
      // setFeatures((prevFeatures) => {
      //   const updatedFeatures = [...prevFeatures];
      //   const existingIndex = updatedFeatures.findIndex(
      //     (item) => item.ID === updatedFeature.ID
      //   );
      //   if (existingIndex !== -1) {
      //     updatedFeatures[existingIndex] = {
      //       ...updatedFeatures[existingIndex],
      //       ...updatedFeature,
      //     };
      //   } else {
      //     updatedFeatures[index] = {
      //       ...updatedFeatures[index],
      //       ...updatedFeature,
      //     };
      //   }
      //   return updatedFeatures;
      // });
      await getAllFeatures(application_id, true, index, addToNewFeature);
    }

    if (key === "add") {
      setIsLoading({
        ...isLoading,
        add: editFeaturesParams?.[0]?.isLoading,
      });
    }

    return featureListResponse;
  };

  // Features List
  const getFeatureList = async (id) => {
    let Lists = await getFeaturesList(application_id);
    if (Lists?.data?.data?.length) {
      setFeatures([...Lists?.data?.data]);
      let initialSaveStatus = Lists?.data?.data?.reduce((status, feature) => {
        return {
          ...status,
          [feature?.id]: true,
        };
      }, {});
      setSaveStatus(initialSaveStatus);
    }
    if (!Lists?.data?.data?.[0]?.id && !id) {
      addFeature(true);
    }
  };

  // Get Features
  const getFeatureData = async (id) => {
    let feature = await getFeatureWriter(id);
    setFeatures((prevFeatures) => {
      let updatedFeatures = [];
      if (prevFeatures.length > 0) {
        updatedFeatures = prevFeatures?.map((u) =>
          u.id !== feature?.data?.data?.ID
            ? { ...u, keyword: "Feature" }
            : {
                ...feature?.data?.data,
                id: feature?.data?.data?.ID,
                keyword:
                  feature?.data?.data?.keyword.length > 0
                    ? feature?.data?.data?.keyword
                    : "Feature",
              }
        );
      } else {
        updatedFeatures = feature?.data?.data
          ? [{ ...feature?.data?.data, id: feature?.data?.data?.ID }]
          : [];
        console.log("INSIDE");
      }
      if (
        updatedFeatures.length === 1 &&
        updatedFeatures[0].name !== "New Feature"
      ) {
        updatedFeatures[0].name = "New Feature";
      }
      return updatedFeatures;
    });
  };

  // OnDeleteFeatures
  const onDeleteFeature = (e, val, index) => {
    setOpenDelete(true);
  };

  // On close
  const onDeleteClose = () => {
    setOpenDelete(false);
  };

  // HandleDelete
  const handleDelete = (e) => {
    onDelete(e, features[selectedIndex], selectedIndex);
    setOpenDelete(false);
  };

  // OnDelete Feature
  const onDelete = async (e, val, index) => {
    e.stopPropagation();
    const id = val?.ID;
    if (!id) {
      if (index >= 0) {
        setFeatures((prevFeatures) =>
          prevFeatures.filter((item, i) => i !== index)
        );
        if (selectedIndex === index) {
          setSelectedIndex(0);
        } else if (selectedIndex > index) {
          setSelectedIndex(selectedIndex - 1);
        }
        enqueueSnackbar(`${val?.name} has been deleted`, {
          variant: "success",
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      }
    } else {
      const deleteResult = await deleteFeaturseList(id);
      if (deleteResult?.data?.type === "success") {
        enqueueSnackbar(`${val?.name} has been deleted`, {
          variant: "success",
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
        setFeatures((prevFeatures) =>
          prevFeatures.filter((item) => item.id !== id)
        );

        if (selectedIndex >= features.length - 1) {
          setSelectedIndex(features.length - 1);
        }
      }
      setSelectedIndex(0);
      await getAllFeatures(application_id);
    }
  };

  // On Save Changes
  const onSaveChanges = async (featureToUpdate) => {
    const feature = featureToUpdate ?? features[selectedIndex];
    setIsLoading({
      ...isLoading,
      save: !editFeaturesParams?.[0]?.isLoading,
    });
    // let result = await uploadFeatureList(`${textToFileName(feature?.name)}.feature`);
    if (feature?.ID) {
      // features into text format
      let featureAsText = jsonToFeature({
        ...feature,
        scenarios:feature?.scenarios ?? []
      });
      // once the upload file Api generate two url
      await uploadFeatureList(`${textToFileName(feature?.name)}.feature`)
        .then((result) => {
          const featureBlob = new Blob([featureAsText], {
            type: "text/plain;charset=utf-8",
          });
          let featuresDBDataCopy = JSON.parse(JSON.stringify(features));

          featuresDBDataCopy.forEach((feature, index, array) => {
            if (feature.ID) {
              array[index] = {
                ...feature,
                file_url: result?.data?.data?.file_s3_url,
              };
            }
          });
          setFeaturesDBData(featuresDBDataCopy);
          // And  Fetch into signed_url
          fetch(result?.data?.data?.signed_url, {
            method: "PUT",
            body: featureBlob,
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
              } else {
                const updatedFeature = featuresDBDataCopy.find(
                  (eachFeature) => eachFeature.ID === feature.ID
                );

                run({
                  ...updatedFeature,
                  name: feature?.name ?? updatedFeature?.name,
                });
              }
            })
            .catch((error) => {
              console.error("Error:", error);
              enqueueSnackbar(`${error?.message}`, {
                variant: "error",
                anchorOrigin: { horizontal: "center", vertical: "top" },
              });
              setIsLoading({
                ...isLoading,
                save: editFeaturesParams?.[0]?.isLoading,
              });
              setFeatureCommentLoader(false);
              setUpdateFeatureName("");
            });
        })
        .catch((err) => console.log(err));
    }
  };

  // finding the first char and using commend and uncomment toast message
  const startsWithHash = (string) => {
    if (string.charAt(0) === "@") {
      enqueueSnackbar(`${string} will be excluded from execution hereafter!`, {
        variant: "success",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
    } else {
      enqueueSnackbar(`${string} will be included in execution hereafter!`, {
        variant: "success",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
    }
  };

  // Handle save all
  const handleSaveAll = async (feature) => {
    // let arr = [];
    // Once all features has done , upsert the feature has done
    try {
      let payload = {
        // id: feature?.id,
        ID: feature?.ID,
        application_id: application_id,
        keyword: feature?.keyword || "Feature",
        name: feature?.name,
        description: feature?.description,
        scenarios: feature?.scenarios,
        file_url: feature?.file_url,
        tags: feature?.tags,
      };
      let upsertFeatures = await editFeaturesList(payload);
      if (upsertFeatures?.data?.type === "success") {
        feature.ID = upsertFeatures?.data?.feature_id;
        payload.id = feature.ID;
        const updatedFeatures = [...features];
        const existingFeatureIndex = updatedFeatures.findIndex(
          (feat) => feat?.ID === feature?.ID
        );
        if (existingFeatureIndex !== -1) {
          updatedFeatures[existingFeatureIndex] = feature;
        } else {
          updatedFeatures[selectedIndex] = feature;
        }
        setFeatures(updatedFeatures);
        if (upsertFeatures?.data?.type === "success") {
          if (updateFeatureName === 'updated') {
            enqueueSnackbar(`${feature?.name} Feature has been updated`, {
              variant: "success",
              anchorOrigin: { horizontal: "center", vertical: "top" },
            });
          } else if (updateFeatureName === 'commented') {
            enqueueSnackbar(`${feature?.name} will be excluded from execution hereafter!`, {
              variant: "success",
              anchorOrigin: { horizontal: "center", vertical: "top" },
            });
          }else if (updateFeatureName === 'uncommented') {
            enqueueSnackbar(`${feature?.name} will be included in execution hereafter!`, {
              variant: "success",
              anchorOrigin: { horizontal: "center", vertical: "top" },
            });
          }
          else if (featureCommentLoader) {
            startsWithHash(feature?.name);
          } else {
            enqueueSnackbar(`${feature?.name} Feature has been updated`, {
              variant: "success",
              anchorOrigin: { horizontal: "center", vertical: "top" },
            });
          }
        }
      } else if (upsertFeatures?.error?.data?.type === "error") {
        enqueueSnackbar(`Error While adding Features !!!!`, {
          variant: "error",
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      }

      // set comment and uncomment loader false
      setFeatureCommentLoader(false);
      setUpdateFeatureName("");

      setIsLoading({ ...isLoading, save: editFeaturesParams?.[0]?.isLoading });
      setSelectedIndex(selectedIndex);
      setSaveStatus({ ...saveStatus, [feature?.ID]: true });
      featureSnapshot.current = {
        id: feature?.ID,
        description: feature?.description,
        scenarios: feature?.scenarios,
        tags: feature?.tags,
      };
    } catch (error) {
      console.log(error, "error");
      setFeatureCommentLoader(false);
      setUpdateFeatureName("");
    }
  };

  // Copy features file
  const copyFeatureFile = () => {
    const val = features[selectedIndex];
    const temp = jsonToFeature(val);
    if (temp) {
      copyFeature(temp);
      enqueueSnackbar(`${val?.name} feature has been copied`, {
        variant: "success",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
    }
  };

  // Copy file
  const onCopyFile = () => {
    const fileURL = features[selectedIndex].file_url;
    navigator.clipboard.writeText(fileURL);
    enqueueSnackbar(`Feature File URL has been copied`, {
      variant: "success",
      anchorOrigin: { horizontal: "center", vertical: "top" },
    });
  };

  // Download file
  const downloadFeatureFile = () => {
    const val = features[selectedIndex];
    const temp = jsonToFeature(val);
    if (temp) {
      saveFeatureFile(temp, val?.name);
    }
  };

  // To Get All Features
  const getAllFeatures = async (application_id, updateS3Url, index, addToNewFeature) => {
    let getAll = await getAllFeature(application_id);
    if (getAll?.data?.data?.length) {
      const sortedArrayAsc = getAll?.data?.data.slice().sort((a, b) => {
        const dateA = new Date(a.CreatedAt);
        const dateB = new Date(b.CreatedAt);
        return dateA - dateB;
      });
      setFeatures(sortedArrayAsc);
      setFeaturesDBData(sortedArrayAsc);

      // update file upload
      if (updateS3Url) {
        const featureToUpdate = sortedArrayAsc.find(
          (feature, featureIndex) => featureIndex === index
        );

        // when add feature do not call file upload fun
        !addToNewFeature && onSaveChanges(featureToUpdate);
      }
    }
    return getAll;
  };

  // Initial Data Calls
  const initalData = async () => {
    await getFeatureList();
    await getAllFeatures(application_id);
    setSelectedIndex(0);
  };

  useEffect(() => {
    initalData();
  }, []);

  // on disable  and enable the save changes feature button
  useEffect(() => {
    const selectedFeature = features[selectedIndex];
    if (
      selectedFeature &&
      (!featureSnapshot?.current ||
        selectedFeature?.ID !== featureSnapshot?.current?.ID)
    ) {
      featureSnapshot.current = selectedFeature;
    }
  }, [selectedIndex]);

  // on disable  and enable the save changes feature button
  useEffect(() => {
    const selectedFeature = features[selectedIndex];

    if (
      selectedFeature &&
      featureSnapshot?.current &&
      Object.keys(featureSnapshot?.current).length > 0
    ) {
      let saved = isEqual(
        {
          description: featureSnapshot.current.description,
          scenarios: featureSnapshot.current.scenarios ?? [],
          tags: featureSnapshot.current?.tags ?? [],
        },
        {
          description: selectedFeature.description,
          scenarios: selectedFeature.scenarios,
          tags: selectedFeature.tags,
        }
      );
      setSaveStatus({ ...saveStatus, [selectedFeature?.ID]: saved });
    }
  }, [selectedIndex, features]);

  return (
    <>
      <TopNavBar
        showTopLeftNav={true}
        onChangeSaveButton={() => {
          onSaveChanges();
        }}
        isSaveButton={true}
        saveChangeLoader={isLoading?.save}
        saveButtonDisabled={
          !saveStatus[features[selectedIndex]?.ID] ? false : true
        }
        breadcrumbsLink={[
          {
            pathName: linkText,
            link: AppRoutes.projectInstancesParent + project_id,
            subText: "Instance",
          },
          {
            pathName: linkTextTwo,
            link:
              AppRoutes.projectInstanceDetailParent +
              project_id +
              "/" +
              instance_id +
              "/" +
              linkText +
              "/" +
              linkTextTwo,
            subText: "Application",
          },
        ]}
        title={secondarytext}
        subTitle={"BddFeatures"}
        bddUrl
        onbackScreenBtn={handleClickOpen}
        isBddFeatures
        onCopyfeatures={copyFeatureFile}
        onDownloadFeatures={downloadFeatureFile}
        onLinkcopyFeatures={onCopyFile}
      />

      <Box sx={bddFeature_Style.rootSx} className={`${className}`} {...rest}>
        <Box sx={bddFeature_Style.totalSx}>
          <Grid container justifyContent={"space-between"}>
            {/* featureListing */}
            <Grid
              item
              sx={{ ...bddFeature_Style.leftSideSx }}
              sm={12}
              md={4}
              lg={4}
              mt={2}
            >
              <Box
                sx={{
                  position: "sticky",
                  top: "95px",
                  height: "calc(100vh - 126px)",
                  overflow: "scroll",
                }}
              >
                {features &&
                  features?.map((val, index) => {
                    if (features?.ID || features) {
                      return (
                        <FeatureListing
                          selectName={val?.name}
                          index={index}
                          selectedIndex={selectedIndex}
                          setSelectedIndex={setSelectedIndex}
                          id={val?.ID}
                          value={features}
                          setFeatures={setFeatures}
                          featureName={val?.name}
                          setIsEdit={setIsEdit}
                          isEdit={isEdit}
                          // onEdit={(e) => onEdit(val,index)}
                          isSelected={selectedIndex === index && isEdit}
                          onDelete={(e) => onDeleteFeature(e, val, index)}
                          onListsClick={() => onFeatureChange(val, index)}
                          onSaveFeatures={(name, key, uniqId) => {
                            onSaveFeature(val, index, name, key);
                            // set comment and uncomment loader and toast message handling
                            setUpdateId(uniqId);
                            setUpdateFeatureName(key);
                          }}
                          isLoading={
                            updateId === val?.ID && featureCommentLoader
                          }
                        />
                      );
                    }
                    return null;
                  })}
                <Box sx={{ pt: 1, px: 3, textAlign: "start" }}>
                  <LoadingButton
                    sx={bddFeature_Style.addFeaturesSx}
                    onClick={() => addFeature(true)}
                    loading={isLoading?.add}
                  >
                    <AddIcon sx={{ fontSize: "14px" }} /> Add Feature
                  </LoadingButton>
                </Box>
              </Box>
            </Grid>
            {/* featureWriting */}
            <Grid
              sx={{ ...bddFeature_Style.rightSideSx }}
              sm={12}
              md={8}
              lg={8}
              mt={2}
            >
              <Box sx={bddFeature_Style.featureWriteSx}>
                {features?.length > 0 &&
                  features?.map((val, index) => {
                    if (index === selectedIndex) {
                      if (val?.scenarios) {
                        return (
                          <>
                            <FeatureWriter
                              key={val.id || val?.ID}
                              showFeatureName={false}
                              onFeatureChange={handleFeatures}
                              allowDownload={false}
                              allowCopy={false}
                              defaultFeature={{
                                ...val,
                                scenarios: val?.scenarios ?? [],
                              }}
                            />
                          </>
                        );
                      } else if (val?.scenarios === null) {
                        return (
                          <>
                            <FeatureWriter
                              key={val.id || val.ID}
                              onFeatureChange={handleFeatures}
                              showFeatureName={false}
                              allowDownload={false}
                              allowCopy={false}
                              defaultFeature={{
                                ...val,
                                scenarios: [],
                                keyword: "Feature",
                                name: val?.name ?? "New Feature",
                              }}
                            />
                          </>
                        );
                      }
                    }
                    return null;
                  })}
              </Box>
              <Box
                sx={{
                  pt: 2,
                  px: 2.25,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ ...bddFeature_Style.actionbtnSx }}>
                  <LoadingButton
                    sx={{
                      fontWeight: 500,
                      fontFamily: "crayond_regular",
                      textTransform: "capitalize",
                      mr: 1,
                      // mt: 1,
                    }}
                    size="small"
                    endIcon={<ContentCopyOutlined size="small" />}
                    variant="outlined"
                    onClick={() => {
                      copyFeatureFile();
                    }}
                  >
                    Copy Feature
                  </LoadingButton>

                  <LoadingButton
                    sx={{
                      fontWeight: 500,
                      fontFamily: "crayond_regular",
                      textTransform: "capitalize",
                      // mt: 1,
                      mr: 1,
                    }}
                    size="small"
                    endIcon={<FileDownloadOutlined size="small" />}
                    variant="outlined"
                    onClick={() => {
                      downloadFeatureFile();
                    }}
                  >
                    Download
                  </LoadingButton>
                  {/* {features[selectedIndex]?.file_url && ( */}
                  <LoadingButton
                    sx={{
                      fontWeight: 500,
                      fontFamily: "crayond_regular",
                      textTransform: "capitalize",
                      mr: 1,
                    }}
                    size="small"
                    endIcon={<LinkIcon size="small" />}
                    variant="outlined"
                    onClick={() => onCopyFile()}
                  >
                    Copy File
                  </LoadingButton>
                  {/* )} */}
                </Box>
                <LoadingButton
                  sx={{ ...bddFeature_Style.addFeaturesSx, mt: 1 }}
                  loading={isLoading?.save}
                  disabled={
                    !saveStatus[features[selectedIndex]?.ID] ? false : true
                  }
                  onClick={() => {
                    onSaveChanges();
                  }}
                >
                  Save Changes
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* Alert Dialog  */}
        <AlertDialog
          bodyContent={
            <>
              The changes made to the{" "}
              <Typography
                variant="body1"
                sx={{
                  display: "contents",
                  padding: "0px 4px",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                {" "}
                {unsaveObject[0]?.name}
              </Typography>{" "}
              have not been saved. Are you sure you want to close without
              saving?
            </>
          }
          handleClose={handleClose}
          open={open}
          closeBtn="No"
          accpetBtn="Yes"
          handleAccept={() => navigate(-1)}
          acceptSx={bddFeature_Style.acceptBtnSx}
          closesx={bddFeature_Style.closeBtnSx}
          paperProps={{
            style: {
              position: "absolute",
              top: "56px",
              width: "446px",
              padding: "16px 16px",
              backgroundColor: "rgb(255 255 255)",
              color: "#000",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
          }}
        />
        {/* Delete Features */}
        <AlertDialog
          bodyContent={
            <>
              Are you sure you want to delete this feature{" "}
              <Typography variant="body1" sx={bddFeature_Style.dialogContentSx}>
                {" "}
                {features[selectedIndex]?.name}
              </Typography>{" "}
              ?
            </>
          }
          handleClose={onDeleteClose}
          open={openDelete}
          closeBtn="Cancel"
          accpetBtn="Delete"
          acceptSx={bddFeature_Style.deleteBtnSx}
          closesx={bddFeature_Style.closeBtnSx}
          handleAccept={(e) => handleDelete(e)}
          paperProps={{
            style: {
              position: "absolute",
              top: "56px",
              width: "456px",
              padding: "16px 16px",
              backgroundColor: "rgb(255 255 255)",
              color: "#000",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
          }}
        />
      </Box>
    </>
  );
};
