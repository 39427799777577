import { Box, Typography } from "@mui/material";
import React from "react";
import { DefaulBugIcon, DefaultLockIcon } from "../../assets";
import { CricleIcon } from "../../assets/cricleIcon";
import { PathIcon } from "../../assets/fileIcon";
import { issuesStyle } from "../issues/style";
import { ListNested } from "../listNested";
import { Listloading } from "../listView/listLoading";
import { removeStyle } from "./styles";

export const RemoveComment = ({ handleClick = () => false, issuesList ,isLoading}) => {
  const IssuesList = ({ item }) => {
    return (
      <>
        <Box>
          <Box sx={removeStyle.pathContained}>
            <Box sx={issuesStyle.pathIcon}>
              <PathIcon />
            </Box>
            <Box>
              <Typography sx={issuesStyle.pathText}>{item?.component}</Typography>
            </Box>
          </Box>

          <Box sx={removeStyle.paperBlock}>
            <Box sx={removeStyle.flexBlock}>
              <Box>
                <Typography sx={removeStyle.typeText}>{item?.message}</Typography>
              </Box>
              <Box sx={removeStyle.centerBlock}>
                <Box sx={removeStyle.dotIcon}></Box>
                <Typography style={removeStyle.lineText}>L {item?.line}</Typography>
              </Box>
            </Box>

            <Box sx={removeStyle.centerBlockSX}>
              <Box sx={removeStyle.removeBottomBlock}>
                {item?.type === "BUG" ? <DefaulBugIcon /> : ""}
                {item?.type === "VULNERABILITY" ? <DefaultLockIcon /> : ""}
                {item?.type === "CODE_SMELL" ? <CricleIcon /> : ""}
                <ListNested
                  onClickNav={() => handleClick()}
                  sxButton={removeStyle.dropList}
                  titlePrimary={item?.type}
                  sxTitle={removeStyle.codeSmellText}
                />

                <Box sx={removeStyle.dotIcon}></Box>
              </Box>

              <Box sx={removeStyle.removeBottomBlock}>
                <ListNested
                  sxButton={removeStyle.dropList}
                  titlePrimary={item?.severity}
                  sxTitle={removeStyle.codeSmellText}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  }

  return (
    <>

      {
        issuesList?.map((item) => (

          isLoading ?
            <Box mb={1}>
              <Listloading />
            </Box>
            :
           <>
           <IssuesList item={item} />
           </>))


      }
    </>
  );
};
