export const bddFeature_Style = {
  rootSx: {
    position:'relative',
    marginTop:'12px',
  },
  featureWriteSx: {
    px: 2,
  },
  addFeaturesSx: {
    backgroundColor: '#3b82f6',
    color: "#fff",
    textTransform: "capitalize",
    fontSize: "12px",
    fontWeight: "500",
    fontFamily: "crayond_regular",
    // p: "4px 12px",
    lineHeight: '1rem',
    padding: "0.25rem 0.5rem",
    // columnGap: "0.5rem",
    "&:hover": {
      backgroundColor: "#3b82f6",
      color: "#fff",
    },
      borderRadius: '0.25rem',
  
      '& .MuiLoadingButton-loadingIndicator': {
        color: '#3b82f6',
      },
      '&.Mui-disabled':{
           color:'#fff',
           backgroundColor:'#eaeaea'
      }
     
  },
  closeSx: {
    position: "absolute",
    right: "8px",
    top: "-26px",
  },
  acceptBtnSx:{
    borderColor:'#3b82f6',
    color:'#6a6a6a',
    fontSize:'14px',
    fontWeight:'500',
    "&:hover": {
      backgroundColor: "#3b82f6",
      color:'#fff',
    }
  },closeBtnSx:{
    color:'#6a6a6a',
    fontSize:'14px',
    fontWeight:'500',
    "&:hover": {
      backgroundColor: "RGB(156,155,152)",
      color: "#fff",
    }
  },
  deleteBtnSx:{
    borderColor:'rgba(239,68,68)',
    color:'rgba(239,68,68)',
    fontSize:'14px',
    fontWeight:'500',
    "&:hover": {
      backgroundColor: "rgba(239,68,68)",
      borderColor:'rgba(239,68,68)',
      color:'#fff',
    }
  },
  dialogContentSx:
    {display: 'contents',padding:'0px 4px',fontSize:'14px', fontWeight: '600' },

    leftSideSx:{
      '@media (max-width: 900px) ': {
       width:'100%',
       margin:'12px auto'
        
      },
    },
    rightSideSx:{
      '@media (max-width: 900px) ': {
        width:'100%',
        margin:'5px auto'
         
       },
    }
  
};
