/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import { Button, Box, Typography } from "@mui/material";

import { toggleButton_style } from "./style";

export function ToggleButton(props) {
  const {
    className = "",
    onChange,
    disable= false,
    value,
    options = [],
    isError = false,
    errorMessage = "",
    ...rest
  } = props;
  
  const [active, setActive] = useState(value);

  useEffect(() => {
    // if (value !== active) {
      onChange(active);
    // }
     //eslint-disable-next-line
  }, [active]);

  useEffect(() => {
    setActive(value);
  }, [value]);

  return (
    <Box>
      <Box
        display="flex"
      
        sx={{ my: 0.5, border: "1px solid #E0E0E0",borderRadius:'0px' }}
        className={`${className}`}
        {...rest}
      >
        {options?.map((val) => (
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              gap: 0.5,
              ...(val.value === active
                ? toggleButton_style.activeSx
                : toggleButton_style.radioSx),
            }}
            onClick={() => {
              setActive(val.value);
            }}
          >
            <Button sx={{
                ...val.value === active
                  ? {...toggleButton_style.buttonactiveSx, backgroundColor: disable ? '#b4b4b4' : '#0061E3',
                  "&:hover": {
                    backgroundColor: disable ? '#b4b4b4' : '#0061E3',
                  },}
                  : toggleButton_style.buttoninactiveSx,
                }
              }
            >
              {val?.name && (
                <Typography
                  sx={{
                    fontSize: "12px",
                    ...(val.value === active
                      ? toggleButton_style.nameActiveSx
                      : toggleButton_style.nameInactiveSx),
                  }}
                >
                  {val.name}
                </Typography>
              )}
              {/* {val?.icon} */}
            </Button>
          </Box>
        ))}
      </Box>
      {isError && (
        <Typography sx={{ mt: 0.5 }} variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
}
