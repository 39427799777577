import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  features: [{
    "keyword": "Feature",
    "name": "Feature Name",
    "description": "",
    "scenarios": [],
    "id": 1675188136258
}],
};

const bddSlice = createSlice({
  name: 'bdd',
  initialState,
  reducers: {
    addFeatures : {
      reducer: (state, action) => {
        state.features=action.payload;
      },
      prepare: (features) => {
        let modFeatures=features.reduce((prev,curr)=>{
            let result=curr;
            if(!curr?.feature_ref_id){
                 result={
                ...curr,
                feature_ref_id:curr.id
            }
         }
        return [...prev,result]
        },[]);
        return { payload: modFeatures }
      },
    }
  },
});

export const {addFeatures} = bddSlice.actions;

export default bddSlice.reducer;

