
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Box, CircularProgress, Container, Grid, Typography } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import { FilterIcon } from "../../assets/filterIcon";
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from "@mui/styles";
import React,{useState} from 'react';
import { useLocation, useParams } from "react-router-dom";
import { AutocompleteCmp, ListView, ProgressBar, ToggleButton, TopNavBar } from '../../components';
import { useLazyDASTReportQuery, useLazyGetAllDastReportQuery } from "../../redux/services";
import { AppRoutes } from '../../router/routes';
import moment from 'moment';
import { closeBackdrop, openBackdrop } from '../../redux/slices/backdrop';
import { useDispatch } from 'react-redux';
import { html2pdf } from 'html2pdf.js';



const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        backgroundImage: "url('/images/jpg/signInSignUpBg.png')", height: "auto", backgroundSize: "cover", backgroundRepeat: "no-repeat"
    },
    instancebg: {
        textAlign: "center",
        backgroundImage: "url('/images/instance.svg')", height: "215px", backgroundSize: "cover"
    }, progressbar: {
        marginTop: "-100px",
    },
    container: {
        width: "100%",
        maxWidth: "75%"
    }, aicon: {
        fontSize: "17px",
        margin: "-5px 5px",
    },
    issues: {
        padding: "0px 6px"
    },
    parentDiv: {
        width: "100%",
        display: 'flex'
    },
    parentDiv1: {
        width: "30%",
        backgroundColor: "#e4f4f8"
    },
    parentDiv2: {
        width: "70%"
    },
    instanceContentHeadingsDiv: {
        display: 'flex',
        height: "50px",
        alignItems: 'center'
    },
    instanceContentHeadings: {
        whiteSpace: "nowrap",
        textAlign: 'left',
        marginLeft: "12px"
    },
    instanceContentDiv: {
        height: "50px",
        borderBottom: "1px solid #e3e3e3",
        alignItems: 'center',
        display: 'flex'
    },
    instanceContent: {
        whiteSpace: "nowrap",
        textAlign: 'left',
        textOverflow: "ellipsis",
        overflow: "hidden",
        marginLeft: "8px"
    },
    applicationSx: {
        fontSize: "16px",
        fontweight: "500",
        color: "#000",
        paddingTop: "12px",
        paddingLeft: "32px",
    },
    filterSx: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        maxWidth: "890px",
        margin: "0px auto",
        marginTop: "18px",
        border: "1px solid #eaeaea",
        borderRadius: "8px",
        padding: "12px 12px",
        backgroundColor:'#fff'
      },
      autocompeleteSx: {
        marginLeft: "8px",
        "& .MuiAutocomplete-option[data-focus='true']": {
          backgroundColor: "#000",
        },
        "& .MuiAutocomplete-option[data-focus='false'][aria-selected='true']": {
          backgroundColor: "transparent",
        },
        "& .MuiOutlinedInput-root": {
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
          paddingLeft: "14px !important",
          paddingRight: "28px !important",
          height: "36px!important",
          backgroundColor: "#fff",
          "&.MuiAutocomplete-option.Mui-focused": {
            backgroundColor: "#000",
          },
        },
      },
}))

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const DASTReport = (props) => {

    const classes = useStyles();
    const  location =useLocation();
    const dispatch = useDispatch();
    let { project_id,instance_id,application_id,linkText,linkTextTwo,linkTextThree,linkTextFour } = useParams();

    // Component States --

    const [DASTDATA, setDASTData] = useState(null);
    const [isLoading, setisLoading] = useState(true);
    // const [isLoadingPdf, setisLoadingPdf] = React.useState(true);
    const [allExpended, setAllExpended] = React.useState(false);
    const [currentDate, setCurrentDate] = useState(7);
    const [isError, setisError] =useState(false);
    const [DASTFilterValue, setDASTFilterValue] =useState(null);
    const [ACCDATA, setACCDATA] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [openFilter, setOpenFilter] = useState(false);
    const [expanded, setExpanded] = useState('panel1');
    const [instance_expanded, setInstanceExpanded] = React.useState('panel1');

    // REdux Hooks For API

    const [DASTReport, ...DASTReportParams] = useLazyDASTReportQuery();
    const [getAllDastReport,...getAllDastReportParams]=useLazyGetAllDastReportQuery();

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleChangeInstanceExpand = (panel) => (event, newExpanded) => {
        setInstanceExpanded(newExpanded ? panel : false);
    };

    const riskcode = DASTDATA?.site[0]?.alerts?.map((i) => { return i?.riskcode });
    
    const Informational_Medium_riskcode = riskcode?.filter((i) => { return i === "0" })?.length;

    const Low_riskcode = riskcode?.filter((i) => { return i === "1" })?.length;

    const Medium_riskcode = riskcode?.filter((i) => { return i === "2" })?.length;

    const high_riskcode = riskcode?.filter((i) => { return i === "3" })?.length;

    const onChangeBDD = (e, Newval) => {
        setDASTFilterValue(Newval);
    }

    const showBackdrop = () => {
      dispatch(openBackdrop( reportData ?"Collecting Dast Data.....  ": " Downloading Dast Data....."));
    };
  
    const hideBackdrop = () => {
      dispatch(closeBackdrop());
    };

    const getDASTData = async () => {
        let dataDAST = await DASTReport({ application_id: application_id })
        setDASTData(dataDAST?.data?.data)
        setisError(DASTReportParams[0]?.isError)
        setisLoading(DASTReportParams[0]?.isLoading)
        setOpenFilter(true);
    }

    const options = [
        {
          name: "7D",
          value: "7",
        },
        {
          name: "30D",
          value: "30",
        },
        {
          name: "3M",
          value: "90",
        },
        {
          name: "6M",
          value: "180",
        },
      ];
      
    const onStatusChange = (daysAgo) => {
        const date = moment().subtract(daysAgo, "days").format("YYYY-MM-DD");
        getAllDAstReport(date)
    };

    const fetchReportData = async (data) => {
      showBackdrop()
        try {
          const arrData = await Promise.all(
            data.map(async (val) => {
              if (!val?.report_url) return null;
              const response = await fetch(val.report_url);
              const data = await response.json();
    
              return data;
            })
          );
          setReportData(arrData);
          hideBackdrop()
          return arrData.filter(Boolean);
          
        } catch (error) {
          console.error(error);
          return [];
        }

      };

    const getAllDAstReport = async (date) => {
        let getAllSastReport=    await getAllDastReport({
          application_id: application_id,
          since: date ?? currentDate,
        });
        await fetchReportData(getAllSastReport?.data?.data);
        setisError(getAllDastReportParams[0]?.isError);
        setisLoading(getAllDastReportParams[0]?.isLoading);
        setOpenFilter(false);
    };

    const onPerformanceClick = (value, i) => {
        setDASTData(value)
        setOpenFilter(true);
    };

    const OnCloseClick = () => {
        setOpenFilter(false);
        setCurrentDate(7);
        getAllDAstReport(moment().subtract(7, "days").format("YYYY-MM-DD"));
    };

   function convertHtmlToPdf() {
        const element = document.getElementById('screen');
        const options = {
          margin: 10,
          filename: 'Performance_Test_Reports.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a2', orientation: 'portrait' },
        };
        setTimeout(()=>{
        html2pdf().set(options).from(element).save();
         setAllExpended(false)
        //  setisLoadingPdf(false)
         hideBackdrop()
      },1000)
    
    }
    
    const onClickDownload = async (element) => {
        // setisLoadingPdf(true)
        showBackdrop()
        setAllExpended(true)
        convertHtmlToPdf()
    };

    React.useEffect(() => {
        setACCDATA(DASTDATA?.site[0]?.alerts?.filter((i) => { if (DASTFilterValue === null) { return i } else { return i?.riskcode === DASTFilterValue?.value } }))
        // eslint-disable-next-line
    }, [DASTDATA?.site[0]?.alerts, DASTFilterValue, ACCDATA])

    React.useEffect(() => {
        if (location?.state?.reportFilter) {
            setCurrentDate(7);
            getAllDAstReport(moment().subtract(7, "days").format("YYYY-MM-DD"));
          } else {
            getDASTData();
          }
        // eslint-disable-next-line
    }, [application_id])

    return <>
        <TopNavBar
            isDownloadRefreshRequired={openFilter}
            showTopLeftNav={true}
            onDownload={onClickDownload}
            title={linkTextFour}
            subTitle={"Perfomance"}
            breadcrumbsLink={[
                {
                    pathName: linkText,
                    link: AppRoutes.projectInstancesParent + project_id,
                    subText:"Instance"
                },
                {
                    pathName: linkTextTwo,
                    link: AppRoutes.projectInstanceDetailParent + project_id + "/" + instance_id + "/" + linkText + "/" + linkTextTwo,
                    subText:"Application"
                },
                  {
                    pathName: linkTextThree,
                    link: AppRoutes.applications + project_id +"/" + instance_id  +"/"+ application_id +"/"+ linkText  +"/"+ linkTextTwo +"/"+ linkTextThree,
                    subText:"Testing Report"
                  },
              ]}
        />
        
        <div className={classes.root}>
            {(isError === false) && (isLoading === false) && (DASTDATA !== (null || undefined)) && <div>

                {/* <Typography className={classes.applicationSx}>
                    {location?.state?.linkTextFour}
                </Typography> */}

                {/* //Health-BG */}
                <Box className={classes.instancebg} sx={{ padding: "50px" }}></Box>

                {/* //Back */}
                 {location?.state?.reportFilter && openFilter && (  
                  <Box sx={{position:'relative'}}>
                          <Box sx={{
                            display: "flex",
                                alignItems: "center",
                                position:'absolute',
                                  cursor: "pointer",
                                  top:'26px',
                                  left:'24px'
                            }} onClick={OnCloseClick}>
                            <ArrowBackIosNewIcon sx={{ color: "#0063e7", fontSize: "14px", marginRight: "5px" }} fontSize="small" />
                            <Typography sx={{ color: "#0063e7", fontSize: "14px", fontWeight: "600" }} size="small"> Back To Dast Report List </Typography>
                         </Box> 
                    </Box>
                 )}

                {/* //ProgressBar */}
                <Box className={classes.progressbar} sx={{marginTop: location?.state?.reportFilter ? '-146px':"100px",}}>
                    {location?.state?.reportFilter && !openFilter &&  <Box className={classes.filterSx}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            sx={{ fontSize: "14px", fontWeight: "600", mr: "8px" }}
                          >
                            Filter
                          </Typography>
                          <FilterIcon />
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <ToggleButton disable={reportData?.length > 0 ? false : true} options={options} value={JSON.stringify(currentDate)} onChange={onStatusChange} />
                        
                        </Box>
                      </Box>
                    }
                
                    <Box sx={{paddingTop: '12px'}}> {!openFilter && reportData?.length > 0 && reportData?.map((val, index) => (
                	   <Grid sx={{ padding: "12px 26px" }} key={index} className={classes.listi}>
	                 	    <ListView data={val || {}} titleSx={{ padding: "5px 5px" }} images="/images/homeScreenLogo.png" title={moment(val?.fetchTime).format( "MMMM Do YYYY, h:mm a")} btnName={ "View Performance"} btnVarient="outlined" onBtnClick={()=> onPerformanceClick(val, index)} />
                     </Grid> ))} 
                     </Box>
                    {openFilter && ACCDATA && <Container className={classes.container} sx={{ width: "100%", maxWidth: "95%" }}>
                   
                        <Box sx={{ display: "flex" }}>
                            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 1, sm: 8, md: 12 }} sx={{ padding: "20px" }}>
                                <Grid item xs={2} sm={4} md={3}>
                                    <ProgressBar title={'Informational'} countBar={Informational_Medium_riskcode} type={"countBar"} />
                                </Grid>
                                <Grid item xs={2} sm={4} md={3}>
                                    <ProgressBar title={'Low risk'} countBar={Low_riskcode} type={"countBar"} />
                                </Grid>
                                <Grid item xs={2} sm={4} md={3}>
                                    <ProgressBar title={'Medium risk'} countBar={Medium_riskcode} type={"countBar"} />
                                </Grid>
                                <Grid item xs={2} sm={4} md={3}>
                                    <ProgressBar title={'high risk'} countBar={high_riskcode} type={"countBar"} />
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>}
                </Box>

                {/* / Dast Rpeort List */}
                  {ACCDATA?.length> 0 && (<Box sx={{ padding: '35px 35px' }}>
                    <>
                        {<div style={{ display: 'flex', width: "100%", alignItems: 'flex-end', justifyContent: 'end' }}>
                            <div style={{ padding: "20px" }}>
                                <AutocompleteCmp
                                    // multiple={true}
                                    limitTags={2}
                                    id={"BDD_filter"}
                                    value={DASTFilterValue}
                                    onChange={onChangeBDD}
                                    options={[{ label: "Informational", value: "0" }, { label: "Low risk", value: "1" }, { label: "Medium risk", value: "2" }, { label: "High risk", value: "3" }]}
                                    label={"Risk filter"}
                                    placeholder={"select filter"}
                                    width={"320px"}
                                />
                            </div>
                        </div>}
                        {ACCDATA?.length > 0 && ACCDATA?.map((item, index) => {

                            return <Accordion expanded={expanded === index || allExpended} onChange={handleChange(index)}>
                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                    <Typography>{item?.alert + " "}</Typography><Avatar sx={{ width: item?.instances?.length > 99 ? 24 : 20, height: item?.instances?.length > 99 ? 24 : 20 }} style={{ fontSize: item?.instances?.length > 99 ? "10px" : "13px", marginLeft: "5px", marginTop: "3px" }}>{item?.instances?.length}</Avatar>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {item?.instances?.map((instance, index) => {
                                        return <Accordion expanded={instance_expanded === index||allExpended} onChange={handleChangeInstanceExpand(index)}>
                                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                <Typography>{"Instance " + index}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>

                                                <div className={classes.parentDiv}>

                                                    <div className={classes.parentDiv1}>
                                                        <div className={classes.instanceContentHeadingsDiv}><Typography variant="subtitle2" className={classes.instanceContentHeadings}>{"Uri"}</Typography> </div>
                                                        <div className={classes.instanceContentHeadingsDiv}><Typography variant="subtitle2" className={classes.instanceContentHeadings}>{"Evidence"}</Typography> </div>
                                                        <div className={classes.instanceContentHeadingsDiv}><Typography variant="subtitle2" className={classes.instanceContentHeadings}>{"Otherinfo"}</Typography> </div>
                                                        <div className={classes.instanceContentHeadingsDiv}><Typography variant="subtitle2" className={classes.instanceContentHeadings}>{"Reference INFO"}</Typography> </div>
                                                        <div className={classes.instanceContentHeadingsDiv}><Typography variant="subtitle2" className={classes.instanceContentHeadings}>{"solution"}</Typography> </div>
                                                    </div>

                                                    <div className={classes.parentDiv2}>
                                                        <div className={classes.instanceContentDiv}><Typography variant="body2" className={classes.instanceContent}><a href={instance?.uri}>{instance?.uri}</a></Typography> </div>
                                                        <div className={classes.instanceContentDiv}><Tooltip title={instance?.evidence} placement="top"><Typography variant="body2" className={classes.instanceContent}>{instance?.evidence}</Typography></Tooltip> </div>
                                                        <div className={classes.instanceContentDiv}><Tooltip title={item?.otherinfo ? item?.otherinfo : "-"} placement="top"><Typography variant="body2" className={classes.instanceContent}>{item?.otherinfo ? item?.otherinfo : "-"}</Typography></Tooltip> </div>
                                                        <div className={classes.instanceContentDiv}><Tooltip title={item?.reference ? item?.reference : "-"} placement="top"><Typography variant="body2" className={classes.instanceContent}>{item?.reference ? item?.reference : "-"}</Typography></Tooltip> </div>
                                                        <div className={classes.instanceContentDiv}><Tooltip title={item?.solution ? item?.solution : "-"} placement="top"><Typography variant="body2" className={classes.instanceContent}>{item?.solution ? item?.solution : "-"}</Typography></Tooltip></div>
                                                    </div>

                                                </div>
                                            </AccordionDetails>
                                        </Accordion>

                                    })}
                                </AccordionDetails>
                            </Accordion>

                        })}
                    </>
                </Box>)}
            </div>}
        </div>

        {(isError === false) && (isLoading === true) && (DASTDATA === null) && <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "90%" }}><CircularProgress color="inherit" /></div>}
        {(isError === true) && (isLoading === false) && (DASTDATA === null) && <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "90%" }}>{"OOPS Something Went Wrong !"}</div>}
       
         {/* Empty State Handle */} 
        {isError === false &&
        isLoading === false &&
        ((DASTDATA === (null || undefined))|| (!DASTDATA?.length<0 ) || (location?.state?.reportFilter && reportData?.length === 0)) && <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "90%" }}>
        <div style={{ display: "flex",alignItems: "center",justifyContent: "center",height: "75",}}>
            <Box className={classes.emptyState}>
              <img src="/images/emptyState.svg" alt="emptyState" />
              <Typography className={classes.emptyTitle}>
                There is no Dast report to show.
              </Typography>
            </Box>
          </div>
          </div>}
       
    </>

}

