import { CricleIcon } from "../../assets/cricleIcon";
export const boxData = [
    {
      id: "1",
      boxText: "Remove this commented out code",
      icon: <CricleIcon />,
      subText: "Code Smell",
    },
    {
      id: "2",
  
      boxText: "Remove this unused import of 'RequireAuth'.",
      icon: <CricleIcon />,
      subText: "Code Smell",
    },
    {
      id: "3",
      boxText: "Rename this file to 'graphqlReq'",
      icon: <CricleIcon />,
      subText: "Code Smell",
    },
    {
      id: "4",
      boxText: "Remove this unused import of 'config'.",
      icon: <CricleIcon />,
      subText: "Code Smell",
    },{
      id: "5",
      boxText: "Remove this commented out code",
      icon: <CricleIcon />,
      subText: "Code Smell",
    },
    {
      id: "6",
  
      boxText: "Remove this unused import of 'RequireAuth'.",
      icon: <CricleIcon />,
      subText: "Code Smell",
    },
    {
      id: "7",
      boxText: "Rename this file to 'graphqlReq'",
      icon: <CricleIcon />,
      subText: "Code Smell",
    },
    {
      id: "8",
      boxText: "Remove this unused import of 'config'.",
      icon: <CricleIcon />,
      subText: "Code Smell",
    },
    {
      id: "9",
      boxText: "Remove this commented out code",
      icon: <CricleIcon />,
      subText: "Code Smell",
    },
    {
      id: "10",
  
      boxText: "Remove this unused import of 'RequireAuth'.",
      icon: <CricleIcon />,
      subText: "Code Smell",
    },
    {
      id: "11",
      boxText: "Rename this file to 'graphqlReq'",
      icon: <CricleIcon />,
      subText: "Code Smell",
    },
    {
      id: "12",
      boxText: "Remove this unused import of 'config'.",
      icon: <CricleIcon />,
      subText: "Code Smell",
    },
  ];
  