import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: config.api_url,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = localStorage.getItem(LocalStorageKeys.authToken);
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 });

export const bddFeaturesAPI = createApi({
  reducerPath: "bddFeaturesAPI",
  baseQuery: baseQueryWithRetry,
  tagTypes: ["Bdd"],
  endpoints: (builder) => ({
    getFeaturesList: builder.query({
      query: (application_id) => ({
        url: `bdd/feature/get/all/names?application_id=${application_id}&start=0&length=10`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),

    getFeatureWriter: builder.query({
      query: (feature_id) => ({
        url: `bdd/feature/get?feature_id=${feature_id}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),

    editFeaturesList: builder.mutation({
      query(body) {
        return {
          url: `bdd/feature/upsert`,
          method: "POST",
          body: body,
        };
      },
    }),

    uploadFeatureList: builder.query({
      query: (fileName) => ({
        url: `bdd/feature/upload/url?file_name=${fileName}`,
        method: "GET",
      }),
    }),

    deleteFeaturseList: builder.mutation({
      query(delete_Id) {
        return {
          url: `bdd/feature/delete`,
          method: "POST",
          body: {
            feature_id: delete_Id,
          },
        };
      },
    }),

    saveFeaturesList: builder.query({
      query: (payload) => ({
        // url: ``,
        method: "",
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),

    getAllFeature: builder.query({
      query: (applicationId) =>
        `bdd/feature/get/all?application_id=${applicationId}`,
      providesTags: ["Bdd"],
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useLazyGetFeaturesListQuery,
  useEditFeaturesListMutation,
  useDeleteFeaturseListMutation,
  useSaveFeaturesListQuery,
  useLazyUploadFeatureListQuery,
  useLazyGetFeatureWriterQuery,
  useLazyGetAllFeatureQuery,
} = bddFeaturesAPI;
