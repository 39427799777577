export const AppRoutes = {
  home: "/",
  login: "/login",
  signUp: "/signUp",
  forgotPassword: "/forgotpassword",
  projects: "/projects",
  projectInstanceDetailParent: "/project/instance/",
  projectInstanceDetail:
    "/project/instance/:project_id/:id/:linkText/:linkTextTwo",
  projectInstancesParent: "/project/instances/",
  projectInstances: "/project/instances/:project_id",
  applications: "/application/",
  application:
    "/application/:project_id/:instance_id/:application_id/:linkText/:linkTextTwo/:linkTextThree",
  applicationlog:
    "/applicationlog/:log_type/:project_id/:instance_id/:application_id/:linkText/:linkTextTwo/:linkTextThree/:linkTextFour",
  DASTReport:
    "/DAST_Report/:project_id/:instance_id/:application_id/:linkText/:linkTextTwo/:linkTextThree/:linkTextFour",
  BDDReport:
    "/BDD_Report/:project_id/:instance_id/:application_id/:linkText/:linkTextTwo/:linkTextThree/:linkTextFour",
  SASTReport:
    "/SAST_Report/:project_id/:instance_id/:application_id/:linkText/:linkTextTwo/:linkTextThree/:linkTextFour",
  Performance_Test_Report:
    "/Performance_Test_Report/:project_id/:instance_id/:application_id/:linkText/:linkTextTwo/:linkTextThree/:linkTextFour",
  Performance_Test_FilterReport:
    "/Performance_Test_Report/:project_id/:instance_id/:application_id/",
  activity_log:
    "/activity_log/:project_id/:instance_id/:linkText/:linkTextTwo/:applicationName/:application_id",
  activity_logs: "/activity_log",
  bddFeatures:'/bddFeatures/:project_id/:instance_id/:applicationid/:linkText/:linkTextTwo/:secondarytext'
};
