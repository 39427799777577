import { Box, Typography } from "@mui/material";
import { styles } from "./styles";
import Progress from "./progress";

export const ProgressWidgetVertical = (props) => {
  const { title} = props;
  return (
    <Box sx={styles.rootSx}>
      <Box sx={styles.mainBlockSx}>
        <Box>
          <Typography sx={styles.titleSx}>{title}</Typography>
        </Box>
        <Progress {...props} symbol />
      </Box>
    </Box>
  );
};

ProgressWidgetVertical.defaultProps = {
  title: "title",
  progress: 0,
  sx: "",
  size: 42,
  thickness: 3,
};
