export const bddReportStyle={
    root: {
        // textAlign: "center",
        backgroundImage: "url('/images/jpg/signInSignUpBg.png')",
        height: "100vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingTop: "70px",
      },
      parentDiv: {
        width: "100%",
        display: "flex",
      },
      parentDiv1: {
        width: "30%",
        backgroundColor: "#e4f4f8",
      },
      parentDiv2: {
        width: "70%",
      },
      instanceContentHeadingsDiv: {
        display: "flex",
        height: "50px",
        alignItems: "center",
      },
      instanceContentHeadings: {
        whiteSpace: "nowrap",
        textAlign: "left",
        marginLeft: "12px",
      },
      instanceContentDiv: {
        height: "50px",
        borderBottom: "1px solid #e3e3e3",
        alignItems: "center",
        display: "flex",
      },
      instanceContent: {
        whiteSpace: "nowrap",
        textAlign: "left",
        textOverflow: "ellipsis",
        overflow: "hidden",
        marginLeft: "8px",
      },
      gridContainer: {
        width: "100%",
        justifyContent:'center'
      },
      featureGrid: {
        padding: "14px",
      },
      featurePaper: {
        height: "340px",
        position:'relative',
        boxShadow:" 0px 0px 3px 0px #e0e0e0",
        border: '2px solid #f5f9fe',
        
      },
      featureDiv: {
        paddingLeft: "6px",
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        backgroundColor: '#f5f9fe',
      

      },
      featureTypo: {
        color: "#18283E",
        opacity: 1,
        fontWeight: 600,
      },
      featureCircDiv: {
        height: "180px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

      },
      scenarioGrid: {
        padding: "14px",
      },
      scenarioPaper: {
        height: "340px",
        position:'relative',
        boxShadow:" 0px 0px 3px 0px #e0e0e0",
        border: '2px solid #f5f9fe',
      },
      scenarioDiv: {
        paddingLeft: "6px",
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        backgroundColor: '#f5f9fe',
      
      },
      scenarioTypo: {
        color: "#18283E",
        opacity: 1,
        fontWeight: 600,
      },
      scenarioCircDiv: {
        height: "180px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      metaGrid: {
        padding: "14px",
      },
      metaPaper: {
        height: "230px",
      },
      metaDiv: {
        paddingLeft: "6px",
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        background: "#0063E70A 0% 0% no-repeat padding-box",
      },
      metaTypo: {
        color: "#18283E",
        opacity: 1,
        fontWeight: 600,
      },
      metaContParentDiv: {
        height: "180px",
        width: "100%",
      },
      metaContParentSubDiv1: {
        height: "90px",
        width: "100%",
        display: "flex",
      },
      metaContAppDiv: {
        height: "89px",
        width: "33.3%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingLeft: "12px",
      },
      metaContApVeTypo: {
        paddingBottom: "2px",
      },
      metaContApVeVrTypo: {
        paddingTop: "2px",
        color: "#18283E",
        opacity: 1,
        fontWeight: 600,
      },
      metaContBrowserDiv: {
        height: "89px",
        width: "33.3%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingLeft: "12px",
      },
      metaContBrowserTypo: {
        paddingBottom: "2px",
      },
      metaContBrowserVr: {
        paddingTop: "2px",
        color: "#18283E",
        opacity: 1,
        fontWeight: 600,
      },
      metaContScenariosDiv: {
        height: "89px",
        width: "33.3%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingLeft: "12px",
      },
      metaContParaTypo: {
        paddingBottom: "2px",
      },
      metaContScenrTypo: {
        paddingTop: "2px",
        color: "#18283E",
        opacity: 1,
        fontWeight: 600,
      },
      metaContParentSubDiv2: {
        height: "90px",
        width: "100%",
        display: "flex",
      },
      metaContStagingDiv: {
        height: "89px",
        width: "33.3%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingLeft: "12px",
      },
      metaContTeTypo: {
        paddingBottom: "2px",
      },
      metaContStagTypo: {
        paddingTop: "2px",
        color: "#18283E",
        opacity: 1,
        fontWeight: 600,
      },
      metaContPlatDiv: {
        height: "89px",
        width: "33.3%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingLeft: "12px",
      },
      metaContPlatTypo: {
        paddingBottom: "2px",
      },
      metaContPlatOsTypo: {
        paddingTop: "2px",
        color: "#18283E",
        opacity: 1,
        fontWeight: 600,
      },
      metaContExeDiv: {
        height: "89px",
        width: "33.3%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingLeft: "12px",
      },
      metaContExeTypo: {
        paddingBottom: "2px",
      },
      metaContRemoTypo: {
        paddingTop: "2px",
        color: "#18283E",
        opacity: 1,
        fontWeight: 600,
      },
      bddAcord: {
        padding: "14px",
      },
      applicationSx: {
        fontSize: "16px",
        fontweight: "500",
        color: "#000",
        paddingTop: "12px",
        paddingLeft: "32px",
      },
      chatjs: {
        width: "100%",
        height: "148px",
      },
      featuresHead:{
        fontSize:'16px',
        fontWeight:'600',
        py:2
      },
      featuresSub:{
        fontSize:'12px',
        fontWeight:'600',
        display:'flex',
        alignItems:'center',
      },
      totalTitleSx:{
        position:'absolute',
        right:'30px',
        zIndex:'1',
        margin:'14px 0px'

      },
      circleSx:{
        pr:0.7,
        fontSize:"18px"
      },
      seneiorsSx:{
        width: '98%', 
        overflowWrap: 'break-word' ,
      },
      noSx:{
        display:'flex',
        justifyContent:'center',
        alignItems:"center",
        height:'49vh'
      },
      errorTest:{
        padding:'16px',  borderRadius:'4px', mb:'1rem', mt:'0.5rem', background:'#fbfbfb', border:'1px solid #ccc', '&:hover':{
          background:'#f0f0f0'
        }
      }
}