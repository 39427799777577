import React from "react";
import { SignUp } from './signUp';

class SignUpParent extends React.Component {
    render() {
        return <SignUp />;
    }
}

export default SignUpParent;
