import React from "react";
import { Projects } from './projects';


class ProjectsParent extends React.Component {
  render() {
    return <Projects />
  }
}

export default ProjectsParent;
