import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: config.api_url,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = localStorage.getItem(LocalStorageKeys.authToken);
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 1 });

export const reportAPI = createApi({
  reducerPath: "reportAPI",
  baseQuery: baseQueryWithRetry,
  tagTypes: ["reports"],
  endpoints: (builder) => ({
    // Performance Test Report API
    PerformanceTestReport: builder.query({
      query: (payload) => ({
        url: `application/report/get/performance?applicationId=${payload?.application_id}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),
    GetAllPerformanceTestReport: builder.query({
      query: (payload) => ({
        url: `application/report/performance/get/all?application_id=${payload?.application_id}&since=${payload?.since}&start=0&length=50`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),

    // SAST Report API
    SASTReport: builder.query({
      query: (payload) => ({
        url: `application/report/get/codequality?applicationId=${payload?.application_id}${payload?.since ? `&since=${payload.since}` : ''}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),

    // SAST Get All Report
    GetAllSASTReport:builder.query({
      query: (payload) => ({
        url: `application/report/get/codequality/history?applicationId=${payload?.application_id}&page=${payload?.start}&page_size=${payload?.end}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),

    // SAST ChartReport API
    SASTChartReport: builder.query({
      query: (payload) => ({
        url: `application/report/get/codequality/activity?applicationId=${payload?.application_id}&from_date=${payload?.from_date}&length=10`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),
     // SAST Issues API
     SASTIssues: builder.query({
      query: (payload) => ({
        url: `application/report/get/codequality/issue?applicationId=${payload?.application_id}&issue_type=${payload?.type }&severity=${payload?.serverty ? payload?.serverty : '' }${payload?.since ? `&since=${payload.since}` : ''}&page=${payload?.start ? payload?.start : 1}&page_size=${payload?.end ? payload?.end : 20}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),

    // SAST IssuesDummy API
    SASTIssuesTypes: builder.query({
        query: (payload) => ({
          // url: `application/report/get/codequality/issue?applicationId=${payload?.application_id}${payload?.type}`,
           url : `application/report/get/codequality/issue?applicationId=${payload?.application_id}${payload?.since ? `&since=${payload.since}` : ''}`,
          method: "GET",
        }),
        transformResponse: (response, meta, arg) => {
          // debugger
          return response;
        },
    }),

    // DAST Report API
    DASTReport: builder.query({
      query: (payload) => ({
        url: `application/report/get/security?applicationId=${payload?.application_id}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),
    getAllDastReport :builder.query({
      query: (payload) => ({
        url: `/application/report/dast/get/all?application_id=${payload?.application_id}&since=${payload?.since}&start=0&length=50`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),

    // BDD Report
    BDDReport: builder.query({
      query: (application_id) =>
        `application/report/get/bdd?applicationId=${application_id}`,
    }),

    // BDD Run
    runBDD: builder.query({
      query: (application_id) =>
        `application/report/bdd/scan?application_id=${application_id}`,
    }),
    // Errorlog Report API
    ErrorLog: builder.query({
      query: (payload) => ({
        url: `application/get/logs?log_type=error&log_lines=100&applicationId=${payload?.application_id}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),

    // Error Download API
    ErrorDownloadLog: builder.query({
      query: (payload) => ({
        url: `application/get/logs?log_type=error&log_lines=500&applicationId=${payload?.application_id}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),
    // Accesslog Report API
    AccessLog: builder.query({
      query: (payload) => ({
        url: `application/get/logs?log_type=access&log_lines=100&applicationId=${payload?.application_id}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),
    // Accesslog Download API
    AccessDownloadLog: builder.query({
      query: (payload) => ({
        url: `application/get/logs?log_type=access&log_lines=500&applicationId=${payload?.application_id}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response;

      },
    }),
    // Outputlog Report API
    OutputLog: builder.query({
      query: (payload) => ({
        url: `application/get/logs?log_type=output&log_lines=100&applicationId=${payload?.application_id}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),
    // Outputlog Download API
    OutputDownloadLog: builder.query({
      query: (payload) => ({
        url: `application/get/logs?log_type=output&log_lines=500&applicationId=${payload?.application_id}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useLazyPerformanceTestReportQuery,
  useLazyGetAllPerformanceTestReportQuery,
  useBDDReportQuery,
  useLazyRunBDDQuery,
  useSASTReportQuery,
  useLazyGetAllSASTReportQuery,
  useLazySASTReportQuery,
  useSASTChartReportQuery,
  useLazySASTChartReportQuery,
  useSASTIssuesQuery,
  useLazySASTIssuesQuery,
  useSASTIssuesTypesQuery,
  useLazySASTIssuesTypesQuery,
  useDASTReportQuery,
  useLazyDASTReportQuery,
  useErrorLogQuery,
  useLazyAccessLogQuery,
  useLazyAccessDownloadLogQuery,
  useLazyErrorDownloadLogQuery,
  useLazyOutputDownloadLogQuery,
  useLazyErrorLogQuery,
  useLazyOutputLogQuery,
  useLazyGetAllDastReportQuery,
} = reportAPI;
