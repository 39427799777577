import React from 'react';
import { ToggleButton, ToggleButtonGroup, Box, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import SearchField from '../searchField';
import { Views } from './utils';

const styles = makeStyles((theme) => ({
    subnav: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: "87px", paddingLeft: "20px", paddingRight: "20px",
        [theme.breakpoints.down('sm')]: {
            display: "block",
        }
    },
    gridlisticon: {
        padding: " 0px 15px",
        [theme.breakpoints.down('sm')]: {
            padding: "10px 0px",
        }
    },
    iconm: {
        padding: "6px 6px",
        boxShadow: "rgb(0 0 0 / 10%) 0px 4px 12px",
        border: "1px solid #c9c9c9"
    },
    search: {
        display: "flex",
        justifyContent: "space-between",
        color: "#000",
        [theme.breakpoints.down('sm')]: {
            display: "block",
        }
    },

}));

export const NavTitle = ({ title = "", search = "", setSearch = () => { }, handleView = () => false, view = "" }) => {

    const classes = styles();

    const handleChange = (nextView) => {
        handleView(nextView)
    };

    return (
        <div>
            <Box className={classes.subnav}>

                {/* title */}
                <Box>
                    <Typography variant="h6" sx={{ fontFamily: "crayond_medium" }}>{title} </Typography>
                </Box>
                {
                /* Grid-addprojects */}
                <Box className={classes.search}>
                    <SearchField search={search} onSearch={setSearch} />

                    {/* Grid-listview */}
                    <div className={classes.gridlisticon}>
                        <ToggleButtonGroup
                            orientation="horizontal"
                            value={view}
                            exclusive
                        >
                            {Views.map((_) => <ToggleButton onClick={(e) => handleChange(_.value)} className={classes.iconm} value={_.value}>
                                {_.icon}
                            </ToggleButton>)}

                        </ToggleButtonGroup>
                    </div>

                    {/* addprojects-button */}
                    {/* <Button variant="contained" color='primary' sx={{fontFamily:"crayond_medium",fontSize:"14px"}} onClick={addprojects}>
                        add
                    </Button> */}
                </Box>
            </Box>
        </div >
    )
}
