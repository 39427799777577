import React from "react";
import { DASTReport } from "./dastReport";

class DASTReportParent extends React.Component {
    render() {
        return <DASTReport />
    }
}

export default DASTReportParent;
