import React from "react";
import { Dashboard } from "./dashboard";

class DashboardParent extends React.Component {
    render() {
        return <Dashboard/>;
    }
}

export default DashboardParent;
