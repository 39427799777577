export  const Sast_styles = {
    root: {
        textAlign: "center",
        backgroundImage: "url('/images/jpg/signInSignUpBg.png')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingTop:'28px',
        height: "100%",
        "& .MuiTabPanel-root": {
          padding: "24px",
        },
      },
      outSX: {
        //  background: "#F6F6F6 0% 0% no-repeat padding-box",
        padding: {
          xs: "10px 14px 10px 14px",
          sm: "10px 14px 10px 14px",
          md: "34px 40px 34px 40px",
          lg: "34px 40px 34px 40px",
        },
      },
      typeSx: {
        fontSize: "14px",
        color: "black",
        textTransform: "capitalize",
        font: "normal normal medium 16px/25px Poppins",
        fontWeight: "600",
      },
      iconSx: {
        color: "#848484",
        marginRight: "-4px",
        fontSize: "1.3rem",
      },
      iconHomeSx: {
        color: "#848484",
      },
      filterSx: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        maxWidth: "890px",
        margin: "0px auto",
        border: "1px solid #eaeaea",
        borderRadius: "8px",
        padding: "12px 12px",
        backgroundColor:'#fff'
      },
    //   skeletonLoader:{margin:'10px 10px'}
    loaderSx: {
        padding: "10px 10px",
        justifyContent: { xs: "center", md: "space-between", lg: "space-between" },
      },
}