import React, { createContext, useState, useEffect } from 'react';
import { config } from "../config";

const WebSocketContext = createContext();

const WebSocketProvider = (props) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const ws = new WebSocket(`${config.websocket_url}${localStorage.getItem('auth_token')}`);
    setSocket(ws);
  }, []);

  return (
    <WebSocketContext.Provider value={socket}>
      {props.children}
    </WebSocketContext.Provider>
  );
};

export { WebSocketContext, WebSocketProvider };