import {
  Button,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { DefaulBugIcon, DefaultLockIcon } from "../../assets";
import { CricleIcon } from "../../assets/cricleIcon";
import { ListNested } from "../listNested";
import { listStyle } from "../listNested/style";
import { filterStyle } from "./style";


const Types = ({ issuesData, setRequestType, onFilterChange=()=> false,setIsLoading }) => {
  const [selected, setSelected] = React.useState("");

  // For onChange the Type
  const handleGetTypeIssue = (item) => {
      setRequestType(item?.val);
      setIsLoading(true);
      onFilterChange(item)
      setSelected(item.val);
  }

  return (
    <List component="div" disablePadding>
      {issuesData?.data?.facets[1].values.map((item, key) => (
        <ListItemButton sx={{...filterStyle.typeSx, pl: 4, backgroundColor: selected === item?.val ? '#e0e0e0' : 'transperant',color:selected === item?.val ? '#000' : 'transperant', }} onClick={() => handleGetTypeIssue(item)}>
          <ListItemIcon sx={filterStyle.itemText}>
            {item?.val === "BUG" ? <DefaulBugIcon /> : ""}
            {item?.val === "VULNERABILITY" ? <DefaultLockIcon /> : ""}
            {item?.val === "CODE_SMELL" ? <CricleIcon /> : ""}
          </ListItemIcon>
          <ListItemText sx={filterStyle.itemText} primary={item?.val} />
          <Typography
            sx={filterStyle.itemText}
            justifySelf={"self-end"}
            color={selected === item?.val ? '#000' : "#848484"}>
            {item?.count}
          </Typography>
        </ListItemButton>
      ))}
    </List>
  );
};

const Severitys = ({ handleSelectedSeveritys = () => { }, typeFilter,setIsLoading }) => {
  const [active, setActive] = React.useState(false);
  const [selected, setSelected] = React.useState("");

  const handleActive = (item) => {
    setActive(!active);
    setSelected(item.val);
  }

  // For onChange the  severity type
  const handleSelectedSeverity =(item)=>{
    setIsLoading(true)
    handleSelectedSeveritys(item)
  }
  return (
    <Grid container justifyContent={"space-between"} p={1} mt={1} spacing={1}>
      {typeFilter?.data?.facets[0]?.values?.map((item) => (
        <Grid item xs={12} md={12} lg={6}>
          <Box onClick={() => handleSelectedSeverity(item)}>
            <Box sx={
              selected === item?.val ? listStyle.activeCard : listStyle.childCard
            } onClick={() => handleActive(item)}>
              <Typography color={"#3D4755"} style={listStyle.cardTitle}>
                {item?.val}
              </Typography>
              <Typography color={
                (item?.val === "MAJOR" && "#BF0F9C") ||
                (item.val === "BLOCKER" && "#DC831E") ||
                (item.val === "CRITICAL" && "#BD1919") ||
                (item.val === "MINOR" && "#19BD50") ||
                (item.val === "INFO" && "#0063E7")
              } style={listStyle.cardCount}>
                {item?.count}
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export const Filter = ({ setRequestType, typeFilter, onFilterChange=()=>false, issuesData, handleSelectedSeveritys, setIsLoading }) => {
  return (
    <>
      <Box sx={filterStyle.mailBox}>
        <Box sx={filterStyle.headHorder}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item>
              <Typography style={filterStyle.filterText}>Filters</Typography>
            </Grid>
            <Grid item>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  disabled
                  size="small"
                  sx={{ textTransform: "none", fontSize: "12px" }}
                >
                  My Issues
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ textTransform: "none", fontSize: "12px" }}
                >
                  All
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Box>
            <ListNested
              titlePrimary={"Type "}
              option={<Types onFilterChange={onFilterChange} issuesData={issuesData} setRequestType={setRequestType} setIsLoading={setIsLoading} />}
              sxTitle={filterStyle.tpeTxt}
            />
          </Box>
          <Box>
            <ListNested
              titlePrimary={"Severity"}
              sxTitle={filterStyle.tpeTxt}
              option={<Severitys handleSelectedSeveritys={handleSelectedSeveritys} typeFilter={typeFilter} setIsLoading={setIsLoading}  issuesData={issuesData} />}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
