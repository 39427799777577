export const HashIcon = (props) => {
    // const {width, height, color} = props;
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        stroke={'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        className="lucide lucide-hash"
        viewBox="0 0 24 24"
        {...props}
      >
        <path d="M4 9h16M4 15h16M10 3 8 21M16 3l-2 18" />
      </svg>
    )
}
