import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React from "react";
import { useWindowDimensions } from "../../hooks";
import { Filter } from "../filters";
import { RemoveComment } from "../removeComment";
import { issuesStyle } from "./style";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton, TablePagination, Typography } from "@mui/material";
import { InputField } from "../inputField";

export const Issues = ({
  handleClick = () => {},
  onChangeRow = () => false,
  handleChangeRowsPerPage = () => false,
  rowsPerPage,
  count,
  currentPage,
  filterSastType,
  onPageFun = () => false,
  issuesData,
  onSeverityChange = () => false,
  onFilterChange = () => false,
  issuesTypeData,
  screen,
  allExpended,
  setRequestType,
  typeFilter,
}) => {
  // const [issuesList, setIssuesList] = React.useState([]);

  const [isLoading, setIsLoading] = React.useState(true);

  const size = useWindowDimensions();

  let sastTypeData = typeFilter
    ? typeFilter?.data?.issues
    : issuesData?.data?.issues;

  const handleSelectedSeveritys = (item) => {
    if (item?.val) {
      let serverity = item?.val;
      onSeverityChange(serverity);
    }
  };

  const onPageBtn = (event, newPage) => {
    onPageFun(event, newPage);
  };

  const onChangeFilter = (item) => {
    onFilterChange(item);
  };


  // React.useEffect(() => {
  //   setIssuesList(issuesTypeData?.data?.issues);
  // }, [issuesTypeData])

  setTimeout(() => {
    setIsLoading(false);
  }, 600);

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <Box
            sx={{
              ...issuesStyle.paperBlock,
              height: size?.height - 130,
              overflow: "overlay",
            }}
          >
            <Filter
              onFilterChange={onChangeFilter}
              handleSelectedSeveritys={handleSelectedSeveritys}
              issuesData={issuesData}
              typeFilter={typeFilter}
              issuesTypeData={issuesTypeData}
              setRequestType={setRequestType}
              setIsLoading={setIsLoading}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={8} lg={9}>
          <Box
            sx={{
              ...issuesStyle.rootBlock,
              height: allExpended ? "none" : size?.height - 128,
              overflow: allExpended ? "none" : "overlay",
            }}
          >
            <Box id={screen} allExpended={allExpended}>
              <RemoveComment
                handleClick={handleClick}
                issuesData={issuesData}
                issuesList={sastTypeData}
                isLoading={isLoading}
              />

              {(typeFilter?.data?.issues?.length === 0 || typeFilter?.data?.issues === undefined) && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "75%",
                  }}
                >
                  <Box className={issuesStyle.emptyState}>
                    <img src="/images/emptyState.svg" alt="emptyState" />
                    <Typography className={issuesStyle.emptyTitle}>
                      There is no Data
                    </Typography>
                  </Box>
                </div>
              )}

              {/* {typeFilter?.data?.issues?.length === 0 && ( */}
              <Box spacing={2} sx={{ ...issuesStyle.totalBox }}>
                <Box sx={{ ...issuesStyle?.pagesBox }}>
                  <Typography sx={{ ...issuesStyle.paginationTitle, mr: 1 }}>
                    pages
                  </Typography>
                  <Box
                    sx={{
                     ...issuesStyle.totalPaginationSx
                    }}
                  >
                    <InputField
                      fullWidth
                      isLabelReqiured={false}
                      type="number"
                      value={currentPage}
                      sx={{
                        ...issuesStyle.rowpage,

                        "& .MuiOutlinedInput-root": {
                          borderRadius: "8px",
                        },
                      }}
                      onChange={(e) => onChangeRow(e, "page")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                     <Box
                          sx={{
                            ...issuesStyle.totalArrowSx,
                            // left: currentPage?.length > 2 ? "74px" : "33px",
                          }}
                        >
                          <IconButton
                            sx={{ p: "0", height: "0px", marginTop: "8px" }}
                            onClick={(e) => onChangeRow(e, "increase")}
                          >
                            <KeyboardArrowUpIcon
                              sx={{ ...issuesStyle.keyArrowSx }}
                            />
                          </IconButton>
                          <IconButton
                            onClick={(e) => onChangeRow(e, "decrease")}
                            sx={{ p: "0", height: "0px", marginTop: "-8px" }}
                          >
                            <KeyboardArrowDownIcon
                              sx={{ ...issuesStyle.keyArrowSx }}
                            />
                          </IconButton>
                        </Box>
                  </Box>

                  <Typography sx={{ ...issuesStyle.paginationTitle }}>
                    of total 
                  </Typography>
                  <Typography sx={{ ...issuesStyle.paginationTitle ,fontSize:'16px',fontWeight:'600'}}>
                    {Math.ceil(typeFilter?.data?.total / rowsPerPage)}
                  </Typography>
                </Box>
                <TablePagination
                  sx={{ ...issuesStyle.pagination }}
                  component="div"
                  count={count}
                  page={currentPage}
                  onPageChange={onPageBtn}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                {/* <Pagination count={count} page={currentPage} sx={{...issuesStyle.paginationSx}} onChange={onPageBtn} /> */}
              </Box>
              {/* )} */}
            </Box>

            {sastTypeData?.data?.issues.length === 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "75%",
                }}
              >
                <Box className={issuesStyle.emptyState}>
                  <img src="/images/emptyState.svg" alt="emptyState" />
                  <Typography className={issuesStyle.emptyTitle}>
                    There is no sast report to show.
                  </Typography>
                </Box>
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
