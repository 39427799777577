import {
  Avatar,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { cardstyle } from "./style";

export const StatCard = ({
  name = "",
  domain = "",
  data,
  cardValue,
  icon,
  color,
  Color,
  toolTip,
  number,
  BackgroundColor = "",
  metricData = "",
}) => {
  return (
    <Card sx={cardstyle?.rediesSx}>
      <Box sx={cardstyle?.cardSx}>
        <Stack
          sx={cardstyle?.stackSx}
          direction="row"
          justifyContent={"space-between"}
        >
          <Box sx={cardstyle?.boxSx}>
            <Typography sx={[cardstyle?.numberSx, { color: color }]}>
              {cardValue}
            </Typography>
            <Box sx={{ marginTop: "4px" }}>{icon}</Box>
          </Box>
          <Box sx={cardstyle?.boxxxSx}>
            <Typography
              sx={[cardstyle?.numberSx, { color: color?.numberColor }]}
            >
              {data}
            </Typography>
            <Box sx={[cardstyle?.numberSx, { color: color?.numberColor }]}>
              {number}
            </Box>
          </Box>
        </Stack>
        <Stack direction="row" justifyContent={"space-between"}>
          <Box>
            <Typography sx={cardstyle?.HeadText}>{name}</Typography>
          </Box>
          <Typography sx={cardstyle?.HeadText}></Typography>
        </Stack>
      </Box>
      <Box
        sx={[
          cardstyle?.cardContent,
          { backgroundColor: BackgroundColor, position: "relative" },
        ]}
      >
        <Typography sx={cardstyle?.cardText}>{domain}</Typography>
        <Avatar sx={[cardstyle?.cardAvater, { color: Color }]}>
          {metricData}
        </Avatar>
        <Tooltip
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: "12px",
                fontWeight: 400,
                color: "#fff",
                padding: "7px",
                background: "#161515",
                marginTop: "0px !important",
              },
            },
            arrow: {
              sx: {
                color: "#000",
              },
            },
          }}
          title={toolTip}
        >
          <IconButton sx={{ position: "absolute", right: "0px" }}>
            <InfoOutlinedIcon sx={{ fontSize: "14px", color: "#fff" }} />
          </IconButton>
        </Tooltip>
      </Box>
    </Card>
  );
};
