import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { featureListing_Style } from "./style";
import { HashIcon } from "../../assets/hashIcon";
import { useSnackbar } from "notistack";
// import { useSnackbar } from "notistack";

export function FeatureListing({
  className = "",
  featureName = "",
  selectName,
  isLoading = false,
  index,
  selectedIndex = 0,
  onEdit = () => false,
  onDelete = () => false,
  setFeatures,
  onListsClick = () => false,
  setSelectedIndex,
  id,
  setIsEdit,
  isEdit,
  value,
  isEdited,
  onSaveFeatures = () => {},
  ...rest
}) {
  // general Hooks

  const [data, setData] = useState({
    name: selectName ?? "",
    error: {
      name: "",
    },
  });
  const inputRef = useRef(null);

  const onEditList = () => {
    setIsEdit(id);
  };

  const onClose = () => {
    setIsEdit(null);
  };

  const updateState = (key, value) => {
    let error = data.error;
    error[key] = "";
    setData({ ...data, [key]: value, error });
  };


  // finding the first char
  const startsWithHash = (string) => {
    if (string.charAt(0) === "@") {
      return string.substring(1);
    } else {
      return `@${string}`;
    }
  };

  
  const onAddValueBefore = (value, key, id) => {
    let getNewValue = startsWithHash(value);
    onSaveFeatures(getNewValue, key, id);
    setIsEdit(null);
  };


  const onSave = (val, id) => {
    if (selectName.charAt(0) === "@" && val.charAt(0) === "@") {
      onSaveFeatures(val, "updated", id);
    } else if (selectName.charAt(0) === "@" && val.charAt(0) !== "@") {
      onSaveFeatures(val, "uncommented", id);
    } else if (selectName.charAt(0) !== "@" && val.charAt(0) === "@") {
      onSaveFeatures(val, "commented", id);
    } else {
      onSaveFeatures(val, "updated", id);
    }

    // const updatedFeatureName = data.featureName;
    // setData((prevData) => {
    //   if (!Array.isArray(prevData)) {
    //     return prevData;
    //   }
    //   const newData = [...prevData];
    //   newData[index] = {
    //     ...newData[index],
    //     featureName: updatedFeatureName,
    //   };
    //   return newData;
    // });
    setIsEdit(null);
  };

  // useEffect(() => {
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //   }
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    setData({
      ...data,
      name: selectName,
    });
    // eslint-disable-next-line
  }, [selectName]);

  return (
    <Box sx={featureListing_Style.rootSx} className={`${className}`} {...rest}>
      <Box
        sx={{
          ...featureListing_Style.totalFeaturesSx,
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "flex-start", lineHeight: "40px" }}
        >
          {isLoading && selectedIndex === index ? (
            <Box sx={{ paddingTop: "2px" }}>
              <CircularProgress sx={{ color: "#3b82f6" }} size={16} />
            </Box>
          ) : (
            selectedIndex === index && (
              <Box sx={{ paddingTop: "1px" }}>
                <HashIcon
                  cursor={"pointer"}
                  onClick={() => onAddValueBefore(data?.name, "updateComment", id ?? "")}
                  width={16}
                  height={16}
                  marginTop={selectedIndex === index ? 2 : 2}
                  color={data?.name.charAt(0) === "@" ? "#3b82f6" : "#94A3BB"}
                />
              </Box>
            )
          )}
          {id === isEdit ? (
            <Box sx={featureListing_Style.inputSx}>
              <TextField
                inputRef={inputRef}
                sx={featureListing_Style.textBox}
                InputProps={{
                  endAdornment: (
                    <>
                      <IconButton
                        // disableRipple
                        sx={{ p: 0 }}
                        onClick={() => onSave(data?.name, id ?? "")}
                      >
                        <CheckCircleOutlineRoundedIcon
                          sx={{ ...featureListing_Style.tickSx }}
                        />
                      </IconButton>
                      <IconButton
                        // disableRipple
                        sx={{ p: 0, ml: "10px" }}
                        onClick={onClose}
                      >
                        <CancelOutlinedIcon
                          sx={{ ...featureListing_Style.closeInput }}
                        />
                      </IconButton>
                    </>
                  ),
                }}
                label=""
                value={data?.name}
                onChange={(e) => updateState("name", e.target.value)}
                // onBlur={()=>onClose()}
                fullWidth
              />
            </Box>
          ) : (
            <Box
              sx={{
                position: "relative",
                width: "100%",
                paddingLeft: selectedIndex === index ? "0" : "14px",
              }}
            >
              {selectedIndex === index && (
                <IconButton
                  disableRipple
                  sx={{ p: 0, position: "absolute", top: "10px", left: "16px" }}
                  onClick={onEditList}
                >
                  <EditIcon sx={{ ...featureListing_Style.iconEditSx }} />
                </IconButton>
              )}
              <Box
                sx={{
                  ...featureListing_Style.featuresBoxSx,
                  backgroundColor:
                    selectedIndex === index
                      ? "#e2eeff"
                      : data?.name.charAt(0) === "@"
                      ? "#eaeaea"
                      : "#fff",
                }}
                onClick={(e) => onListsClick(e)}
              >
                <Box sx={{ display: "flex" }}>
                  <Typography
                    sx={{
                      ...featureListing_Style.featuresNameSx,
                      ml: selectedIndex === index ? 2.85 : 0.7,
                    }}
                  >
                    {featureName}
                  </Typography>
                </Box>
                <IconButton
                  disableRipple
                  sx={{ p: 0 }}
                  onClick={(e) => onDelete(e)}
                >
                  {" "}
                  {value?.length > 1 && selectedIndex === index && (
                    <DeleteIcon sx={{ ...featureListing_Style.iconSx }} />
                  )}
                </IconButton>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
