import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = localStorage.getItem(LocalStorageKeys.authToken);
        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
    },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 });
 
export const bddAPI = createApi({
    reducerPath: "bddAPI",
    baseQuery: baseQueryWithRetry,
    tagTypes: ["Bdd"],
    endpoints: (builder) => ({
        getAllFeatures: builder.query({
                query: (applicationId) => `bdd/feature/get/all?application_id=${applicationId}`,
                providesTags:['Bdd']
        }),
        putAllFeatures: builder.mutation({
            query(body) {
                return {
                    url: `bdd/feature/upsert`,
                    method: "POST",
                    body: body
                };
            },
        }),
        uploadFeature:builder.query({
            query: (fileName) => ({
                    url: `bdd/feature/upload/url?file_name=${fileName}`,
                    method: "GET",
                }),
        }),
        deleteFeature:builder.mutation({
            query(feature_id) {
                    return {
                        url: `bdd/feature/delete`,
                        method: "POST",
                        body: {
                            feature_id: feature_id
                        }
                    };
                },
            })
        }),
});
 
// Export hooks for usage in functional components
export const {
    useLazyGetAllFeaturesQuery,
    useGetAllFeaturesQuery,
    usePutAllFeaturesMutation,
    useLazyUploadFeatureQuery,
    useDeleteFeatureMutation,
} = bddAPI;


