import React from "react";
import { ForgotPassword } from "./forgotPassword"


class ForgotpasswordParent extends React.Component {
    render() {
        return <ForgotPassword />;
    }
}

export default ForgotpasswordParent;