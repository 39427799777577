export const  VulnerabilitiesIcon = (props) => (
<svg xmlns="http://www.w3.org/2000/svg" id="Group_110376" data-name="Group 110376" width="20.518" height="18.453" viewBox="0 0 20.518 18.453">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_56071" data-name="Rectangle 56071" width="20.518" height="18.453" fill="none"/>
    </clipPath>
  </defs>
  <g id="Group_110350" data-name="Group 110350" transform="translate(0 0)" clip-path="url(#clip-path)">
    <path id="Path_98374" data-name="Path 98374" d="M10.259,18.453H2.58A2.576,2.576,0,0,1,.349,14.588l3.84-6.65,3.839-6.65a2.576,2.576,0,0,1,4.462,0l3.839,6.65,3.84,6.65a2.576,2.576,0,0,1-2.231,3.864Z" transform="translate(0 0)" fill="#3183ef"/>
    <path id="Path_98375" data-name="Path 98375" d="M28.524,23.741s.021.04.06.119a1.06,1.06,0,0,1-.1.99,1.047,1.047,0,0,1-.289.288.949.949,0,0,1-.442.144c-1.309.014-3.2.012-5.535.017l-8.208.009H12.836a1.011,1.011,0,0,1-.946-.685A1.117,1.117,0,0,1,11.851,24c.013-.051.03-.1.047-.152s.044-.1.068-.144l.148-.271.6-1.1,2.5-4.565,2.5-4.566L18.9,11.039c.1-.177.189-.354.294-.529a1.2,1.2,0,0,1,.432-.429,1.271,1.271,0,0,1,1.161-.052,1.211,1.211,0,0,1,.459.367,5.06,5.06,0,0,1,.289.5l.534.977,1.02,1.869,1.83,3.355,2.645,4.861.716,1.323.184.345c.041.078.06.119.06.119s-.024-.038-.068-.115l-.191-.341-.729-1.316L24.868,17.12l-1.839-3.35L22.006,11.9l-.535-.976a5.044,5.044,0,0,0-.284-.485,1.137,1.137,0,0,0-.432-.344,1.2,1.2,0,0,0-1.092.05,1.125,1.125,0,0,0-.4.4c-.1.168-.194.35-.292.526l-1.187,2.172-2.5,4.567-2.5,4.564-.6,1.1a2.31,2.31,0,0,0-.256.546,1.039,1.039,0,0,0,.036.578.934.934,0,0,0,.874.635h1.173l8.208.009,5.533.008a.921.921,0,0,0,.429-.136,1.028,1.028,0,0,0,.284-.278,1.049,1.049,0,0,0,.183-.593,1.144,1.144,0,0,0-.122-.5" transform="translate(-9.999 -8.384)" fill="#263238"/>
    <path id="Path_98376" data-name="Path 98376" d="M58.416,52.094a1.316,1.316,0,0,1,2.62,0,1.314,1.314,0,0,1-2.62,0m.012-6.972h2.609L60.6,50.258H58.855Z" transform="translate(-49.4 -38.158)" fill="#fff"/>
    <path id="Path_98377" data-name="Path 98377" d="M58.413,83.61c-.033-1.12,1.488-1.578,2.268-.906a1.223,1.223,0,0,1-.014,1.832,1.37,1.37,0,0,1-2.254-.926m0,0a1.308,1.308,0,0,0,2.188.852,1.162,1.162,0,0,0-.87-2.012,1.207,1.207,0,0,0-1.318,1.16" transform="translate(-49.397 -69.675)" fill="#263238"/>
    <path id="Path_98378" data-name="Path 98378" d="M58.491,44.927l2.609-.035h.038l0,.039c-.108,1.486-.292,3.631-.417,5.138l0,.052h-.052l-1.743-.005h-.048l0-.047c-.125-1.495-.266-3.626-.376-5.141m0,0c.145,1.509.352,3.647.479,5.132l-.052-.047,1.743,0-.056.052c.131-1.515.316-3.643.46-5.135l.035.038Z" transform="translate(-49.463 -37.962)" fill="#263238"/>
    <path id="Path_98379" data-name="Path 98379" d="M54.844,2.5a2.116,2.116,0,0,0-.489-.1,1.729,1.729,0,0,0-1.113.372,2.8,2.8,0,0,0-.736.945c-.14.278-.206.461-.218.456a.428.428,0,0,1,.032-.133,3.2,3.2,0,0,1,.135-.348,2.606,2.606,0,0,1,.738-.983,1.7,1.7,0,0,1,1.166-.365,1.175,1.175,0,0,1,.365.089.37.37,0,0,1,.12.068" transform="translate(-44.217 -1.976)" fill="#fff"/>
    <path id="Path_98380" data-name="Path 98380" d="M48.034,17.906a1.027,1.027,0,0,1,.169-.365c.112-.19.219-.335.238-.324a2.01,2.01,0,0,1-.407.688" transform="translate(-40.618 -14.56)" fill="#fff"/>
  </g>
</svg>
)
