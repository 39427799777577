import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
// import { LocalStorageKeys } from "../../../utils";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: config.github_url,
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 6 });

export const gitHubIntegrationAPI = createApi({
  reducerPath: "gitHubIntegrationAPI",
  baseQuery: baseQueryWithRetry,
  tagTypes: ["Github"],
  endpoints: (builder) => ({
    // Get All Groups API
    getAllGitHubGroups: builder.query({
      query: (payload) => ({
        //  Group URL with Pagination
        url: `user/orgs?per_page=50&page=1`,
        method: "GET",
        headers: { Authorization: "Bearer " + payload?.access_token },
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),

    // Get All GroupsRepo using ORG API
    getAllGitHubGroupsRepo: builder.query({
      query: (payload) => ({
        // url: `groups/${payload?.id}/projects`,
        url: `orgs/${payload?.id}/repos?per_page=500&page=1`,
        method: "GET",
        headers: { Authorization: "Bearer " + payload?.access_token },
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),

    // Get All GroupsRepo using USER API
    getAllGitHubGroupsUserRepo: builder.query({
      query: (payload) => ({
        url: `user/repos?affiliation=owner`,
        method: "GET",
        headers: { Authorization: "Bearer " + payload?.access_token },
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),

    // Get All GroupsRepoBranch API
    getAllGitHubGroupsRepoBranch: builder.query({
      query: (payload) => ({
        url: `repos/${payload?.id}/${payload?.value}/branches?per_page=150&page=1`,
        method: "GET",
        headers: { Authorization: "Bearer " + payload?.access_token },
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),
    
  }),
});

// Export hooks for usage in functional components
export const {
  useLazyGetAllGitHubGroupsQuery,
  useLazyGetAllGitHubGroupsRepoQuery,
  useLazyGetAllGitHubGroupsUserRepoQuery,
  useLazyGetAllGitHubGroupsRepoBranchQuery,
} = gitHubIntegrationAPI;
