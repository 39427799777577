import { Box,  Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Stack } from "@mui/system";
import moment from "moment/moment";
import React from "react";
// import { Error } from "../errorMessage";
import { RenderLineChart } from "../lineChart";
import { SelectInput } from "../select";
import { chartStyle } from "./style";
import { chartCard } from "./utils";
import { SkeletonLoader } from "../skeletonLoader";

export const Chart = ({
  duplicatedResponce,
  chartData,
  isLoading,
  isError,
}) => {
  const [chartDataList, setChartLoadDateList] = React.useState({
    labels: [],
    datasets: [],
  });
  const [completeData, setCompleteData] = React.useState({
    labels: [],
    datasets: [],
  });
  const [select, setSelect] = React.useState({
    label: "",
  });

  const getChartData = (issuesType) => {
    const cart_ChartName = chartData?.data?.measures
      ?.filter((item) => {
        if (!issuesType) {
          if (
            item?.metric === "bugs" ||
            item?.metric === "vulnerabilities" ||
            item?.metric === "code_smells"
          ) {
            return item?.metric;
          }
        } else {
          if (item?.metric === issuesType) {
            return item?.metric;
          }
        }
        return item;
      })
      ?.map((item) => {
        return {
          label:
            (item?.metric === "bugs" && "Bugs") ||
            (item?.metric === "vulnerabilities" && "Vulnerabilities") ||
            (item?.metric === "code_smells" && "Code Smells"),
          date: item?.history?.map(({ date }) => date),
          data: item?.history?.map(({ value }) => value),
          backgroundColor:
            (item?.metric === "bugs" && "#36A2EB") ||
            (item?.metric === "vulnerabilities" && "#FF6384") ||
            (item?.metric === "code_smells" && "#FF9F40"),
          borderColor:
            (item?.metric === "bugs" && "#36A2EB") ||
            (item?.metric === "vulnerabilities" && "#FF6384") ||
            (item?.metric === "code_smells" && "#FF9F40"),
          borderWidth: 1,
          yAxisID: "y",
        };
      });

    const labelss = cart_ChartName?.map((val) => val?.date)?.flat(1);

    const sortDate = labelss?.sort((a, b) => {
      return moment(a).unix() - moment(b).unix();
    });

    const labelssSet = [...new Set(sortDate)];

    const labels = labelssSet?.map((val) => moment(val).format("MMM Do YY"));

    const data = {
      labels,
      datasets: cart_ChartName,
    };
    return data;
  };

  const selectOptionss = chartData?.data?.measures
    ?.filter((item) => {
      if (
        item?.metric === "bugs" ||
        item?.metric === "vulnerabilities" ||
        item?.metric === "code_smells"
      ) {
        return item?.metric;
      }
      return item;
    })
    ?.map((item) => {
      return {
        label:
          (item?.metric === "bugs" && "Bugs") ||
          (item?.metric === "vulnerabilities" && "Vulnerabilities") ||
          (item?.metric === "code_smells" && "Code Smells"),
      };
    });
    
  React.useEffect(() => {
    if (!isLoading && !isError) {
      setChartLoadDateList(getChartData());
      setCompleteData(getChartData());
    }
    // eslint-disable-next-line
  }, [isLoading, isError]);

  const handleChange = (newvalue) => {
    if (typeof newvalue === "string") {
      setSelect(newvalue);
      const getSelectedData = completeData?.datasets?.filter(
        (val) => val.label === newvalue
      );
      if (newvalue === "") {
        setChartLoadDateList(completeData);
      } else {
        setChartLoadDateList({ ...chartDataList, datasets: getSelectedData });
      }
    } else {
      setSelect(newvalue);
    }
  }

  return (
    <>
      <Grid container spacing={1.8}>
        <Grid item xs={12} sm={3} md={4} lg={3}>
        {  isError === false &&
               isLoading === true &&
               (
                <SkeletonLoader skeletonLoaderSx ={{width: '100%'}} height="286px"/>
               )
            }
          {/* {!isLoading  && !duplicates.length > 0 && <Box sx={chartStyle.emptyState}>
            <img src="/images/emptyState.svg" alt="emptyState" />
            <Typography sx={chartStyle.emptyTitle}>
              There is no Duplicate Responce to show.
            </Typography>
          </Box> } */}
          
          { isLoading === false && isError === false &&
            duplicatedResponce?.map((item) => (
              <Box sx={chartStyle.rediesSX}>
                <Stack sx={chartStyle.padSX}>
                  <Box>
                    <Box sx={chartStyle.cercleBoder}>
                      <Box sx={chartStyle.cercleColor}></Box>
                    </Box>
                  </Box>

                  <Box sx={chartStyle.gapSx}>
                    <Typography sx={chartStyle.percetSx}>
                      <span>{item?.dublicatePrs}</span>%
                    </Typography>
                  </Box>

                  <Box sx={chartStyle.gapSx}>
                    <Typography sx={chartStyle.subTitle}>
                      Duplications on
                      <span style={{ color: item?.color }}>
                        {item?.dublicateNcloc}
                      </span>{" "}
                      Lines
                    </Typography>
                  </Box>
                  <Box sx={chartStyle.horizandalBar}></Box>
                  <Box sx={chartStyle.gapSx}>
                    <Typography sx={chartStyle.subTitle2}>
                      {item?.dublicateValue}
                    </Typography>
                  </Box>
                  <Box sx={chartStyle.gapSx}>
                    <Typography sx={chartStyle.subTitle}>
                      {item?.name}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            ))}
        </Grid>

        <Grid item xs={12} sm={9} md={8} lg={9}>
            {  isError === false &&
               isLoading === true &&
               (
                <SkeletonLoader skeletonLoaderSx ={{ width: '100%'}} height="286px"/>
               )
            }
              {/* {!isLoading  && chartData === undefined && <Box sx={chartStyle.emptyState}>
            <img src="/images/emptyState.svg" alt="emptyState" />
            <Typography sx={chartStyle.emptyTitle}>
              There is no chart Data to show.
            </Typography>
          </Box> } */}
          {!isLoading  && (
            <Box sx={chartStyle.chartSX}>
              <Box>
                <Grid
                  height={"100%"}
                  container
                  justifyContent={"space-between"}
                  p={2}
                >
                  <Typography fontWeight={600}>{chartCard.title}</Typography>
                  <SelectInput
                    options={selectOptionss ? selectOptionss : []}
                    onChange={(event, newvalue) => handleChange(newvalue)}
                    select={select}
                    placeholder="Issues"
                    selectSx={chartCard.selectSx}
                  />
                </Grid>
                <Box p={2}>
                  <RenderLineChart data={chartDataList} />
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Chart;
